import React, { useEffect, useRef, useState } from "react";
import CSSLoader from "components/cssLoader/CSSLoader";
import { useDispatch } from "react-redux";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";
import { bh_sub_steps } from "helpers/enum";
import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";
import Button from "components/buttons/Button";
import useToastify from "hooks/useToastify";
import SymptomChip from "../../common/SymptomChip";
import VisitSubQuestionSlideUpContainer from "components/visitSubQuestionSlideUp/VisitSubQuestionSlideUpContainer";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import { Form, Formik } from "formik";
import { OtherSymptomsSchema } from "helpers/validationSchema";
import Input from "components/inputs/Input";

function Symptoms({
  updateVisit,
  updatingVisit,
  setActiveIndex,
  symptomsFromApi,
  gettingSymptoms,
}) {
  const formRef = useRef();
  const dispatch = useDispatch();
  const { incompleteData } = useBehaviorHealthVisitFlow();
  const [symptomsList, setSymptomsList] = useState([]);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const { showAlert } = useToastify();

  const [otherValue, setOtherValue] = useState("");

  useEffect(() => {
    if (
      incompleteData?.behavior_health_symptoms?.length &&
      symptomsList?.length
    ) {
      let formedConcerns = [];
      const concernsResult = incompleteData?.behavior_health_symptoms.filter(
        (el) => {
          return symptomsList?.find((elem) => {
            return el === elem;
          });
        }
      );
      const otherResult = incompleteData?.behavior_health_symptoms.find(
        (el) => {
          return !symptomsList?.find((elem) => {
            return el === elem;
          });
        }
      );

      formedConcerns = concernsResult;

      if (otherResult) {
        formedConcerns.push("Other");
        setOtherValue(otherResult);
      }
      setSelectedSymptoms(formedConcerns);
    }
  }, [symptomsList, incompleteData.behavior_health_symptoms]);

  useEffect(() => {
    if (symptomsFromApi && symptomsFromApi?.symptom_opts?.length) {
      setSymptomsList([...symptomsFromApi?.symptom_opts, "Other"]);
    }
  }, [symptomsFromApi]);

  function onRightButtonClick(_, nextAnimation) {
    if (selectedSymptoms?.includes("Other")) {
      formRef?.current?.handleSubmit();
    } else {
      updateVisit({
        payload: {
          case: {
            behavior_health_symptoms: selectedSymptoms,
            medications:
              typeof incompleteData?.medications[0] === "string"
                ? incompleteData?.medications.map((item) => ({
                    name: item,
                  }))
                : incompleteData?.medications,
            bh_second_phase_steps: {
              ...incompleteData?.bh_second_phase_steps,
              [bh_sub_steps.symptoms]: "completed",
            },
          },
        },
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            behaviorHealthVisitFlowReducers.symptoms({
              value: selectedSymptoms,
            })
          );
          showAlert("Progress Saved Successfully", "success");
          setActiveIndex(7);
          nextAnimation();
        },
      });
    }
  }

  function onFormSubmit(values) {
    updateVisit({
      payload: {
        case: {
          behavior_health_symptoms: [
            ...selectedSymptoms?.filter((el) => el !== "Other"),
            values?.other_symptom,
          ],
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications,
          bh_second_phase_steps: {
            ...incompleteData?.bh_second_phase_steps,
            [bh_sub_steps.symptoms]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        dispatch(
          behaviorHealthVisitFlowReducers.symptoms({
            value: selectedSymptoms,
          })
        );
        showAlert("Progress Saved Successfully", "success");
        setActiveIndex(7);
      },
    });
  }

  function onSelectSymptom(el) {
    let final = [...selectedSymptoms];
    if (final?.includes(el)) {
      setSelectedSymptoms(final.filter((elem) => elem !== el));
    } else {
      setSelectedSymptoms([...final, el]);
    }
  }

  return (
    <div className="bg-white shadow-card rounded-b-4xl px-10 pb-10 relative overflow-x-hidden">
      {gettingSymptoms ? (
        <div className={"h-40 flex items-center justify-center"}>
          <CSSLoader className={"w-16 h-16"} color={"orange"} />
        </div>
      ) : (
        <div className="mt-4 flex flex-wrap items-baseline space-y-4">
          {symptomsList?.map((el, idx) => {
            return (
              <SymptomChip
                key={idx}
                selected={selectedSymptoms?.includes(el)}
                onSelect={() => onSelectSymptom(el)}
                symptomText={el}
              />
            );
          })}
        </div>
      )}
      {selectedSymptoms?.includes("Other") ? (
        <VisitSubQuestionSlideUpContainer>
          <Spacer height={40} />
          <Card>
            <Formik
              onSubmit={onFormSubmit}
              innerRef={formRef}
              validationSchema={OtherSymptomsSchema}
              initialValues={{
                other_symptom: otherValue ?? "",
              }}
            >
              {() => (
                <Form>
                  <Input
                    id={"other_symptom"}
                    name={"other_symptom"}
                    label="Type your symptom(s), separated by comma"
                  />
                </Form>
              )}
            </Formik>
          </Card>
        </VisitSubQuestionSlideUpContainer>
      ) : null}
      <Button
        disabled={selectedSymptoms.length === 0}
        className="py-4 mt-10"
        loading={updatingVisit}
        label="Next"
        onClick={onRightButtonClick}
      />
    </div>
  );
}

export default Symptoms;
