import Images from "assets/images";
import React, { useEffect, useState } from "react";
import { Icon } from "stories/Icons";
import classNames from "classnames";
import CSSLoader from "components/cssLoader/CSSLoader";
import RegularSelect from "components/radioButtonCard/RegularSelect";
import Button from "components/buttons/Button";
import useToastify from "hooks/useToastify";
import { useApiMutation } from "hooks/useApiMutation";
import { linkInsuranceAction } from "api/actions/VisitActions";
import { useCurrentUser } from "hooks/useCurrentUser";
import {
  useDermatologyVisitFlow,
  usePedVisitFlow,
  usePrimaryCareVisitFlow,
} from "hooks/useVisitFlow";
import useIncompleteData from "hooks/visit/useIncompleteData";
import { excludeImageContainer } from "helpers/validationSchema";
import { includes } from "lodash";

export const bhServices = [
  "Established care with a licensed therapist",
  "50 minute face-to-face video call",
  "Convenient, effective and affordable",
  "Unlimited portal support",
];

export const insuranceServices = [
  "Established care with a board certified doctor",
  "Evaluation of 1 medical condition",
  "Diagnosis, advice and treatment plan",
  "Prescription ordering, as approved by MD",
  "Unlimited portal / app support",
];
const insuranceSteps = [
  "Pay your patient responsibility via our app or patient portal.",
  "Our support staff will check your insurance coverage to determine if SkyMD is in or out of your insurance network.",
  "Once eligibility is determined, our support staff will share your pricing estimate via email",
  "After accepting and making your payment, our physician will evaluate your visit.",
];
// Old design
// const notFilled = ({ onClick }) => {
//   return (
//     <div
//       className="flex p-6 bg-white border cursor-pointer border-borderColor rounded-4xl"
//       onClick={onClick}
//     >
//       <img
//         className="self-start object-contain w-24"
//         alt="insurance_icon"
//         src={Images.insuranceShield}
//       />
//       <div className="pl-6">
//         <h2 className="text-3xl font-bold">Upload Health Insurance</h2>
//         <p className="mt-2 text-2xl font-light leading-8 text-cloudyBlue">
//           Please enter your health insurance details so we can confirm your
//           out-of-pocket estimate. Please keep your insurance card handy as you
//           are required to upload a picture of it.
//         </p>
//       </div>
//     </div>
//   );
// };
const newInsurance = ({
  onSelect,
  isActive,
  insuranceDetails,
  onUpdateInsurance,
  isLoading,
}) => {
  return (
    <div
      className={classNames(
        "flex px-6 py-8 bg-white border-2 border-t-8  rounded-xl  active:bg-orange ring-0 focus:ring-0 focus:shadow-none active:shadow-none",
        isActive && "border-orange"
      )}
      onClick={onSelect}
    >
      <div className="flex flex-col text-left gap-y-6">
        <div className={"flex items-start space-x-4"}>
          <div className={"cursor-pointer"}>
            <Icon name={isActive ? "radioActive" : "radioInactive"} />
          </div>
          <div>
            <h2 className="text-2.5xl md:text-3xl font-bold">
              {insuranceDetails
                ? "Insurance Price Estimate"
                : "Upload Health Insurance"}
            </h2>

            {insuranceDetails ? (
              <div className="flex justify-between w-full pt-6">
                <div
                  className={classNames(
                    "flex items-center justify-start space-x-16"
                  )}
                >
                  <div>
                    <p className="text-xl font-medium leading-5 text-cloudyBlue">
                      Insurance
                    </p>
                    <p className="mt-2 text-2xl font-medium leading-7">
                      {insuranceDetails?.insurance_company}
                    </p>
                  </div>
                  <div>
                    <p className="text-xl font-medium leading-5 text-cloudyBlue">
                      Member ID
                    </p>
                    <p className="mt-2 text-2xl font-medium leading-7">
                      {insuranceDetails?.member_id}
                    </p>
                  </div>
                </div>
                <button
                  className="text-2xl font-semibold opacity-100 text-orange"
                  onClick={onUpdateInsurance}
                >
                  Update Insurance
                </button>
              </div>
            ) : (
              <p className="mt-2 text-2xl font-light leading-8 text-black">
                Please enter your health insurance details so we can confirm
                today's out-of-pocket estimate. Please keep your insurance card
                handy as you are required to upload a picture of it.
              </p>
            )}
            <div className={"py-6 border-b border-grey-600 -ml-12 md:ml-0"}>
              <div className="p-2.5 bg-gray-150 ">
                <h2 className="pb-2.5 text-2xl font-bold">
                  Simple and Convenient Insurance Process with SkyMD
                </h2>
                <div className="flex flex-col space-y-6 md:space-y-0 md:grid items-start grid-flow-col grid-rows-2 py-2 gap-y-2 gap-x-12">
                  {insuranceSteps.map((e, index) => {
                    return (
                      <div
                        key={`steps ${index}`}
                        className="flex items-center space-x-4"
                      >
                        <p className="text-2xl font-bold leading-8 text-black ">
                          Step {index + 1}:
                          <span className="text-2xl font-light leading-8 text-black ">
                            {" "}
                            {e}
                          </span>
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <h2 className="py-6 text-2xl font-bold">Additional Benefits</h2>
              <div className="space-y-6 md:space-y-0 md:grid items-start grid-flow-col grid-rows-3 pb-2 gap-2.5">
                {insuranceServices.map((e, i) => {
                  return (
                    <div
                      key={`services ${i}`}
                      className="flex items-center space-x-4"
                    >
                      <img
                        className="w-8 h-8"
                        src={Images.checkedGreen}
                        alt="expression"
                      />
                      <p className="text-2xl font-light leading-8 text-black ">
                        {e}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className={
                "flex items-center justify-between pt-6 space-x-4 -ml-12 md:ml-0"
              }
            >
              <p className="text-2xl font-semibold">Patient Responsibility</p>
              <div className="w-5/12">
                {isLoading ? (
                  <CSSLoader className="w-8 h-8" color="orange" />
                ) : (
                  <p className="text-2xl font-medium text-left">
                    Our support staff will review your coverage and update you
                    as soon as possible.
                  </p>
                )}
              </div>
            </div>
            {/* {showSkipButton && (
              <div className="flex items-center justify-between pt-6 mt-6 border-t border-grey-600">
                <p className="text-2xl font-semibold">No Insurance?</p>
                <div className="w-5/12">
                  <button
                    className="self-end float-right px-3 py-2 text-2xl font-medium text-left border rounded-md border-secondaryBlue-600 text-secondaryBlue-600"
                    onClick={onSkip}
                  >
                    Skip and Provide Later
                  </button>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

const InsuranceCard = ({ imageUrls, insuranceInfo, dob, disabled }) => {
  return (
    <div
      className={classNames(
        "flex flex-col md:grid w-full grid-cols-5 gap-6 pt-6 pb-8",
        disabled && "opacity-50"
      )}
    >
      {!includes(excludeImageContainer, insuranceInfo[0]?.content) && (
        <div className="flex md:flex-col col-span-1 space-x-6 md:space-x-0 md:space-y-6">
          {imageUrls.map((e, i) => (
            <div key={`add-insurance-image-${i}`}>
              <img
                className="object-contain px-4 py-1 border bg-secondaryBlue-100 w-28 h-28 justify-self-stretch "
                alt={`insurance_photo_${i}`}
                src={e}
              />
              <p className="mt-2 text-2xl font-medium leading-7">
                {i === 0 ? "Front Image" : "Back Image"}
              </p>
            </div>
          ))}
        </div>
      )}
      <div className="md:flex grid flex-col col-span-2 grid-cols-2 gap-6 md:gap-0 justify-between md:space-y-6">
        {insuranceInfo.map((e, i) => (
          <div key={`insurance-info-${i}`}>
            <div className="space-y-4">
              <p className="text-xl font-medium leading-5 text-themeBlack">
                {e.title}
              </p>
              <p className="mt-2 text-2xl font-medium leading-7">{e.content}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col col-span-2 space-y-6">
        <div className="space-y-4">
          <p className="text-xl font-medium leading-5 text-themeBlack">
            {"Member DOB"}
          </p>
          <p className="mt-2 text-2xl font-medium leading-7">{dob}</p>
        </div>
      </div>
    </div>
  );
};

const filled = ({
  onSelect,
  isActive,
  insuranceDetails,
  onUpdateInsurance,
  disabled,
  disabledReason,
  isExpired,
  onUpdateSecondaryInsurance,
  isLoading,
  showSecondary,
  setShowSecondary,
  authCode,
  setAuthCode,
  submitAuthcode,
  submittingAuthCode,
  incompleteData,
  onAddSecondaryInsurance,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col px-6 py-8 bg-white border-2 border-t-8 rounded-xl w-full active:bg-orange ring-0 focus:ring-0 focus:shadow-none active:shadow-none",
        isActive && !disabled && "border-orange"
      )}
      onClick={onSelect}
    >
      <div className={"flex items-start space-x-4"}>
        <div>
          <Icon
            className={
              disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
            }
            name={isActive ? "radioActive" : "radioInactive"}
          />
        </div>
        <div>
          <div className="flex justify-between">
            <h2 className="text-2.5xl md:text-3xl font-bold">
              Your Insurance Information
            </h2>
            <button
              className="p-4 text-2xl md:block hidden font-semibold border rounded-lg opacity-100 text-orange border-orange"
              onClick={onUpdateInsurance}
            >
              Update Insurance
            </button>
          </div>
          {disabled && disabledReason && (
            <p className="mt-2 text-2xl font-light leading-8 text-errorRed">
              {disabledReason}
            </p>
          )}
          <div className="border-b border-grey-600 -ml-12 md:ml-0">
            <InsuranceCard
              disabled={disabled}
              insuranceInfo={[
                {
                  title: "Insurance",
                  content: insuranceDetails?.insurance_company,
                },
                {
                  title: "Member Name",
                  content: insuranceDetails?.member_name,
                },
                {
                  title: "Relationship of patient to member",
                  content: insuranceDetails?.relationship_to_patient,
                },
                {
                  title: "Member ID",
                  content: insuranceDetails?.member_id,
                },
              ]}
              dob={insuranceDetails.member_dob}
              imageUrls={[
                insuranceDetails?.insurance_front_photo?.url ??
                  insuranceDetails?.insurance_front_cover_photo,
                insuranceDetails?.insurance_back_photo?.url ??
                  insuranceDetails?.insurance_back_cover_photo,
              ]}
            />
            <button
              className="p-4 text-2xl w-full mb-8 block md:hidden font-semibold border rounded-lg opacity-100 text-orange border-orange"
              onClick={onUpdateInsurance}
            >
              Update Insurance
            </button>
          </div>
          {!isExpired &&
            insuranceDetails?.can_add_secondary_insurance &&
            (insuranceDetails?.secondary_insurance?.insurance_company ? (
              <div className="mt-4">
                <div className="flex justify-between">
                  <h2 className="text-2xl font-bold">
                    {"Secondary Insurance"}
                  </h2>
                  <button
                    className="p-4 text-2xl font-semibold border rounded-lg opacity-100 text-orange border-orange"
                    onClick={onUpdateSecondaryInsurance}
                  >
                    Update Secondary Insurance
                  </button>
                </div>
                <div className="border-b border-grey-600">
                  <InsuranceCard
                    disabled={disabled}
                    insuranceInfo={[
                      {
                        title: "Insurance",
                        content:
                          insuranceDetails?.secondary_insurance
                            .insurance_company,
                      },
                      {
                        title: "Member Name",
                        content:
                          insuranceDetails?.secondary_insurance.member_name,
                      },
                      {
                        title: "Relationship of patient to member",
                        content:
                          insuranceDetails?.secondary_insurance
                            .relationship_to_patient,
                      },
                      {
                        title: "Member ID",
                        content:
                          insuranceDetails?.secondary_insurance.member_id,
                      },
                    ]}
                    dob={insuranceDetails.secondary_insurance.member_dob}
                    imageUrls={[
                      insuranceDetails?.secondary_insurance
                        ?.insurance_back_photo?.url ??
                        insuranceDetails?.secondary_insurance
                          ?.insurance_front_cover_photo,
                      insuranceDetails?.secondary_insurance
                        ?.insurance_back_photo?.url ??
                        insuranceDetails?.secondary_insurance
                          ?.insurance_back_cover_photo,
                    ]}
                  />
                </div>
              </div>
            ) : (
              <div className="pb-8 mt-8 border-b border-grey-600 -ml-12 md:ml-0">
                <div className="flex md:flex-row flex-col space-y-6 md:space-y-0 justify-between">
                  <RegularSelect
                    label="Do you want to add a secondary insurance?"
                    options={["Yes", "No"]}
                    selected={showSecondary}
                    setSelected={(el) => setShowSecondary(el)}
                  />
                  {showSecondary === "Yes" && (
                    <div>
                      <button
                        className="p-4 text-2xl w-full font-semibold border rounded-lg opacity-100 text-orange border-orange"
                        onClick={onAddSecondaryInsurance}
                      >
                        Add Secondary Insurance
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          {isExpired || !insuranceDetails?.auth_id_required ? (
            <div />
          ) : incompleteData?.insurance_authorisation ? (
            <div className="pb-8 mt-8 border-b border-grey-600 -ml-12 md:ml-0">
              <p className="text-xl font-medium leading-5 text-themeBlack">
                Authorization Approval Number
              </p>
              <p className="mt-2 text-2xl font-medium leading-7">
                {incompleteData?.insurance_authorisation?.auth_id}
              </p>
            </div>
          ) : (
            <div className="pb-8 mt-8 border-b border-grey-600 -ml-12 md:ml-0">
              <div className="flex flex-col space-x-0 space-y-4 md:flex-row md:items-end md:space-x-4 md:space-y-0">
                <div className="flex-1">
                  <label
                    className="font-bold text-2xl text-indigo text-opacity-50"
                    htmlFor="auth_id"
                  >
                    <span className="text-red">*</span>Please enter your
                    NAPHCARE authorization approval number
                  </label>
                  <div className="flex items-center custom_pass_field mt-2">
                    <input
                      id="auth_id"
                      placeholder="Enter Auth ID..."
                      value={authCode}
                      onChange={(e) => setAuthCode(e.target.value)}
                      type="text"
                      autoFocus={true}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key) || authCode.length > 5) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div>
                  <Button
                    type="submit"
                    label="Submit"
                    disabled={authCode.length !== 6}
                    onClick={submitAuthcode}
                    loading={submittingAuthCode}
                    className="h-10"
                  />
                </div>
              </div>
            </div>
          )}
          {!isExpired && (
            <div
              className={classNames(
                "flex items-center justify-between pt-6 space-x-4 -ml-12 md:ml-0",
                disabled && "opacity-50"
              )}
            >
              <p className="text-2xl font-semibold">Patient Responsibility</p>

              <div className="w-5/12">
                {isLoading ? (
                  <CSSLoader className="w-8 h-8" color="orange" />
                ) : (
                  <p className="text-2xl font-medium text-left">
                    Our support staff will review your coverage and update you
                    as soon as possible.
                  </p>
                )}
              </div>
            </div>
          )}
          {/* {showSkipButton && (
              <div className="flex items-center justify-between pt-6 mt-6 border-t border-grey-600">
                <p className="text-2xl font-semibold">No Insurance?</p>
                <div className="w-5/12">
                  <button
                    className="self-end float-right px-3 py-2 text-2xl font-medium text-left border rounded-md border-secondaryBlue-600 text-secondaryBlue-600"
                    onClick={onSkip}
                  >
                    Skip and Provide Later
                  </button>
                </div>
              </div>
            )} */}
        </div>
      </div>
    </div>
  );
};

function NewInsuranceBlock({
  insuranceDetails,
  onInsuranceClick,
  onUpdateInsurance,
  isActive,
  disabled = false,
  disabledReason,
  isExpired = false,
  isLoading = false,
  onUpdateSecondaryInsurance,
  onAddSecondaryInsurance,
}) {
  const [incompleteData, setIncompleteData] = useState(null);
  const [authCode, setAuthCode] = useState("");
  const [showSecondary, setShowSecondary] = useState("");

  const { userId, currentSpeciality } = useCurrentUser();
  const {
    fetchIncompleteData,
    incompleteData: fetchedIncompleteData,
  } = useIncompleteData();
  const { incompleteData: dermIncompleteData } = useDermatologyVisitFlow();
  const { incompleteData: pcIncompleteData } = usePrimaryCareVisitFlow();
  const { incompleteData: pedIncompleteData } = usePedVisitFlow();

  const { showAlert } = useToastify();

  const {
    mutate: callLinkInsuranceApi,
    loading: submittingAuthCode,
  } = useApiMutation(linkInsuranceAction);

  function successHandler(message) {
    showAlert(message, "success");
  }

  function errorHandler(message) {
    showAlert(message, "error");
  }

  useEffect(() => {
    switch (currentSpeciality) {
      case 1:
        setIncompleteData(dermIncompleteData);
        break;
      case 2:
        setIncompleteData(pcIncompleteData);
        break;
      case 4:
        setIncompleteData(pedIncompleteData);
        break;
      default:
        setIncompleteData(dermIncompleteData);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpeciality]);

  useEffect(() => {
    if (fetchedIncompleteData) {
      setIncompleteData(fetchedIncompleteData);
    }
  }, [fetchedIncompleteData]);

  async function submitAuthcode() {
    const params = {
      unsupported_insurance: "0",
      insurance_company: insuranceDetails?.insurance_company,
      member_dob: insuranceDetails?.member_dob,
      member_name: insuranceDetails?.member_name,
      member_id: insuranceDetails?.member_id,
      relationship_to_patient: insuranceDetails?.relationship_to_patient,
      patient_id: incompleteData?.patient_id,
      insurance_type: 1,
      account_id: userId,
      insurance_authorisations_attributes: [
        {
          case_id: incompleteData?.id,
          auth_id: authCode,
        },
      ],
    };

    try {
      const result = await callLinkInsuranceApi({
        formData: params,
        accountId: userId,
      });
      if (result.error || result.payload.error || result.payload.error_msg) {
        errorHandler(result.payload.error || result.payload.error_msg);
      } else {
        successHandler(result?.payload?.message);
        fetchIncompleteData(currentSpeciality);
      }
    } catch (error) {
      errorHandler(error?.message);
    }
  }

  return (
    <>
      {insuranceDetails
        ? filled({
            insuranceDetails: insuranceDetails,
            onSelect: onInsuranceClick,
            isActive: isActive,
            onUpdateInsurance: onUpdateInsurance,
            disabled: disabled,
            disabledReason: disabledReason,
            isExpired: isExpired,
            isLoading: isLoading,
            onUpdateSecondaryInsurance: onUpdateSecondaryInsurance,
            onAddSecondaryInsurance: onAddSecondaryInsurance,
            setShowSecondary: setShowSecondary,
            showSecondary: showSecondary,
            authCode: authCode,
            setAuthCode: setAuthCode,
            submitAuthcode: submitAuthcode,
            submittingAuthCode: submittingAuthCode,
            incompleteData: incompleteData,
          })
        : newInsurance({
            insuranceDetails: insuranceDetails,
            onSelect: onInsuranceClick,
            isActive: isActive,
            isLoading: isLoading,
          })}
    </>
  );
}

export default NewInsuranceBlock;
