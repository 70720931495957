import React, { useEffect, useState } from "react";
import Images from "assets/images";
import DoctorCard from "components/doctorCard/DoctorCard";
import Spacer from "components/Spacer/Spacer";
import moment from "moment";
import DateChip from "components/slotChip/DateChip";
import TimeSlotChip from "components/slotChip/TimeSlotChip";
import CSSLoader from "components/cssLoader/CSSLoader";
import { useDispatch } from "react-redux";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";
import { bh_sub_steps } from "helpers/enum";
import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";
import useModal from "hooks/useModal";
import PCProviderProfileDetails from "components/pcProviderProfileDetails/PCProviderProfileDetails";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import CosmeticSelectProvider from "pages/home/waitingRoom/consultation/cosmetic/cosmeticFlow/CosmeticSelectProvider";
import useToastify from "hooks/useToastify";
import Button from "components/buttons/Button";

const SelectSlot = ({
  incompleteData,
  updateVisit,
  updatingVisit,
  setActiveIndex,
  gettingBookingSlots,
  slotsResponse,
  preselectedSlotId = null,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);

  const dispatch = useDispatch();
  const { setShowModal } = useModal();
  const { subSteps } = useBehaviorHealthVisitFlow();
  const currentScreenValueFromStore = subSteps[bh_sub_steps.select_slot].value;
  const { showAlert } = useToastify();

  useEffect(() => {
    if (preselectedSlotId) {
      if (slotsResponse) {
        Object.values(slotsResponse).forEach((slotsArr, slotsArrIndex) =>
          slotsArr.forEach((slot) => {
            slot?.providers.forEach((provider) => {
              if (provider?.slot_id === preselectedSlotId) {
                setSelectedDate(Object.keys(slotsResponse)[slotsArrIndex]);
                setSelectedProvider(provider);
                setSelectedSlot(slot);
              }
            });
          })
        );
      }
    }
  }, [preselectedSlotId, slotsResponse]);

  useEffect(() => {
    if (
      currentScreenValueFromStore &&
      Object.keys(currentScreenValueFromStore)?.length &&
      slotsResponse
    ) {
      setSelectedDate(currentScreenValueFromStore?.selectedDate);
      const findSlotFromPayload = slotsResponse?.[
        currentScreenValueFromStore?.selectedDate
      ]?.find(
        (el) => el?.slot_id === currentScreenValueFromStore?.slotInfo?.slot_id
      );
      if (
        findSlotFromPayload?.booked === false ||
        findSlotFromPayload?.hasOwnProperty("booked") === false ||
        currentScreenValueFromStore?.slotInfo?.booked === false
      ) {
        setSelectedSlot(currentScreenValueFromStore?.slotInfo);
        setSelectedProvider(currentScreenValueFromStore?.selectedProvider);
      }
    }
  }, [currentScreenValueFromStore, slotsResponse]);

  function onRightButtonClick(provider) {
    if (!provider) {
      updateVisit({
        payload: {
          case: {
            phase: "first_phase",
            medications:
              typeof incompleteData?.medications[0] === "string"
                ? incompleteData?.medications.map((item) => ({
                    name: item,
                  }))
                : incompleteData?.medications,
            behavior_health_provider_id: selectedProvider?.id,
            bh_second_phase_steps: {
              ...incompleteData?.bh_second_phase_steps,
              [bh_sub_steps.select_slot]: "completed",
            },
            meta: {
              ...incompleteData?.meta,
              booking_info: {
                selectedDate,
                slotInfo:
                  selectedSlot?.slot_id !== selectedProvider?.slot_id
                    ? { ...selectedSlot, slot_id: selectedProvider?.slot_id }
                    : selectedSlot,
                selectedProvider: selectedProvider,
              },
            },
          },
        },
        change_current_screen: false,
        screen_to_complete: incompleteData?.phase
          ? bh_sub_steps.select_slot
          : null,
        callBack: () => {
          dispatch(
            behaviorHealthVisitFlowReducers.select_slot({
              value: {
                selectedDate,
                slotInfo: selectedSlot,
                selectedProvider: selectedProvider,
              },
            })
          );
          showAlert("Progress Saved Successfully", "success");
          setActiveIndex(3);
        },
      });
    } else {
      updateVisit({
        payload: {
          case: {
            phase: "first_phase",
            medications:
              typeof incompleteData?.medications[0] === "string"
                ? incompleteData?.medications.map((item) => ({
                    name: item,
                  }))
                : incompleteData?.medications,
            meta: {
              ...incompleteData?.meta,
              booking_info: {
                selectedDate,
                slotInfo: selectedSlot,
                selectedProvider: provider,
              },
            },
          },
        },
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            behaviorHealthVisitFlowReducers.select_slot({
              value: {
                selectedDate,
                slotInfo: selectedSlot,
                selectedProvider: provider,
              },
            })
          );
          setActiveIndex(2);
        },
      });
    }
  }

  return (
    <div className="bg-white shadow-card rounded-b-4xl p-10 relative overflow-x-hidden">
      {gettingBookingSlots ? (
        <div className={"h-40 flex items-center justify-center"}>
          <CSSLoader className={"w-16 h-16"} color={"orange"} />
        </div>
      ) : (
        <div>
          {slotsResponse && Object.keys(slotsResponse)?.length ? (
            <>
              <label className="font-bold text-2xl text-indigo text-opacity-50">
                Select Day
              </label>
              <Spacer height={15} />
              <div className="overflow-auto">
                <div className="flex pb-10">
                  {Object.keys(slotsResponse)
                    ?.sort((a, b) => {
                      return new Date(a) - new Date(b);
                    })
                    ?.map((el, idx) => {
                      return (
                        <DateChip
                          key={idx}
                          numberOfSlots={slotsResponse?.[el]?.length}
                          onClick={() => {
                            setSelectedProvider(null);
                            setSelectedSlot(null);
                            setSelectedDate(el);
                          }}
                          selected={el === selectedDate}
                          isLast={idx === Object.keys(slotsResponse)?.length}
                          date={
                            moment().format("YYYY-MM-DD") === el
                              ? "Today"
                              : moment().add(1, "days").format("YYYY-MM-DD") ===
                                el
                              ? "Tomorrow"
                              : moment(el, "YYYY-MM-DD").format("DD MMM")
                          }
                          day={moment(el).format("ddd")}
                        />
                      );
                    })}
                </div>
              </div>
            </>
          ) : (
            <h2 className="text-2xl font-semibold text-center text-indigo text-opacity-50">
              Oops! No slots available. Please try again later.
            </h2>
          )}
          {selectedDate ? (
            <div>
              <Spacer height={15} />
              <label className="font-bold text-2xl text-indigo text-opacity-50">
                Select Time
              </label>
              {slotsResponse?.[selectedDate]?.length ? (
                <div>
                  <Spacer height={15} />
                  {slotsResponse?.[selectedDate]?.length ? (
                    <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
                      {slotsResponse?.[selectedDate]
                        ?.sort((a, b) => {
                          return moment(a.start_time, "HH:mm").diff(
                            moment(b.start_time, "HH:mm")
                          );
                        })
                        ?.map((el, idx) => {
                          return (
                            <TimeSlotChip
                              key={idx}
                              onClick={() => {
                                setSelectedSlot(el);
                                setSelectedProvider(el?.providers?.[0]);
                              }}
                              disabled={el?.booked}
                              selected={selectedSlot?.slot_id === el?.slot_id}
                              isLast={
                                idx === slotsResponse?.[selectedDate]?.length
                              }
                              slot={`${moment(el?.start_time, "hh:mma").format(
                                "h:mm A"
                              )} - ${moment(el?.end_time, "hh:mma").format(
                                "h:mm A"
                              )}`}
                            />
                          );
                        })}
                    </div>
                  ) : null}
                </div>
              ) : (
                <p className="text-xl pt-4 font-normal">
                  Oops! No slots available. Please try selecting other dates.
                </p>
              )}
            </div>
          ) : null}
        </div>
      )}
      {selectedSlot ? (
        <div>
          <Spacer height={36} />
          <MedicalAssistant
            content={
              <p>
                Your pre-selected therapist is{" "}
                <span className="font-black">{selectedProvider?.name}</span>.
              </p>
            }
          />
          <Spacer height={26} />
          <div className="border border-borderColor rounded-3xl p-6 relative">
            <DoctorCard
              license={selectedProvider?.license}
              isActive={selectedProvider?.active}
              name={selectedProvider?.name}
              imageUrl={selectedProvider?.avatar}
              speciality={selectedProvider?.speciality ?? ""}
              viewProfile={true}
              onViewProfileClick={() =>
                setShowModal({
                  state: true,
                  children: (_, onClose) => (
                    <PCProviderProfileDetails
                      details={selectedProvider}
                      caseId={incompleteData?.id}
                      onClose={onClose}
                    />
                  ),
                })
              }
            />
          </div>
          {selectedSlot?.providers.length > 1 ? (
            <div className="flex flex-1 items-center justify-center mt-7">
              <div
                onClick={() => {
                  setShowModal({
                    state: true,
                    children: (_, onClose) => (
                      <CosmeticSelectProvider
                        caseId={incompleteData?.id}
                        selectedProvider={selectedProvider}
                        availableProviders={selectedSlot?.providers}
                        onSelectProvider={(selectedOption) => {
                          setSelectedProvider(selectedOption);
                          onRightButtonClick(selectedOption);
                        }}
                        onClose={onClose}
                      />
                    ),
                  });
                }}
                className="flex items-center justify-center cursor-pointer"
              >
                <p className="pr-6 text-2xl font-medium">Change Therapist</p>
                <img className="w-8" src={Images.arrowRight} alt={"go"} />
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      <Button
        disabled={gettingBookingSlots || !selectedSlot}
        className="mt-10 py-4"
        loading={updatingVisit}
        label="Next"
        onClick={() => onRightButtonClick(false)}
      />
    </div>
  );
};

export default SelectSlot;
