import { bh_main_steps, bh_sub_steps, step_status } from "helpers/enum";

export const behaviorHealthVisitFlowInitialState = {
  currentScreen: bh_sub_steps.select_patient,
  incompleteData: null,
  patientsList: null,
  completedProgress: 0,
  paymentMethod: null,
  mainSteps: {
    [bh_main_steps.visit_details]: {
      status: step_status.current,
      currentScreen: null,
    },
    [bh_main_steps.main_select_slot]: {
      status: step_status.upcoming,
    },
    [bh_main_steps.basic_info]: {
      status: step_status.upcoming,
    },
    [bh_main_steps.additional_details]: {
      status: step_status.upcoming,
    },
  },
  subSteps: {
    [bh_sub_steps.select_patient]: {
      value: null,
      isValid: false,
      prevScreen: null,
      nextScreen: null,
      status: step_status.current,
      headerTitle: "Visit Details",
      progress: 10,
    },
    [bh_sub_steps.select_child]: {
      value: null,
      isValid: false,
      prevScreen: bh_sub_steps.select_patient,
      nextScreen: bh_sub_steps.select_therapy,
      headerTitle: "Visit Details",
    },
    [bh_sub_steps.select_someone_else]: {
      value: null,
      isValid: false,
      prevScreen: bh_sub_steps.select_patient,
      nextScreen: bh_sub_steps.select_therapy,
      headerTitle: "Visit Details",
    },
    [bh_sub_steps.select_therapy]: {
      value: null,
      isValid: false,
      prevScreen: bh_sub_steps.select_patient,
      nextScreen: bh_sub_steps.select_slot,
      status: step_status.upcoming,
      headerTitle: "Visit Details",
      progress: 20,
    },
    [bh_sub_steps.select_slot]: {
      value: null,
      isValid: false,
      prevScreen: bh_sub_steps.select_therapy,
      nextScreen: bh_sub_steps.confirm_residence,
      headerTitle: "Select Your Appointment Date and Time",
      status: step_status.upcoming,
      progress: 30,
    },
    [bh_sub_steps.confirm_residence]: {
      value: null,
      isValid: false,
      prevScreen: bh_sub_steps.select_slot,
      nextScreen: bh_sub_steps.identity_confirmation,
      status: step_status.upcoming,
      headerTitle: "Basic Info",
      progress: 40,
      rightButtonText: "Confirm",
    },
    [bh_sub_steps.identity_confirmation]: {
      value: null,
      isValid: false,
      prevScreen: bh_sub_steps.confirm_residence,
      nextScreen: bh_sub_steps.select_therapy_reasons,
      status: step_status.upcoming,
      headerTitle: "Confirm your identity",
      progress: 50,
    },
    [bh_sub_steps.select_therapy_reasons]: {
      value: null,
      isValid: false,
      status: step_status.upcoming,
      prevScreen: bh_sub_steps.identity_confirmation,
      nextScreen: bh_sub_steps.symptoms,
      headerTitle: "What led you to therapy today?",
      progress: 80,
    },
    [bh_sub_steps.symptoms]: {
      value: null,
      isValid: false,
      status: step_status.upcoming,
      prevScreen: bh_sub_steps.select_therapy_reasons,
      nextScreen: bh_sub_steps.additional_info,
      headerTitle: "Select Symptoms",
      progress: 90,
    },
    [bh_sub_steps.additional_info]: {
      value: null,
      isValid: false,
      prevScreen: bh_sub_steps.symptoms,
      nextScreen: null,
      status: step_status.upcoming,
      headerTitle: "Additional Information",
      progress: 100,
    },
  },
};
