import classNames from "classnames";
import Stepper from "components/stepContainer/Stepper";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { startCase, lowerCase } from "lodash";
import Images from "assets/images";
import { NavLink } from "react-router-dom";
import { speciality } from "helpers/enum";
import { calculatePercentage } from "helpers/functions";
import PracticeUserCard from "./PracticeUserCard";
import useModal from "hooks/useModal";
import ProviderProfileDetails from "../profile/ProviderProfile";

const IncompleteVisitTracker = ({ visit, routeHelper, loading }) => {
  const { setShowModal } = useModal();

  if (loading) return <ShimmerEffect />;
  else if (!loading && visit) {
    return (
      <div
        className={classNames(
          "w-full p-4 md:p-6 space-y-8 xl:space-y-0 bg-white border gap-y-20 xl:gap-y-0 xl:gap-x-20 xl:grid xl:grid-cols-2 rounded-xl"
        )}
      >
        {visit?.practice_user ? (
          <PracticeUserCard
            showNetwork={false}
            size={"responsive"}
            picture={visit?.practice_user.picture}
            name={visit?.practice_user.name}
            speciality={visit?.practice_user.speciality}
            gender={visit?.practice_user.gender}
            showLicense={visit?.practice_user.license}
            licenseState={visit?.practice_user.license?.state}
            licenseNumber={visit?.practice_user.license?.license}
            licenseActive={visit?.practice_user.license?.active}
            ratingValue={visit?.practice_user?.rating?.value}
            ratingUserCount={visit?.practice_user?.rating?.user_count}
            inNetwork={visit?.practice_user?.insurance?.in_network}
            viewProfile={() => {
              setShowModal({
                state: true,
                children: (_, onClose) => (
                  <ProviderProfileDetails
                    details={visit?.practice_user}
                    caseId={visit?.id}
                    onClose={onClose}
                  />
                ),
              });
            }}
          />
        ) : (
          <div className="w-full p-6 border border-gray-200 rounded-lg md:p-10">
            <div className="flex flex-col items-center justify-center h-full space-y-14">
              <div className="flex flex-col w-full space-y-6">
                <h3 className="text-2xl font-bold text-center xl:text-left">
                  {`Continue with your ${
                    visit.speciality === "behavior_health"
                      ? "behavioral health"
                      : lowerCase(visit.speciality)
                  } provider`}
                </h3>
                <p className="text-2xl font-medium text-center xl:text-left text-themeBlack">
                  {`Don't forget to schedule your regular ${
                    visit.speciality === "behavior_health"
                      ? "behavioral health"
                      : lowerCase(visit.speciality)
                  } visit
                    with your healthcare provider!`}
                </p>
              </div>
              <img
                className="object-cover w-40 h-40 rounded-full"
                src={Images.UserPlaceholder}
                alt="avatar"
              />
            </div>
          </div>
        )}
        <div className="w-full p-4 border border-gray-200 rounded-lg md:p-10 ">
          {visit && (
            <IncompleteVisit
              incompleteVisitDetails={visit}
              routeHelper={routeHelper}
              isExpired={
                visit?.practice_user &&
                (!visit?.practice_user?.license ||
                  !visit?.practice_user?.license?.active)
              }
            />
          )}
        </div>
      </div>
    );
  }
};

const IncompleteVisit = ({
  incompleteVisitDetails,
  routeHelper,
  isExpired,
}) => {
  const currentProgress = incompleteVisitDetails?.progress;
  const calculatedPercents = calculatePercentage(
    incompleteVisitDetails?.progress
  );
  return (
    <div className="flex flex-col justify-between w-full h-full space-y-4 ">
      <div className="flex flex-col w-full">
        <div className="flex justify-between space-x-2 md:items-center ">
          {incompleteVisitDetails?.follow_up_visit ? (
            <div className="pr-4 flex flex-col items-start space-x-0 space-y-2 md:flex-row md:space-x-4 md:space-y-0 md:items-center">
              <h3 className="text-2xl font-bold">
                {`Almost There: ${
                  incompleteVisitDetails.speciality === "behavior_health"
                    ? "Behavioral Health"
                    : startCase(incompleteVisitDetails.speciality)
                } Visit #${incompleteVisitDetails.id} Tracker`}
              </h3>
              <div className="px-4 py-2 bg-orange rounded-4xl">
                <p className="font-bold text-md text-white md:text-lg">
                  Follow Up
                </p>
              </div>
            </div>
          ) : (
            <h3 className="text-2xl font-bold">
              {`Almost There: ${
                incompleteVisitDetails.speciality === "behavior_health"
                  ? "Behavioral Health"
                  : startCase(incompleteVisitDetails.speciality)
              } Visit #${incompleteVisitDetails.id} Tracker`}
            </h3>
          )}
          <div className="w-24 h-24 md:w-20 md:h-20 ">
            <CircularProgressbar
              value={
                incompleteVisitDetails.speciality === "dermatology"
                  ? incompleteVisitDetails.meta.progress_percentage ??
                    calculatedPercents
                  : calculatedPercents
              }
              text={`${
                incompleteVisitDetails.speciality === "dermatology"
                  ? incompleteVisitDetails.meta.progress_percentage ??
                    calculatedPercents
                  : calculatedPercents
              }%`}
              className={"font-bold"}
              strokeWidth={11}
              styles={buildStyles({
                rotation: 0,
                pathTransitionDuration: 2,
                pathColor: "#ff8548",
                textColor: "#101347",
                trailColor: "#d6d6d6",
                backgroundColor: "#3e98c7",
              })}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full space-y-4">
        <Stepper
          steps={currentProgress}
          speciality={incompleteVisitDetails?.speciality}
        />
        {isExpired && incompleteVisitDetails?.speciality !== "dermatology" ? (
          <NavLink
            to={`/change-provider/${
              speciality[incompleteVisitDetails?.speciality]
            }`}
            className="px-16 py-4 text-2xl font-semibold text-center text-white no-underline rounded-lg bg-orange"
          >
            Change Provider
          </NavLink>
        ) : (
          <button
            onClick={() => routeHelper(incompleteVisitDetails?.speciality)}
            className="px-16 py-4 text-2xl font-semibold text-center text-white no-underline rounded-lg bg-orange"
          >
            Proceed with Consultation
          </button>
        )}
      </div>
    </div>
  );
};

const ShimmerEffect = () => {
  return (
    <div className="flex flex-col items-stretch flex-grow w-full p-6 space-y-20 bg-white border xl:space-y-0 xl:space-x-20 xl:flex-row rounded-xl">
      <div className="flex flex-col w-full p-10 border border-gray-200 rounded-lg ">
        <div className="flex w-full">
          <div className="flex flex-col items-center justify-center space-y-2 ">
            <div className="w-32 h-32 md:w-16.2 md:h-16.2 rounded-full relative">
              <div className="w-full h-full bg-gray-300 rounded-full animate-pulse" />
            </div>
            {/* <button className="px-8 py-2 text-2xl font-medium text-secondaryBlue-500">
            View Profile
          </button> */}
          </div>
          <div className="flex flex-col items-start justify-between px-8 py-2">
            <div className="flex flex-col items-start space-y-4">
              <div className="w-48 h-8 bg-gray-300 rounded-full animate-pulse" />
              <div className="w-32 h-6 bg-gray-300 rounded-full animate-pulse" />
            </div>
            <div className="flex-col items-start hidden space-y-4 md:flex">
              <div className="w-64 h-6 bg-gray-300 rounded-full animate-pulse" />
              <div className="flex items-center space-x-2">
                <div className="w-6 h-6 bg-green-500 rounded-full animate-pulse" />
                <div className="w-20 h-6 bg-gray-300 rounded-full animate-pulse" />
                <div className="w-40 h-6 bg-gray-300 rounded-full animate-pulse" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start pt-4 space-y-4 md:hidden">
          <div className="w-64 h-6 bg-gray-300 rounded-full animate-pulse" />
          <div className="flex items-center space-x-2">
            <div className="w-6 h-6 bg-green-500 rounded-full animate-pulse" />
            <div className="w-20 h-6 bg-gray-300 rounded-full animate-pulse" />
            <div className="w-40 h-6 bg-gray-300 rounded-full animate-pulse" />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between w-full p-10 border border-gray-200 rounded-md animate-pulse">
        <div className="flex flex-col justify-between w-full space-y-6 ">
          <div className="flex flex-col w-full space-y-6">
            <div className="h-10 bg-gray-300 rounded w-72"></div>
            <div className="h-16 bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncompleteVisitTracker;
