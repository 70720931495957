import logoVerticalNaming from "./icon/logoVerticalNaming.png";
import home from "./icon/home.png";
import homeActive from "./icon/homeActive.png";
import myVisits from "./icon/myVisits.png";
import myVisitsActive from "./icon/myVisitsActive.png";
import referrals from "./icon/referrals.png";
import referralsActive from "./icon/referralsActive.png";
import profile from "./icon/profile.png";
import profileActive from "./icon/profileActive.png";
import arrowBack from "./icon/arrowBack.png";
import arrowRight from "./icon/arrowRight.png";
import logoHorizontalNaming from "./icon/logoHorizontalNaming.png";
import uncheckedCircle from "./icon/uncheckedCircle.png";
import radioChecked from "./icon/radioChecked.png";
import editIcon from "./icon/editIcon.png";
import addIcon from "./icon/addIcon.png";
import closeIcon from "./icon/closeIcon.png";
import startVisit from "./icon/startVisit.png";
import continueVisit from "./icon/continueVisit.png";
import messageIcon from "./icon/messageIcon.png";
import insuranceShield from "./icon/insuranceShield.png";
import checkedGreen from "./icon/checkedGreen.png";
import writeIcon from "./icon/writeIcon.png";
import checkedOrange from "./icon/checkedOrange.png";
import stepCompleted from "./icon/stepCompleted.png";
import dropdown from "./icon/dropdown.png";
import checkedGreenFilled from "./icon/checkedGreenFilled.png";
import arrowWhite from "./icon/arrowWhite.png";
import editIconInverted from "./icon/editIconInverted.png";
import closeIconFilled from "./icon/closeIconFilled.png";
import searchIcon from "./icon/searchIcon.png";
import addLocationIcon from "./icon/addLocationIcon.png";
import marker from "./icon/marker.png";
import markerActive from "./icon/markerActive.png";
import searchWhite from "./icon/searchWhite.png";
import listView from "./icon/listView.png";
import mapView from "./icon/mapView.png";
import pause from "./icon/pause.png";
import addRounded from "./icon/addRounded.png";
import settingsIcon from "./icon/settingsIcon.png";
import logoutIcon from "./icon/logoutIcon.png";
import bellIcon from "./icon/bellIcon.png";
import comingSoon from "./icon/comingSoon.png";
import termsAndConditionsIcon from "./icon/termsAndConditionsIcon.png";
import privacyPolicyIcon from "./icon/privacyPolicyIcon.png";
import faqIcon from "./icon/faqIcon.png";
import contactIcon from "./icon/contactIcon.png";
import changePasswordIcon from "./icon/changePasswordIcon.png";
import aboutIcon from "./icon/aboutIcon.png";
import deleteIcon from "./icon/deleteIcon.png";
import phone from "./icon/phone.svg";
import chatIcon from "./icon/chatIcon.png";
import starFilled from "./icon/starFilled.png";
import starUnfilled from "./icon/starUnfilled.png";
import checkedLightGreen from "./icon/checkedLightGreen.png";
import sad from "./icon/sad.png";
import lockIcon from "./icon/lockIcon.png";
import closeWhiteIcon from "./icon/closeWhite.png";
import hourGlassIcon from "./icon/hourGlass.png";
import attachmentIcon from "./icon/attachment.png";
import sendArrowIcon from "./icon/sendArrow.png";
import clockIcon from "./icon/clockIcon.png";
import pdfIcon from "./icon/pdfIcon.png";
import infoIcon from "./icon/info.png";
import crossThinWhiteIcon from "./icon/crossThinWhite.png";
import cosmeticIcon from "./icon/cosmeticIcon.png";
import cosmeticContinueIcon from "./icon/continue_consultation.png";
import exclaimationIcon from "./icon/exclaimation-icon.svg";
import triangleWarning from "./icon/triangle-warning.svg";
import chevronRight from "./icon/chevron-right.svg";
import filledCheckedLight from "./icon/filled-checked-light.svg";
import filledError from "./icon/filled-error.svg";
import tabletIllustrated from "./icon/tablet-illustrated.svg";

import medicalAssistant from "./image/medicalAssistant.png";
import providersGrouped from "./image/providersGrouped.png";
import referralBg from "./image/referralBg.png";
import caseCloseUpPhoto from "./image/caseCloseUpPhoto.png";
import caseFarAwayPhoto from "./image/caseFarAwayPhoto.png";
import searchDoctor from "./image/searchDoctor.png";
import profilePlaceholder from "./image/profilePlaceholder.png";
import noVisits from "./image/noVisits.png";
import noVisitsNew from "./image/noVisitsNew.png";
import appScreenshot from "./image/appScreenshot.png";
import appScreenshotVisit from "./image/mobileAppVisitList.png";
import teaCup from "./image/teaCup.png";
import guaranteedPercentage from "./image/guaranteedPercentage.png";
import eyeOpen from "./icon/eye.svg";
import eyeClose from "./icon/eye-closed.svg";
import squareChecked from "./icon/square-checked.png";
import squareUnhecked from "./icon/square-unchecked.png";

import appLoader from "./image/loader.gif";
import PediaIcon from "./icon/pedia.png";
import PCIcon from "./icon/pc.png";
import DermaIcon from "./icon/derma.png";
import ComingSoon from "./icon/coming-soon.png";
import CosmeticNew from "./icon/cosmetic-new.png";
import DermaNewIcon from "./icon/derma-new.png";
import WaitingRoomBanner from "./image/banner_waiting.png";
import WaitingRoomBannerMobile from "./image/mobile_banner_waiting.png";
import CosmeticBanner from "./image/cosmetic.jpeg";
import UrgentBanner from "./image/urgentCare.jpeg";
import PediaBanner from "./image/pedia.jpeg";
import DermaBanner from "./image/derma.jpeg";
import BHBanner from "./image/BH.jpg";
import UserPlaceholder from "./icon/male_placeholder.png";
import BHIcon from "./icon/bh-new.png";
import NewDermIcon from "./icon/derm-new-icon.png";
import NewPCIcon from "./icon/pc-new-icon.png";
import NewPedIcon from "./icon/ped-new-icon.png";
import NewBHIcon from "./icon/bh-new-icon.png";
import NewCCIcon from "./icon/cc-new-icon.png";
const Images = {
  logoVerticalNaming,
  logoHorizontalNaming,
  bottom_tab: {
    home,
    homeActive,
    myVisits,
    myVisitsActive,
    referrals,
    referralsActive,
    profile,
    profileActive,
  },
  arrowBack,
  arrowRight,
  medicalAssistant,
  providersGrouped,
  uncheckedCircle,
  radioChecked,
  editIcon,
  addIcon,
  appLoader,
  closeIcon,
  startVisit,
  continueVisit,
  messageIcon,
  insuranceShield,
  checkedGreen,
  writeIcon,
  checkedOrange,
  stepCompleted,
  dropdown,
  checkedGreenFilled,
  arrowWhite,
  editIconInverted,
  closeIconFilled,
  searchIcon,
  addLocationIcon,
  marker,
  markerActive,
  searchWhite,
  listView,
  mapView,
  pause,
  referralBg,
  caseCloseUpPhoto,
  caseFarAwayPhoto,
  addRounded,
  settingsIcon,
  logoutIcon,
  searchDoctor,
  profilePlaceholder,
  bellIcon,
  comingSoon,
  termsAndConditionsIcon,
  privacyPolicyIcon,
  faqIcon,
  contactIcon,
  changePasswordIcon,
  aboutIcon,
  noVisits,
  noVisitsNew,
  deleteIcon,
  appScreenshot,
  teaCup,
  phone,
  chatIcon,
  starFilled,
  starUnfilled,
  checkedLightGreen,
  sad,
  appScreenshotVisit,
  lockIcon,
  guaranteedPercentage,
  closeWhiteIcon,
  hourGlassIcon,
  attachmentIcon,
  sendArrowIcon,
  clockIcon,
  pdfIcon,
  infoIcon,
  crossThinWhiteIcon,
  cosmeticIcon,
  cosmeticContinueIcon,
  exclaimationIcon,
  triangleWarning,
  chevronRight,
  filledCheckedLight,
  filledError,
  tabletIllustrated,
  eyeOpen,
  eyeClose,
  squareChecked,
  squareUnhecked,
  WaitingRoomBanner,
  WaitingRoomBannerMobile,
  UserPlaceholder,
  service: {
    PediaIcon,
    DermaIcon,
    DermaNewIcon,
    PCIcon,
    CosmeticNew,
    ComingSoon,
    BHIcon,
    NewDermIcon,
    NewPCIcon,
    NewPedIcon,
    NewBHIcon,
    NewCCIcon,
  },
  banner: {
    CosmeticBanner,
    UrgentBanner,
    DermaBanner,
    PediaBanner,
    BHBanner,
  },
};

export default Images;
