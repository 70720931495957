import Images from "assets/images";
import classNames from "classnames";
import React from "react";
import { Icon } from "stories/Icons";

function getServiceIcon(name) {
  switch (name) {
    case "primary_care":
      return Images.service.NewPCIcon;

    case "dermatology":
      return Images.service.NewDermIcon;

    case "pediatric":
    case "ped":
      return Images.service.NewPedIcon;

    case "cosmetic":
      return Images.service.NewCCIcon;

    case "behavior_health":
      return Images.service.NewBHIcon;

    default:
      return Images.service.ComingSoon;
  }
}
function getSubBgColor(name) {
  switch (name) {
    case "primary_care":
      return "bg-pcOrange-bg text-secondayOrange";

    case "dermatology":
      return "bg-secondaryBlue-100 text-secondaryBlue-500";

    case "pediatric":
    case "ped":
      return "bg-pedGreen-bg text-successGreen";

    case "cosmetic":
      return "bg-cosmYellow-bg text-cosmYellow-text";

    default:
      return "bg-pcOrange-bg text-cosmYellow-text";
  }
}

function ServiceCard({
  label,
  name,
  content,
  onClick,
  available,
  onClickNotify,
  subscribed,
  maintainRatio,
}) {
  const style = maintainRatio
    ? {
        aspectRatio: "1/1",
      }
    : {};
  return (
    <div
      className={classNames(
        "relative flex flex-col items-center justify-evenly overflow-x-hidden text-4xl bg-white border border-grey-600 rounded-2xl p-4 md:p-6",
        available && "cursor-pointer "
      )}
      style={style}
      onClick={!available ? null : onClick}
    >
      <img
        src={available ? getServiceIcon(name) : Images.service.ComingSoon}
        className="object-contain w-20 h-20 mx-auto my-0 md:w-32 md:h-32"
        alt={name ?? "icon"}
      />
      <div
        className={classNames(
          "flex flex-col items-center justify-center w-full space-y-1 md:pt-0 md:pb-0 md:space-y-3 text-secondayOrange",
          !maintainRatio && "pt-4 pb-2 "
        )}
      >
        <div className="flex items-center justify-center w-full space-x-1 md:space-x-3">
          <p
            className={`font-bold text-center ${
              label === "Behavioral Health Visit"
                ? "text-xl md:text-2xl"
                : "text-2xl md:text-3xl"
            }`}
          >
            {label}
          </p>
          <Icon name={"arrow-right"} />
        </div>
        <p className="px-2 text-lg font-medium text-center md:px-6 md:text-xl text-themeBlack">
          {available ? (
            content
          ) : (
            <span>
              We are launching{" "}
              <span className="font-bold text-black">
                {name === "primary_care"
                  ? "Primary Care"
                  : name === "dermatology"
                  ? "Dermatology"
                  : name === "ped"
                  ? "Pediatric"
                  : name === "cosmetic"
                  ? "Cosmetic"
                  : name === "behavior_health"
                  ? "Behavior"
                  : "this"}
              </span>{" "}
              services soon in your state
            </span>
          )}
        </p>
        {!available && (
          <button
            onClick={!subscribed ? onClickNotify : undefined}
            className={classNames(
              "text-xl font-medium text-center ",
              subscribed
                ? `${getSubBgColor(name)} p-2 rounded-md cursor-not-allowed`
                : "underline text-secondaryBlue-500"
            )}
          >
            {subscribed ? "Subscribed" : "Get Notified"}
          </button>
        )}
      </div>
    </div>
  );
}

export default ServiceCard;
