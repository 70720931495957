import React, { useEffect, useState } from "react";
import Images from "assets/images";
import Chip from "components/chip/Chip";
import Header from "components/header/Header";
import SearchInput from "components/inputs/SearchInput";
import { searchMedicalConditionAction } from "api/actions/UserActions";
import { useApiQuery } from "hooks/useApiQuery";
import useDebounce from "hooks/useDebounce";
import Spacer from "components/Spacer/Spacer";
import useToastify from "hooks/useToastify";

function HistoryDebounceSearchModal({
  onClose,
  selected,
  setSelected,
  title,
  keyToSearch,
}) {
  const [selectedLocal, setSelectedLocal] = useState([]);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const { showAlert } = useToastify();

  const [dataFromApi, setDataFromApi] = useState([]);

  const {
    loading,
    payload: result,
    query: callSearch,
    error: searchResultError,
  } = useApiQuery(
    searchMedicalConditionAction({
      condition: keyToSearch,
      searchText: debouncedSearchTerm,
      type: null,
    }),
    false
  );

  useEffect(() => {
    if (debouncedSearchTerm?.trim()?.length && keyToSearch) {
      callSearch();
    } else {
      setDataFromApi([]);
    }
  }, [debouncedSearchTerm, callSearch, keyToSearch]);

  useEffect(() => {
    if (searchResultError) {
      showAlert("Something went wrong", "error");
      setDataFromApi([]);
    } else {
      if (result && result?.length) {
        if (keyToSearch === "medications" || keyToSearch === "allergies") {
          setDataFromApi(result?.map((el) => el));
        } else {
          setDataFromApi(result?.map((el) => el?.name));
        }
      } else if (
        keyToSearch === "medical-history-options" &&
        result?.medical_history_options.length
      ) {
        setDataFromApi(result?.medical_history_options);
      } else {
        setDataFromApi([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, searchResultError, showAlert]);

  useEffect(() => {
    if (selected?.length) {
      setSelectedLocal(selected);
    }
  }, [selected]);

  return (
    <div className="bg-veryLightBlue h-screen">
      <Header
        title={title}
        leftActionView={
          <img
            onClick={onClose}
            className="w-8 cursor-pointer"
            src={Images.closeIcon}
            alt={"close_icons"}
          />
        }
        rightActionView={
          <p
            onClick={() => {
              setSelected(selectedLocal);
              onClose();
            }}
            className="text-2xl text-orange font-bold cursor-pointer"
          >
            Done
          </p>
        }
      />
      <div className="wrapper bg-white h-screen overflow-hidden">
        <div className="border-b border-borderColor">
          <div className="py-6 px-12">
            <SearchInput
              onKeyPress={(e) => {
                if (e?.key === "Enter") {
                  if (searchText?.trim()?.length) {
                    setSelectedLocal((prev) =>
                      keyToSearch === "medications" ||
                      keyToSearch === "allergies"
                        ? [...prev, { name: searchText }]
                        : keyToSearch === "medical-history-options"
                        ? [...prev, { category: "Other", disease: searchText }]
                        : [...prev, searchText]
                    );
                    setSearchText("");
                  }
                }
              }}
              value={searchText}
              loading={loading}
              onChange={(event) => setSearchText(event?.target?.value)}
            />
            {searchText?.trim()?.length ? (
              <>
                <Spacer height={20} />
                <h3
                  onClick={() => {
                    if (searchText?.trim()?.length) {
                      setSelectedLocal((prev) =>
                        keyToSearch === "medications" ||
                        keyToSearch === "allergies"
                          ? prev.some(
                              (prevCondition) =>
                                prevCondition?.name === searchText
                            )
                            ? prev
                            : [...prev, { name: searchText }]
                          : keyToSearch === "medical-history-options"
                          ? prev.some(
                              (prevDisease) =>
                                prevDisease?.disease === searchText
                            )
                            ? prev
                            : [
                                ...prev,
                                { category: "Other", disease: searchText },
                              ]
                          : prev.some((condition) => condition === searchText)
                          ? prev
                          : [...prev, searchText]
                      );
                      setSearchText("");
                    }
                  }}
                  className={"text-2xl font-bold pl-2 cursor-pointer"}
                >
                  Add "{searchText}"
                </h3>
              </>
            ) : null}
          </div>
        </div>
        {selectedLocal?.length ? (
          <div className="border-b border-borderColor">
            <div className="pt-6 px-12">
              <h2 className="text-xl font-black tracking-widest">
                {selectedLocal?.length} SELECTED
              </h2>
              <div className="overflow-auto">
                <div className="flex pt-8 pb-10">
                  {selectedLocal?.map((el, index) => (
                    <Chip
                      text={
                        keyToSearch === "medications" ||
                        keyToSearch === "allergies"
                          ? el?.name
                          : keyToSearch === "medical-history-options"
                          ? el?.disease
                          : el
                      }
                      key={index}
                      onClear={(el) => {
                        setSelectedLocal((prev) =>
                          prev?.filter((elem) =>
                            keyToSearch === "medications" ||
                            keyToSearch === "allergies"
                              ? elem.name !== el
                              : keyToSearch === "medical-history-options"
                              ? elem?.disease !== el
                              : elem !== el
                          )
                        );
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="overflow-y-auto px-0 py-8 pb-64 h-eliminateHeaderWithChipAndAddContainer bg-alternativeWhite">
          <div className="px-12">
            {dataFromApi?.length
              ? dataFromApi?.map((part, index) => {
                  if (selectedLocal?.find((el) => el === part)) {
                    return null;
                  } else {
                    return (
                      <div
                        onClick={() => {
                          setSelectedLocal((prev) =>
                            keyToSearch === "medical-history-options" &&
                            prev.some(
                              (prevDisease) =>
                                prevDisease?.disease === part?.disease
                            )
                              ? prev
                              : [...prev, part]
                          );
                          setSearchText("");
                        }}
                        key={index}
                        className="bg-white border border-borderColor rounded-1.6 p-8 px-12 mb-5 cursor-pointer"
                      >
                        <p className="text-2xl font-bold">
                          {keyToSearch === "medications" ||
                          keyToSearch === "allergies"
                            ? part?.name
                            : keyToSearch === "medical-history-options"
                            ? part?.disease
                            : part}
                        </p>
                      </div>
                    );
                  }
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryDebounceSearchModal;
