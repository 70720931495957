import { useCurrentUser } from "./useCurrentUser";

function useDataFromUserDetails() {
  // TODO: Handle BH related variables
  const { userDetails } = useCurrentUser();

  const dermaDoctorDetails = userDetails?.derm_physician;
  const pcDoctorDetails = userDetails?.primary_care_physician;
  const hasNoDermaProvider =
    !dermaDoctorDetails || dermaDoctorDetails?.provider_id === null;
  const hasNoPcProvider =
    !pcDoctorDetails || pcDoctorDetails?.provider_id === null;
  const isPcServiceAvailable = userDetails?.is_primary_care_available;
  const hasNoProvider = hasNoDermaProvider && hasNoPcProvider;
  const isInactiveDermaProvider = dermaDoctorDetails?.active === false;
  const isInactivePcProvider = pcDoctorDetails?.active === false;
  const isInactiveDermaProviderLicense =
    !hasNoDermaProvider &&
    dermaDoctorDetails?.hasOwnProperty("license") === false;
  const isInactivePcProviderLicense =
    !hasNoPcProvider && pcDoctorDetails?.hasOwnProperty("license") === false;
  const isMessageHidden =
    userDetails?.num_of_cases === 0 ||
    hasNoProvider ||
    (isInactiveDermaProvider && isInactivePcProvider);
  const isNewUser = !userDetails?.num_of_cases || userDetails?.num_of_cases < 2;
  const hasActiveDermaProvidersInState =
    userDetails?.active_dermatology_providers_exist ?? true;
  const hasActivePcProvidersInState = isPcServiceAvailable;

  return {
    isNewUser,
    dermaDoctorDetails,
    pcDoctorDetails,
    hasNoDermaProvider,
    hasNoPcProvider,
    hasNoProvider,
    isPcServiceAvailable,
    isInactiveDermaProvider,
    isInactivePcProvider,
    isInactiveDermaProviderLicense,
    isInactivePcProviderLicense,
    hasActiveDermaProvidersInState,
    hasActivePcProvidersInState,
    isMessageHidden,
  };
}

export default useDataFromUserDetails;
