import React, { useEffect, useState, useCallback } from "react";
import { checkInsurance, getCashPrice } from "api/actions/VisitActions";
import CashOption from "components/choosePaymentMethod/CashOption";
import InsuranceOption from "components/choosePaymentMethod/InsuranceOption";
import Spacer from "components/Spacer/Spacer";
import { useApiMutation } from "hooks/useApiMutation";
import { useApiQuery } from "hooks/useApiQuery";
import { useCurrentUser } from "hooks/useCurrentUser";
import usePatients from "hooks/visit/usePatients";
import PCInsuranceOption from "components/choosePaymentMethod/PCInsuranceOption";
import BHInsuranceOption from "components/choosePaymentMethod/BHInsuranceOption";

function hasInsurance(patient) {
  if (patient?.insurance && patient?.insurance?.insurance_company) {
    return true;
  } else {
    return false;
  }
}

function SelectPaymentMethod({
  cashPrice,
  setCashPrice,
  insuranceBreakDown,
  setInsuranceBreakDown,
  setIsCheckingPrice,
  checkoutDetails,
  paymentMethod,
  setPaymentMethod,
  onUpdatePress,
  currentSpeciality,
  patientsList,
  incompleteData,
  checkingInsuranceInNetwork,
  insuranceInNetwork,
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [insurance_details, setInsuranceDetails] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [insuranceError, setInsuranceError] = useState(null);

  // To get the current patient insuranceDetails
  const { getCurrentPatient, getAccountHolder } = usePatients();
  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );
  const currentUser = getAccountHolder(patientsList);
  const { userId } = useCurrentUser();

  useEffect(() => {
    if (patientsList) {
      if (hasInsurance(currentPatient)) {
        setInsuranceDetails(currentPatient?.insurance);
      } else if (hasInsurance(currentUser)) {
        setInsuranceDetails(currentUser?.insurance);
      } else {
        setInsuranceDetails(null);
      }
    }
  }, [currentPatient, currentUser, patientsList, getAccountHolder]);

  const {
    mutate: callCheckInsurance,
    payload: insuranceBreakdownResponse,
    loading: isCheckingInurance,
  } = useApiMutation(checkInsurance);

  const {
    payload: cashPriceResponse,
    loading: fetchingCashPrice,
    query: fetchCashPrice,
  } = useApiQuery(
    getCashPrice(incompleteData?.patient_id, currentSpeciality),
    false
  );

  const checkInsurancePrice = useCallback(
    async (updatedInsurance) => {
      const payload = updatedInsurance
        ? {
            ...updatedInsurance,
            patient_id: currentPatient?.id,
          }
        : {
            ...insurance_details,
            patient_id: currentPatient?.id,
          };
      await callCheckInsurance({
        userId: userId,
        payload,
        currentSpeciality,
      });
    },
    [
      callCheckInsurance,
      currentPatient?.id,
      insurance_details,
      currentSpeciality,
      userId,
    ]
  );

  useEffect(() => {
    if (insurance_details?.out_of_network === true) {
      setCashPrice(checkoutDetails?.amount);
      setInsuranceError(
        "Sorry! We aren't able to confirm whether your insurance will cover your visit. We can still submit a claim to your insurance, but you need to select Cash Payment to proceed."
      );
    } else {
      if (checkoutDetails?.insurance) {
        setInsuranceBreakDown({
          ...checkoutDetails,
          final_price: checkoutDetails?.amount,
          deductible_amount: checkoutDetails?.deductible,
          insurance_adjustment_amount: checkoutDetails?.insurance_adjustment,
        });
        if (!cashPrice) {
          fetchCashPrice();
        }
      } else if (checkoutDetails?.insurance === false) {
        setCashPrice(checkoutDetails?.amount);
      }
    }
  }, [
    checkoutDetails,
    insurance_details?.practice_insurance_id,
    insurance_details?.out_of_network,
    cashPrice,
    setCashPrice,
    setInsuranceBreakDown,
    fetchCashPrice,
  ]);

  useEffect(() => {
    if (insuranceBreakdownResponse) {
      if (insuranceBreakdownResponse?.error_message) {
        setPaymentMethod("cash");
        setInsuranceError(insuranceBreakdownResponse?.error_message);
      } else {
        setInsuranceError(null);
        setInsuranceBreakDown({
          final_price: insuranceBreakdownResponse?.final_price,
          deductible_amount: insuranceBreakdownResponse?.deductible,
          insurance_adjustment_amount:
            insuranceBreakdownResponse?.insurance_adjustment_amount,
          max_allowable: insuranceBreakdownResponse?.max_allowable,
          gross_charges_today: insuranceBreakdownResponse?.gross_charges_today,
          coinsurance_amount: insuranceBreakdownResponse?.coinsurance_amount,
          copay: insuranceBreakdownResponse?.copay,
          insurance_eligibility: insuranceBreakdownResponse?.active,
        });
      }
    }
  }, [insuranceBreakdownResponse, setInsuranceBreakDown, setPaymentMethod]);

  useEffect(() => {
    if (cashPriceResponse) {
      setCashPrice(cashPriceResponse?.cash_price);
    }
  }, [cashPriceResponse, setCashPrice]);

  useEffect(() => {
    if (isCheckingInurance || fetchingCashPrice) {
      setIsCheckingPrice(true);
    } else {
      setIsCheckingPrice(false);
    }
  }, [isCheckingInurance, fetchingCashPrice, setIsCheckingPrice]);

  useEffect(() => {
    if (insurance_details?.member_id) {
      if (insurance_details?.out_of_network) {
        setPaymentMethod("cash");
      }
    } else {
      setPaymentMethod("cash");
    }
  }, [insurance_details, setPaymentMethod]);

  const payment_data = [
    {
      price: insuranceBreakDown?.final_price,
      type: "insurance",
      component: () =>
        currentSpeciality === 2 ? (
          incompleteData?.accept_insurance || paymentMethod === "insurance" ? (
            <PCInsuranceOption
              speciality={currentSpeciality}
              currentPatient={currentPatient}
              loading={isCheckingInurance}
              disabled={isCheckingInurance}
              isInsuranceExpired={false}
              isActive={paymentMethod === "insurance"}
              onClick={() => {
                setPaymentMethod("insurance");
              }}
              selectCash={() => setPaymentMethod("cash")}
              showConfirm={() => {
                if (
                  incompleteData?.meta?.submitted_payment_info &&
                  incompleteData?.payment_method === "cash"
                ) {
                  setShowConfirm(true);
                }
              }}
              onClickAddInsurance={() =>
                onUpdatePress(
                  insurance_details,
                  currentPatient,
                  checkInsurancePrice
                )
              }
              onClickUpdateInsurance={() =>
                onUpdatePress(
                  insurance_details,
                  currentPatient,
                  checkInsurancePrice
                )
              }
              insurance_details={{
                ...insurance_details,
                ...insuranceBreakDown,
              }}
              checkingInsuranceInNetwork={checkingInsuranceInNetwork}
              insuranceInNetwork={insuranceInNetwork}
            />
          ) : (
            <div />
          )
        ) : // TODO: To be removed when BH starts accepting insurance
        currentSpeciality === 5 ? (
          <BHInsuranceOption />
        ) : incompleteData?.accept_insurance ||
          paymentMethod === "insurance" ? (
          <InsuranceOption
            speciality={currentSpeciality}
            currentPatient={currentPatient}
            loading={isCheckingInurance}
            disabled={isCheckingInurance}
            isInsuranceExpired={false}
            isActive={paymentMethod === "insurance"}
            onClick={() => {
              setPaymentMethod("insurance");
            }}
            selectCash={() => setPaymentMethod("cash")}
            showConfirm={() => {
              if (
                incompleteData?.meta?.submitted_payment_info &&
                incompleteData?.payment_method === "cash"
              ) {
                setShowConfirm(true);
              }
            }}
            onClickAddInsurance={() =>
              onUpdatePress(
                insurance_details,
                currentPatient,
                checkInsurancePrice
              )
            }
            onClickUpdateInsurance={() =>
              onUpdatePress(
                insurance_details,
                currentPatient,
                checkInsurancePrice
              )
            }
            insurance_details={{
              ...insurance_details,
              ...insuranceBreakDown,
            }}
            checkingInsuranceInNetwork={checkingInsuranceInNetwork}
            insuranceInNetwork={insuranceInNetwork}
          />
        ) : null,
    },
    {
      price: cashPrice,
      type: "cash",
      component: () => (
        <CashOption
          loading={fetchingCashPrice}
          disabled={fetchingCashPrice}
          isActive={paymentMethod === "cash"}
          onClick={() => {
            if (
              incompleteData?.meta?.submitted_payment_info &&
              incompleteData?.payment_method === "insurance"
            ) {
              setShowConfirm(true);
            }
            setPaymentMethod("cash");
            if (!cashPrice) {
              fetchCashPrice();
            }
          }}
          price={cashPrice}
          speciality={currentSpeciality}
        />
      ),
    },
  ];

  const sortedOptions = payment_data.sort((a, b) => a?.price - b?.price);

  return (
    <div>
      {sortedOptions.map((option, index) => (
        <div key={index}>
          <option.component />
          {index === 0 ? <Spacer height={15} /> : null}
        </div>
      ))}
      {showConfirm ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-60">
          <div className="flex flex-col w-11/12 max-w-2xl p-8 space-y-8 bg-white">
            <p className="text-3xl font-bold">Attention</p>
            <p className="text-2xl font-medium leading-9">
              You had initially selected {incompleteData?.payment_method} as
              your payment method. If you want to change it to{" "}
              {incompleteData?.payment_method === "cash" ? "insurance" : "cash"}
              , you will have to resubmit the{" "}
              <span className="font-bold">Enter your payment details</span>{" "}
              step.
            </p>
            <div className="flex items-center justify-end space-x-4">
              <button
                className="flex items-center justify-center p-4 text-xl font-bold uppercase rounded bg-indigo bg-opacity-20 text-indigo min-w-32"
                onClick={() => setShowConfirm(false)}
              >
                Got it!
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default SelectPaymentMethod;
