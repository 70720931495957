import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import ChatActions from "./ChatActions";
import {
  markConversationRead,
  visitDetailsAction,
} from "api/actions/VisitActions";
import LoaderComponent from "components/loader/LoaderComponent";
import ChatBubble from "./ChatBubble";
import Spacer from "components/Spacer/Spacer";
import { useApiMutation } from "hooks/useApiMutation";
import { useApiQuery } from "hooks/useApiQuery";
import { reloadConversation, sendConversation } from "api/actions/UserActions";
import { useDispatch } from "react-redux";
import {
  updateVisitMessageCount,
  updateVisitMessagePayload,
} from "reducers/currentUserReducer";
import useModal from "hooks/useModal";
import EditMessageModal from "./EditMessageModal";
import { useCurrentUser } from "hooks/useCurrentUser";
import moment from "moment";
import { Icon } from "stories/Icons";
import SimpleHeader from "components/header/SimpleHeader";

function ChatScreen() {
  const MAX_FILES = 4;
  const template = Array.from(Array(MAX_FILES)).map((_) => ({
    uri: "",
    file: "",
    data: "",
  }));

  const messagesEndRef = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const { visit_id } = useParams();
  const { setShowModal } = useModal();
  const { visitMessagePayload } = useCurrentUser();
  const {
    loading: fetchingVisitDetails,
    payload: visitDetails,
    query: queryVisitDetails,
  } = useApiQuery(visitDetailsAction(visit_id), false);
  const {
    mutate: callSendConversation,
    loading: sendingMessage,
  } = useApiMutation(sendConversation);
  const { mutate: callMarkConversationRead } = useApiMutation(
    markConversationRead
  );

  const { loading: fetchChats, query: refreshChatsQuery } = useApiQuery(
    reloadConversation(visit_id),
    false
  );
  const [inputText, setInputText] = useState("");
  const [fileArray, setFileArray] = useState(template);

  useEffect(() => {
    return () => {
      dispatch(updateVisitMessagePayload({}));
    };
  }, [dispatch]);

  useEffect(() => {
    if (visitMessagePayload?.messages?.length) {
      scrollToBottom();
    }
  }, [visitMessagePayload?.messages]);

  useEffect(() => {
    async function callConversationRead() {
      try {
        const { payload } = await callMarkConversationRead(visit_id);
        dispatch(
          updateVisitMessageCount({
            visitMessageCount: payload?.unread_messages,
          })
        );
      } catch (error) {}
    }
    // if (visitMessagePayload?.unread_provider_messages) {
    callConversationRead();
    // }
  }, [
    visit_id,
    // visitMessagePayload?.unread_provider_messages,
    dispatch,
    callMarkConversationRead,
  ]);

  useEffect(() => {
    if (!visitMessagePayload?.messages?.length) {
      queryVisitDetails();
    }
  }, [visitMessagePayload?.messages, queryVisitDetails]);

  useEffect(() => {
    if (visitDetails && !visitDetails?.error) {
      dispatch(
        updateVisitMessagePayload({
          can_message: visitDetails?.follow_up,
          unread_provider_messages: visitDetails?.unread_provider_messages,
          messages: visitDetails?.messages,
        })
      );
    }
  }, [visitDetails, dispatch]);

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView();
  };

  async function onSend(docFiles) {
    try {
      const formData = new FormData();
      formData.append("conversation", JSON.stringify({ message: inputText }));

      if (docFiles) {
        const pdfFiles = docFiles?.filter(
          (el) => el?.uri?.includes(".pdf") || el?.uri?.includes(".PDF")
        );
        const imageFiles = docFiles?.filter(
          (el) =>
            el?.uri?.includes(".pdf") || el?.uri?.includes(".PDF") === false
        );

        if (imageFiles?.length) {
          imageFiles.forEach((image, index) => {
            formData.append(`photo_url_${index + 1}`, image?.file);
          });
        }
        if (pdfFiles?.length) {
          pdfFiles.forEach((pdf) => {
            formData.append("attachments[]", pdf?.file);
            // formData.append(`photo_url_${1}`, pdf?.file);
          });
        }
      }
      const result = await callSendConversation({
        caseId: visit_id,
        payload: formData,
      });
      if (result?.payload?.message) {
        setInputText("");
        setFileArray(template);
        if (Number(result?.payload?.message?.can_edit) === 1) {
          dispatch(
            updateVisitMessagePayload({
              ...visitMessagePayload,
              messages: [
                ...visitMessagePayload?.messages?.map((el) => ({
                  ...el,
                  can_edit: "0",
                })),
                result?.payload?.message,
              ],
            })
          );
        } else {
          dispatch(
            updateVisitMessagePayload({
              ...visitMessagePayload,
              messages: [
                ...visitMessagePayload?.messages,
                result?.payload?.message,
              ],
            })
          );
        }
      }
    } catch (error) {}
  }

  async function reload() {
    refreshChatsQuery().then((e) => {
      if (e?.payload?.messages.length > 0) {
        dispatch(
          updateVisitMessagePayload({
            ...visitMessagePayload,
            messages: e?.payload?.messages,
          })
        );
      }
    });
  }

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (fetchingVisitDetails) {
    return <LoaderComponent showLoader={{ state: true }} />;
  }

  return (
    <div className="h-full overflow-x-hidden overflow-y-auto bg-alternativeWhite">
      <SimpleHeader
        title={
          visitDetails?.speciality === "cosmetic" ||
          location?.state?.speciality === "cosmetic"
            ? `Consultation #${visit_id}`
            : `Visit #${visit_id}`
        }
        bgColor="bg-alternativeWhite"
        showBackButton
        className={"border-l border-r border-t wrapper"}
        parentClass={"wrapper"}
        rightActionView={
          <button onClick={reload}>
            <Icon name={"refresh"} loading={fetchChats} />
          </button>
        }
      />
      <div className="relative h-eliminateHeader wrapper">
        <div className="h-full">
          <div className="min-h-full px-8 bg-white border-l border-r ">
            {visitMessagePayload?.messages?.map((message, index) => {
              return (
                <ChatBubble
                  showDateBanner={
                    visitMessagePayload?.messages?.[index - 1]
                      ? moment(
                          visitMessagePayload?.messages?.[index - 1]?.created_at
                        ).format("DD MMM YYYY") !==
                        moment(message?.created_at).format("DD MMM YYYY")
                        ? true
                        : false
                      : true
                  }
                  visit_id={visit_id}
                  message={message}
                  key={index}
                  onClickEdit={() => {
                    setShowModal({
                      state: true,
                      children: (_, onClose) => (
                        <EditMessageModal
                          onClose={onClose}
                          message={message}
                          visitId={visit_id}
                          updateMessage={(updatedMsg) => {
                            dispatch(
                              updateVisitMessagePayload({
                                ...visitMessagePayload,
                                messages: visitMessagePayload?.messages?.map(
                                  (el) => {
                                    if (
                                      el?.message_id === updatedMsg?.message_id
                                    ) {
                                      return updatedMsg;
                                    } else {
                                      return el;
                                    }
                                  }
                                ),
                              })
                            );
                            reload();
                          }}
                        />
                      ),
                    });
                  }}
                />
              );
            })}
            <Spacer height={150} />
            <div ref={messagesEndRef} />
          </div>
        </div>
        <ChatActions
          onChangeText={setInputText}
          value={inputText}
          onSend={onSend}
          sendingMessage={sendingMessage}
          fileArray={fileArray}
          setFileArray={setFileArray}
          MAX_FILES={MAX_FILES}
          canUserReply={visitMessagePayload?.can_message}
        />
      </div>
    </div>
  );
}

export default ChatScreen;
