import React, { useEffect, useState } from "react";
import { getCashPrice } from "api/actions/VisitActions";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import ChoosePaymentMethod from "components/choosePaymentMethod/ChoosePaymentMethod";
import Header from "components/header/Header";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import { useApiQuery } from "hooks/useApiQuery";
import { useCurrentUser } from "hooks/useCurrentUser";
import usePatients from "hooks/visit/usePatients";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";
import { bh_sub_steps, speciality } from "helpers/enum";
import useModal from "hooks/useModal";
import InsuranceUpdateModal from "../../common/InsuranceUpdateModal";
import { useDispatch } from "react-redux";
import LoaderComponent from "components/loader/LoaderComponent";
import { useHistory } from "react-router-dom";
import useVisitStepChange from "hooks/useVisitStepChange";
import useUpdateBehaviorHealthVisit from "hooks/visit/useUpdateBehaviorHealthVisit";
import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";
import { checkInsuranceNetworkAction } from "api/actions/UserActions";
import useToastify from "hooks/useToastify";
import HelpButton from "../../common/HelpButton";
import { trackEvent } from "helpers/analytics";

function BehaviorHealthVisitPricing() {
  const { showAlert } = useToastify();
  const [selected, setSelected] = useState("cash");
  const [insurance_details, setInsuranceDetails] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const { setShowModal } = useModal();
  const { userDetails } = useCurrentUser();
  const { loading } = useVisitStepChange();
  const {
    patientsList,
    incompleteData,
    paymentMethod,
  } = useBehaviorHealthVisitFlow();
  const { getCurrentPatient, getAccountHolder } = usePatients();
  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );
  const { updateVisit } = useUpdateBehaviorHealthVisit();
  const {
    payload: cashPriceResponse,
    loading: fetchingCashPrice,
    query: fetchCashPrice,
  } = useApiQuery(
    getCashPrice(incompleteData?.patient_id, speciality.behavior_health),
    false
  );

  const {
    loading: checkingInsuranceInNetwork,
    payload: insuranceInNetwork,
  } = useApiQuery(
    checkInsuranceNetworkAction(
      speciality.behavior_health,
      incompleteData?.patient_id
    ),
    true
  );

  function hasInsurance(patient) {
    if (patient?.insurance && patient?.insurance?.insurance_company) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (paymentMethod) {
      setSelected(paymentMethod);
    }
  }, [paymentMethod, setSelected]);

  useEffect(() => {
    if (patientsList) {
      if (hasInsurance(currentPatient)) {
        setInsuranceDetails(currentPatient?.insurance);
      } else if (hasInsurance(getAccountHolder(patientsList))) {
        setInsuranceDetails(getAccountHolder(patientsList)?.insurance);
      } else {
        setInsuranceDetails(null);
      }
    }
  }, [
    currentPatient,
    patientsList,
    getAccountHolder,
    getCurrentPatient,
    incompleteData?.patient_id,
  ]);

  useEffect(() => {
    fetchCashPrice();
  }, [fetchCashPrice]);

  // TODO: Uncomment this once insurance is accepted in BH flow
  // useEffect(() => {
  //   if (insurance_details?.member_id) {
  //     if (insurance_details?.out_of_network) {
  //       setSelected("cash");
  //     } else {
  //       setSelected("insurance");
  //     }
  //   }
  // }, [insurance_details]);

  async function AcceptPricing() {
    trackEvent("book_appointment_pc_visit", {
      uid: userDetails?.id,
      paymentMethod: selected,
    });
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          payment_method: selected === "cash" ? 0 : 1,
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications,
          bh_second_phase_steps: {
            ...incompleteData?.bh_second_phase_steps,
            [bh_sub_steps.select_patient]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: bh_sub_steps.select_patient,
      callBack: () => {
        dispatch(
          behaviorHealthVisitFlowReducers.setPaymentMethod({
            value: selected,
          })
        );
        showAlert("Progress Saved Successfully", "success");
        setTimeout(() => {
          history.goBack();
        }, 500);
      },
    });
  }

  if (fetchingCashPrice) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
          message: "Please wait",
        }}
      />
    );
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <Header
        title="Pricing Estimate / Payment Options"
        hideBackBtn={loading}
      />
      <div className="px-8 overflow-y-auto h-eliminateHeaderWithFooter wrapper bg-blue">
        <Spacer height={30} />
        <MedicalAssistant
          content={
            <p className="flex flex-col space-y-4 leading-tight">
              <span>
                {userDetails?.first_name}, let's review pricing options before
                we start.
              </span>
              <span>
                First, we do not charge you until after you've been seen by one
                of our therapists. Feel free to book an appointment at no
                up-front cost.
              </span>
              <span>
                Second, you can trust that we will let you know if you're
                in-network, or out-of-network, before your appointment.
              </span>
              <span>
                Third, we give our patients an estimate of any visit charges.
                Our staff will verify your insurance and benefits once we have
                your health insurance information.
              </span>
            </p>
          }
        />
        <ChoosePaymentMethod
          acceptInsurance={incompleteData?.accept_insurance}
          speciality={speciality.behavior_health}
          currentPatient={getCurrentPatient(
            incompleteData?.patient_id,
            patientsList
          )}
          isInsuranceExpired={false}
          cashPriceResponse={cashPriceResponse}
          insuranceDetails={{
            ...insurance_details,
          }}
          onClickAddInsurance={() => {
            setShowModal({
              state: true,
              children: (_, onClose) => (
                <InsuranceUpdateModal
                  onClose={onClose}
                  insuranceDetails={insurance_details}
                  currentPatient={getCurrentPatient(
                    incompleteData?.patient_id,
                    patientsList
                  )}
                  currentSpeciality={speciality.behavior_health}
                />
              ),
              data: null,
            });
          }}
          onClickUpdateInsurance={() => {
            setShowModal({
              state: true,
              children: (_, onClose) => (
                <InsuranceUpdateModal
                  onClose={onClose}
                  insuranceDetails={insurance_details}
                  currentPatient={getCurrentPatient(
                    incompleteData?.patient_id,
                    patientsList
                  )}
                  currentSpeciality={speciality.behavior_health}
                />
              ),
              data: null,
            });
          }}
          onSelect={setSelected}
          selected={selected}
          checkingInsuranceInNetwork={checkingInsuranceInNetwork}
          insuranceInNetwork={insuranceInNetwork}
        />
      </div>
      <HelpButton bottomClass={"bottom-40"} />
      <BottomFixedButton
        label="Proceed with Booking"
        onClick={!selected ? null : AcceptPricing}
        loading={loading}
        disabled={
          loading ||
          checkingInsuranceInNetwork ||
          !selected ||
          (selected === "insurance"
            ? insurance_details.auth_id_required &&
              !incompleteData?.insurance_authorisation?.auth_id
            : false)
        }
      />
    </div>
  );
}

export default BehaviorHealthVisitPricing;
