import React, { useEffect, useState } from "react";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Spacer from "components/Spacer/Spacer";
import Button from "components/buttons/Button";
import useModal from "hooks/useModal";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";
import { bh_sub_steps, speciality } from "helpers/enum";
import AddSomeoneElseModal from "../../common/AddSomeoneElseModal";

function SelectSomeoneElse({
  incompleteData,
  createNewVisit,
  creatingVisit,
  updateVisit,
  updatingVisit,
  setActiveIndex,
  goBackToSelf,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setShowModal } = useModal();
  const [selected, setSelected] = useState(null);
  const { patientsList, subSteps } = useBehaviorHealthVisitFlow();
  const currentScreenValueFromStore =
    subSteps[bh_sub_steps.select_someone_else].value;

  useEffect(() => {
    if (currentScreenValueFromStore) {
      setSelected(currentScreenValueFromStore);
    }
  }, [currentScreenValueFromStore, patientsList]);

  function onRightButtonClick() {
    if (incompleteData && Object.keys(incompleteData)?.length) {
      updateVisit({
        payload: {
          case: {
            id: incompleteData?.id,
            patient_id: patientsList?.find((el) => el?.full_name === selected)
              ?.id,
            medications:
              typeof incompleteData?.medications[0] === "string"
                ? incompleteData?.medications.map((item) => ({
                    name: item,
                  }))
                : incompleteData?.medications,
          },
        },
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            behaviorHealthVisitFlowReducers.select_someone_else({
              value: selected,
            })
          );
          setActiveIndex(1);
          history.push("/visit/behavior-health/pricing");
        },
      });
    } else {
      createNewVisit({
        patient_id: patientsList?.find((el) => el?.full_name === selected)?.id,
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            behaviorHealthVisitFlowReducers.select_someone_else({
              value: selected,
            })
          );
          setActiveIndex(1);
          history.push("/visit/behavior-health/pricing");
        },
      });
    }
  }

  return (
    <>
      <RadioButtonCard
        loading={creatingVisit}
        options={patientsList
          ?.filter(
            (patient) =>
              patient?.relationship_with_account &&
              patient.relationship_with_account !== "myself" &&
              patient.relationship_with_account !== "pappa"
          )
          ?.map((el) => el?.full_name)}
        onSelect={setSelected}
        selected={selected}
      />
      <Spacer height="38" />
      <Button
        disabled={updatingVisit}
        onClick={() => {
          setShowModal({
            state: true,
            children: (_, onClose) => (
              <AddSomeoneElseModal
                onClose={(e) => {
                  if (e !== null) {
                    setSelected(e);
                  }
                  onClose();
                }}
                currentSpeciality={speciality.behavior_health}
              />
            ),
            data: null,
          });
        }}
        label="Add Patient"
        outlined
      />
      <div className="flex justify-between items-center">
        <Button
          className="flex-1 py-4 mt-10"
          label="Back"
          onClick={goBackToSelf}
        />
        <Spacer width={25} />
        <Button
          disabled={!selected}
          className="flex-1 py-4 mt-10"
          loading={updatingVisit || creatingVisit}
          label="Next"
          onClick={onRightButtonClick}
        />
      </div>
    </>
  );
}

export default SelectSomeoneElse;
