import { bh_sub_steps } from "helpers/enum";
import ConfirmResidenceForBehaviorHealth from "./basicDetails/ConfirmResidenceForBehaviorHealth";
import SelectPatientForBehaviorHealth from "./basicDetails/SelectPatientForBehaviorHealth";
import IdentityConfirmationForBehaviorHealth from "./basicDetails/IdentityConfirmationForBehaviorHealth";
import SelectConditionForBehaviorHealth from "./visitDetails/SelectConditionForBehaviorHealth";
import SymptomsForBehaviorHealth from "./visitDetails/SymptomsForBehaviorHealth";
import SelectPharmacyForBehaviorHealth from "./additionalDetails/SelectPharmacyForBehaviorHealth";
import AdditionalInfo from "./additionalDetails/AdditionalInfo";
import SelectSlot from "./selectSlot/SelectSlot";
import SelectChildForBehaviorHealth from "./basicDetails/SelectChildForBehaviorHealth";
import SelectSomeoneElse from "./basicDetails/SelectSomeoneElseForBehaviorHealth";

export const getBehaviorHealthStepScreen = (currentScreen) => {
  switch (currentScreen) {
    case bh_sub_steps.select_patient:
      return <SelectPatientForBehaviorHealth />;
    case bh_sub_steps.select_child:
      return <SelectChildForBehaviorHealth />;
    case bh_sub_steps.select_someone_else:
      return <SelectSomeoneElse />;
    case bh_sub_steps.confirm_residence:
      return <ConfirmResidenceForBehaviorHealth />;
    case bh_sub_steps.identity_confirmation:
      return <IdentityConfirmationForBehaviorHealth />;
    case bh_sub_steps.select_condition:
      return <SelectConditionForBehaviorHealth />;
    case bh_sub_steps.symptoms:
      return <SymptomsForBehaviorHealth />;
    case bh_sub_steps.select_slot:
      return <SelectSlot />;
    case bh_sub_steps.select_pharmacy:
      return <SelectPharmacyForBehaviorHealth />;
    case bh_sub_steps.additional_info:
      return <AdditionalInfo />;
    default:
      break;
  }
};
