import { bh_main_steps, bh_sub_steps } from "helpers/enum";

export const behaviorHealthStepsData = (mainSteps, subSteps) => [
  {
    main_step: {
      name: "Visit Details",
      status: mainSteps?.[bh_main_steps.visit_details]?.status,
    },
    sub_steps: [
      {
        name: "Select Patient",
        status: subSteps?.[bh_sub_steps.select_patient]?.status,
        key: bh_sub_steps.select_patient,
      },
      {
        name: "Select Condition",
        status: subSteps?.[bh_sub_steps.select_condition]?.status,
        key: bh_sub_steps.select_condition,
      },
    ],
  },
  {
    main_step: {
      name: "Select Your Appointment Date and Time",
      status: mainSteps?.[bh_main_steps.main_select_slot]?.status,
    },
    sub_steps: [
      {
        name: "Select Your Appointment Date and Time",
        status: subSteps?.[bh_sub_steps.select_slot]?.status,
        key: bh_sub_steps.select_slot,
      },
    ],
  },
  {
    main_step: {
      name: "Basic Details",
      status: mainSteps?.[bh_main_steps.basic_info]?.status,
    },
    sub_steps: [
      {
        name: "Confirm Residence",
        status: subSteps?.[bh_sub_steps.confirm_residence]?.status,
        key: bh_sub_steps.confirm_residence,
      },
      {
        name: "Identity Confirmation",
        status: subSteps?.[bh_sub_steps.identity_confirmation]?.status,
        key: bh_sub_steps.identity_confirmation,
      },
    ],
  },
  {
    main_step: {
      name: "Additional Details",
      status: mainSteps?.[bh_main_steps.additional_details]?.status,
    },
    sub_steps: [
      {
        name: "Select Pharmacy",
        status: subSteps?.[bh_sub_steps.select_pharmacy]?.status,
        key: bh_sub_steps.select_pharmacy,
      },
      {
        name: "Symptoms",
        status: subSteps?.[bh_sub_steps.symptoms]?.status,
        key: bh_sub_steps.symptoms,
      },
      {
        name: "Additional Info",
        status: subSteps?.[bh_sub_steps.additional_info]?.status,
        key: bh_sub_steps.additional_info,
      },
    ],
  },
];
