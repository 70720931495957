import React, { useEffect } from "react";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";
import usePatients from "hooks/visit/usePatients";
import { bh_sub_steps } from "helpers/enum";
import useImageUpload from "hooks/visit/useImageUpload";
import { useDispatch } from "react-redux";
import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";
import ConfirmIdentityImagePickerPed from "components/confirmIdentityImagePickerPed/ConfirmIdentityImagePickerPed";
import Button from "components/buttons/Button";
import useToastify from "hooks/useToastify";

function IdentityConfirmation({
  alertDesktopPatient,
  firstTimeRef,
  imagePickerRef,
  incompleteData,
  updateVisit,
  updatingVisit,
  setActiveIndex,
  imageData,
  setImageData,
}) {
  const dispatch = useDispatch();
  const { uploadImage } = useImageUpload();
  const { patientsList, subSteps } = useBehaviorHealthVisitFlow();
  const { getCurrentPatient } = usePatients();
  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );
  const currentScreenValue =
    subSteps?.[bh_sub_steps.identity_confirmation]?.value;

  const { showAlert } = useToastify();

  useEffect(() => {
    if (firstTimeRef.current) {
      if (currentScreenValue && currentScreenValue?.uri?.length) {
        setImageData(currentScreenValue);
      } else {
        if (currentPatient) {
          if (currentPatient?.insurance?.id_proof?.length) {
            setImageData({
              uri: currentPatient?.insurance?.id_proof,
              file: null,
              data: null,
            });
          }
        }
      }
      firstTimeRef.current = false;
    }
  }, [currentPatient, currentScreenValue, firstTimeRef, setImageData]);

  function onRightButtonClick(_, nextAnimation) {
    if (!imageData?.uri?.startsWith("https")) {
      uploadImage(imageData);
    }
    updateVisit({
      payload: {
        case: {
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications,
          bh_second_phase_steps: {
            ...incompleteData?.bh_second_phase_steps,
            [bh_sub_steps.identity_confirmation]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        dispatch(
          behaviorHealthVisitFlowReducers.identity_confirmation({
            value: imageData,
          })
        );
        showAlert("Progress Saved Successfully", "success");
        setActiveIndex(5);
        nextAnimation();
      },
    });
  }

  function handleImageChange(event) {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      let reader = new FileReader();
      reader.onload = (event) => {
        let file_object;
        file_object = {
          uri: files?.[0]?.name?.toString(),
          file: files[0],
          filename: files?.[0]?.name?.toString(),
          data: event?.target?.result,
        };
        setImageData(file_object);
      };
      reader.readAsDataURL(files[0]);
    }
  }
  return (
    <div className="flex flex-col items-center space-y-10 bg-white shadow-card rounded-b-4xl p-10">
      <p className="font-medium text-2xl text-center md:text-3xl">
        Please upload a picture of your photo ID below (e.g., Driver's license,
        Passport, etc.) [Accepted image formats: PNG, JPG, JPEG].
        <br />
        <br />
        If the patient is a minor please upload the guardians photo ID.
      </p>
      <div>
        <ConfirmIdentityImagePickerPed
          alertDesktopPatient={alertDesktopPatient}
          imagePickerRef={imagePickerRef}
          handleChange={handleImageChange}
          imageUrl={
            imageData
              ? imageData?.uri?.startsWith("http")
                ? imageData?.uri
                : imageData?.data
              : ""
          }
        />
      </div>
      <Button
        className="py-4"
        loading={updatingVisit}
        disabled={!imageData}
        onClick={onRightButtonClick}
        label="Next"
      />
    </div>
  );
}

export default IdentityConfirmation;
