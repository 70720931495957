import { startCase } from "lodash";
import { useHistory } from "react-router-dom";
function ActiveVisitsBanner({ speciality, id }) {
  const history = useHistory();
  return (
    <div className="flex flex-col md:flex-row md:items-center justify-between w-full p-8 bg-white border rounded-xl">
      <div className="flex flex-col">
        <h2 className="text-2.5xl font-bold text-black">
          {`Your ${
            speciality === "behavior_health"
              ? "Behavioral Health"
              : startCase(speciality)
          } Visit #${id} Status`}
        </h2>
        <p className="mt-2 text-xl md:text-2xl font-medium text-themeBlack">
          For more information look into
        </p>
      </div>
      <button
        onClick={() => {
          sessionStorage.setItem("selectedTabIndex", 1);
          history.push("/home/my-visits");
        }}
        className="px-16 py-4 mt-10 md:mt-0 text-2xl font-semibold text-white no-underline rounded-lg bg-orange"
      >
        Visit
      </button>
    </div>
  );
}
export default ActiveVisitsBanner;
