import React, { useEffect, useState } from "react";
import Images from "assets/images";

function SliderSection() {
  return (
    <div>
      <h2 className="pt-4 pb-1 text-4xl font-bold">Connect with Top Doctors</h2>
      <div className="pt-10 ">
        <Carousel />
      </div>
    </div>
  );
}
const slides = [
  {
    name: "Urgent Care",
    content: [
      "Book by-appointment or next available slot",
      "Complete a medical intake / history",
      "Video and chat securely with your doctor",
      "Prescriptions and refills, as needed",
      "Visit includes detailed report for future reference",
    ],
    image: Images.banner.UrgentBanner,
  },
  {
    name: "Dermatology",
    content: [
      "Upload photos of your skin concern",
      "Chat securely with your doctor",
      "Prescriptions and refills, as needed",
      "Visit includes detailed report for future reference",
    ],
    image: Images.banner.DermaBanner,
  },
  {
    name: "Pediatrics",
    content: [
      "Book by-appointment or next available slot",
      "Complete a medical intake / history",
      "Video and chat securely with your doctor",
      "Prescriptions and refills, as needed",
      "Visit includes detailed report for future reference",
    ],
    image: Images.banner.PediaBanner,
  },
  {
    name: "Behavioral Health",
    content: [
      "Established care with a licensed therapist",
      "50 minute face-to -face video call",
      "Convenient, effective and affordable",
      "Unlimited portal support",
    ],
    image: Images.banner.BHBanner,
  },
  {
    name: "Cosmetic Consultation",
    content: [
      "Up to 30-min video consult with doctor",
      "Discuss recommended services / products",
      "Chat securely with your doctor",
      "Discuss what you should / shouldn't expect to pay",
      "Visit includes detailed report for future reference",
    ],
    image: Images.banner.CosmeticBanner,
  },
];

function ImageWithButtons({ images, currentSlide, setCurrentSlide }) {
  return (
    <div className="relative self-center w-full md:w-1/2">
      <img
        src={images[currentSlide].image}
        alt={`slide_${currentSlide}`}
        className="object-cover w-full max-h-96 rounded-xl"
      />
      <div className="absolute left-0 right-0 flex justify-center mt-4 bottom-4">
        {images.map((_, index) => (
          <button
            key={index}
            className={`${
              index === currentSlide ? "bg-white" : "bg-gray-400"
            } h-4 w-4 rounded-full mx-1.5 transition ease-in duration-300`}
            onClick={() => setCurrentSlide(index)}
          ></button>
        ))}
      </div>
    </div>
  );
}

const Carousel = ({ images = slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [currentSlide, images.length]);

  return (
    <div className="p-6 bg-white border rounded-2xl">
      <div className="flex flex-col md:flex-row">
        <ImageWithButtons
          images={images}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
        />
        <div className="self-center w-full px-0 py-8 md:px-8 md:w-1/2 md:pl-24">
          <h1 className="inline-block p-3 mb-6 text-3xl font-bold text-secondaryBlue-600 bg-secondaryBlue-100">
            {images[currentSlide].name}
          </h1>
          <div className="flex flex-col space-y-4">
            {images[currentSlide].content.map((e, index) => (
              <div className="flex items-center space-x-4" key={index}>
                <img
                  className="w-8 h-8"
                  src={Images.checkedGreen}
                  alt="expression"
                />
                <p className="text-2xl font-light leading-8 text-black">{e}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderSection;
