import React, { useEffect, useState } from "react";

import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Spacer from "components/Spacer/Spacer";
import { useDispatch } from "react-redux";
import { usePedVisitFlow } from "hooks/useVisitFlow";
import { useHistory } from "react-router-dom";
import { ped_sub_steps } from "helpers/enum";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";
import Button from "components/buttons/Button";
import AddChildModal from "../../common/AddChildModal";
import useModal from "hooks/useModal";
import usePatients from "hooks/visit/usePatients";
import CSSLoader from "components/cssLoader/CSSLoader";

function SelectPatient({
  incompleteData,
  gettingPatients,
  gettingProfileDetails,
  createNewVisit,
  creatingVisit,
  updateVisit,
  updatingVisit,
  setActiveIndex,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setShowModal } = useModal();

  const { fetchingPatients, getPatients } = usePatients();
  const { patientsList, subSteps } = usePedVisitFlow();
  const currentScreenValueFromStore =
    subSteps[ped_sub_steps.select_patient].value;

  const [selected, setSelected] = useState(null);
  const [childAdded, setChildAdded] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (gettingPatients === false && gettingProfileDetails === false) {
      setShowLoader(false);
    }
  }, [gettingPatients, gettingProfileDetails]);

  useEffect(() => {
    if (!fetchingPatients) {
      if (childAdded) {
        setSelected(
          patientsList
            ?.filter(
              (patient) =>
                patient?.relationship_with_account &&
                patient.relationship_with_account === "pappa"
            )
            .slice(-1)[0]?.full_name
        );
      }
      setChildAdded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childAdded, patientsList]);

  useEffect(() => {
    if (currentScreenValueFromStore) {
      setSelected(currentScreenValueFromStore);
    } else {
      if (
        patientsList?.filter(
          (patient) =>
            patient?.relationship_with_account &&
            patient.relationship_with_account === "pappa"
        ).length === 1
      ) {
        setSelected(
          patientsList?.filter(
            (patient) =>
              patient?.relationship_with_account &&
              patient.relationship_with_account === "pappa"
          )[0]?.full_name
        );
      }
    }
  }, [currentScreenValueFromStore, patientsList]);

  function onRightButtonClick() {
    if (incompleteData && Object.keys(incompleteData)?.length) {
      updateVisit({
        payload: {
          case: {
            id: incompleteData?.id,
            allergies: incompleteData?.allergies_json,
            medications:
              typeof incompleteData?.medications[0] === "string"
                ? incompleteData?.medications.map((item) => ({
                    name: item,
                  }))
                : incompleteData?.medications,
            patient_id: patientsList?.find((el) => el?.full_name === selected)
              ?.id,
            pediatric_steps: incompleteData?.pediatric_steps,
          },
        },
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            pedVisitFlowReducers.select_patient({
              value: selected,
            })
          );
          setActiveIndex(1);
          history.push("/visit/pediatrics/pricing");
        },
      });
    } else {
      createNewVisit({
        patient_id: patientsList?.find((el) => el?.full_name === selected)?.id,
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            pedVisitFlowReducers.select_patient({
              value: selected,
            })
          );
          history.push("/visit/pediatrics/pricing");
        },
      });
    }
  }

  return (
    <div className="bg-white rounded-b-4xl p-10 relative overflow-x-hidden">
      {showLoader ? (
        <div className="flex-1 py-10 flex justify-center items-center">
          <CSSLoader className="w-16 h-16" color="orange" />
        </div>
      ) : (
        <>
          <RadioButtonCard
            loading={creatingVisit}
            options={patientsList
              ?.filter(
                (patient) =>
                  patient?.relationship_with_account &&
                  patient.relationship_with_account === "pappa"
              )
              ?.map((el) => el?.full_name)}
            onSelect={setSelected}
            selected={selected}
          />
          <Spacer height="38" />
          <Button
            disabled={updatingVisit}
            onClick={() => {
              setShowModal({
                state: true,
                children: (_, onClose) => (
                  <AddChildModal
                    onClose={onClose}
                    onComplete={() => {
                      getPatients();
                      setChildAdded(true);
                    }}
                  />
                ),
                data: null,
              });
            }}
            label="Add Child"
            outlined
          />
          <Button
            disabled={!selected}
            className="py-4 mt-10"
            loading={updatingVisit || creatingVisit}
            label="Next"
            onClick={onRightButtonClick}
          />
        </>
      )}
    </div>
  );
}

export default SelectPatient;
