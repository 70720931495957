import Images from "assets/images";
import React from "react";

import "./RadioButtonCard.scss";

function RadioButtonCard({
  options,
  infoTexts,
  infos,
  showInfo,
  selected,
  onSelect,
  disabled = [],
  variant = "standard",
}) {
  return (
    <div className="radio_button_card_container">
      {options?.map((el, index) => {
        return (
          <div
            key={index}
            className={`radio_button_card rounded-xl cursor-pointer flex items-center justify-between ${
              variant === "small" ? "px-6 py-4" : "p-6"
            } border ${
              selected === el ? "border-orange" : "border-borderColor"
            } ${index !== options?.length - 1 ? "mb-8" : ""} ${
              disabled.includes(el) ? "cursor-not-allowed" : ""
            }`}
            onClick={() => (disabled.includes(el) ? null : onSelect(el))}
          >
            <div>
              <p
                className={`${
                  variant === "small"
                    ? "text-xl font-medium"
                    : "text-2xl font-bold"
                } text-indigo`}
              >
                {el}
                {infos?.length && infos?.[index]?.length ? (
                  <span
                    onClick={() =>
                      showInfo({
                        label: options?.[index],
                        value: infos?.[index],
                      })
                    }
                    className="inline-block w-8 h-8 ml-3 font-semibold text-center text-white rounded-full cursor-pointer bg-indigo bg-opacity-40"
                  >
                    ?
                  </span>
                ) : null}
              </p>
              {infoTexts?.length ? (
                <p
                  className={`${
                    infoTexts[index] === "Coming Soon" ? "w-full" : "w-4/5"
                  } pt-1 text-2xl font-medium opacity-40 md:w-full`}
                >
                  {infoTexts?.[index]}
                </p>
              ) : null}
            </div>
            {disabled?.includes(el) ? null : (
              <img
                className={`${variant === "small" ? "w-6 h-6" : "w-10 h-10"}`}
                src={
                  selected === el ? Images.radioChecked : Images.uncheckedCircle
                }
                alt="unchecked"
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default RadioButtonCard;
