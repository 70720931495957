import { useHistory } from "react-router-dom";
import { updateCase } from "api/actions/VisitActions";
import { bh_sub_steps, speciality, step_status } from "helpers/enum";
import { setBHStepStatus } from "helpers/setBHStepStatus";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";
import useIncompleteData from "./useIncompleteData";

function useUpdateBehaviorHealthVisit() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showAlert } = useToastify();
  const { setLoading } = useVisitStepChange();
  const {
    currentScreen,
    subSteps,
    incompleteData,
    completedProgress,
  } = useBehaviorHealthVisitFlow();
  const { mutate: callUpdateCase, loading: updatingVisit } = useApiMutation(
    updateCase
  );
  const { fetchIncompleteData, fetchingIncompleteData } = useIncompleteData();

  const newSubSteps = {
    [bh_sub_steps.select_patient]: {
      progress: 50,
    },
    [bh_sub_steps.select_condition]: {
      progress: 75,
    },
    [bh_sub_steps.select_slot]: {
      progress: 100,
    },
    [bh_sub_steps.confirm_residence]: {
      progress: 100,
    },
    [bh_sub_steps.identity_confirmation]: {
      progress: 100,
    },
    [bh_sub_steps.select_pharmacy]: {
      progress: 100,
    },
    [bh_sub_steps.symptoms]: {
      progress: 100,
    },
    [bh_sub_steps.additional_info]: {
      progress: 100,
    },
  };

  useEffect(() => {
    if (updatingVisit || fetchingIncompleteData) {
      setLoading(true);
    } else if (!fetchingIncompleteData && !updatingVisit) {
      setLoading(false);
    }
  }, [updatingVisit, setLoading, fetchingIncompleteData]);

  function addProgress(payload, screen_to_complete) {
    let result_payload;
    if (
      screen_to_complete &&
      subSteps[screen_to_complete]?.progress &&
      completedProgress <= subSteps[screen_to_complete]?.progress
    ) {
      result_payload = {
        ...payload,
        case: {
          ...payload.case,
          meta: {
            ...incompleteData.meta,
            ...payload.case.meta,
            progress_percentage: incompleteData?.phase
              ? newSubSteps[screen_to_complete]?.progress
              : subSteps[screen_to_complete]?.progress,
          },
        },
      };
    } else {
      result_payload = {
        ...payload,
        case: {
          ...payload.case,
          meta: {
            ...incompleteData.meta,
            ...payload.case.meta,
          },
        },
      };
    }

    if (screen_to_complete) {
      return setBHStepStatus({
        result_payload: result_payload,
        screen_to_complete,
        current_screen_state: subSteps?.[currentScreen],
      });
    } else {
      return result_payload;
    }
  }

  async function updateVisit({
    payload,
    change_current_screen,
    screen_to_complete,
    callBack,
  }) {
    try {
      const result = await callUpdateCase({
        caseId: incompleteData?.id,
        payload: addProgress(payload, screen_to_complete),
      });
      if (result && result.error === false) {
        const incompleteDataResponse = await fetchIncompleteData(
          speciality.behavior_health
        );
        if (incompleteDataResponse) {
          if (screen_to_complete) {
            dispatch(
              behaviorHealthVisitFlowReducers?.[screen_to_complete]({
                status: step_status.completed,
              })
            );
            if (subSteps?.[screen_to_complete]?.progress) {
              dispatch(
                behaviorHealthVisitFlowReducers.completedProgress({
                  value: subSteps[screen_to_complete]?.progress,
                })
              );
            }
          }
          callBack();
          if (change_current_screen) {
            setTimeout(() => {
              dispatch(
                behaviorHealthVisitFlowReducers.currentScreen({
                  value: subSteps?.[currentScreen]?.nextScreen,
                })
              );
            }, 300);
          }
        }
      } else {
        showAlert(
          result?.payload?.error_message ?? "Something went wrong",
          "error"
        );

        if (
          result.payload.error_message ===
          "Your visit was deleted, please start a new one."
        ) {
          history.replace("/home");
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return { updateVisit, updatingVisit };
}

export default useUpdateBehaviorHealthVisit;
