import React, { useEffect, useRef, useState } from "react";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import VisitSubQuestionSlideUpContainer from "components/visitSubQuestionSlideUp/VisitSubQuestionSlideUpContainer";
import { Form, Formik } from "formik";
import Input from "components/inputs/Input";
import { visitConcerns } from "api/actions/VisitActions";
import { useApiQuery } from "hooks/useApiQuery";
import CSSLoader from "components/cssLoader/CSSLoader";
import { useDispatch } from "react-redux";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";
import usePatients from "hooks/visit/usePatients";
import useUpdateBehaviorHealthVisit from "hooks/visit/useUpdateBehaviorHealthVisit";
import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";
import { bh_main_steps, bh_sub_steps } from "helpers/enum";
import { OtherConditionSchema } from "helpers/validationSchema";
import useVisitStepChange from "hooks/useVisitStepChange";
import scrollToBottom from "helpers/scrollToBottom";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { checkAlreadyCompleted } from "helpers/setBHStepStatus";

function SelectConditionForBehaviorHealth() {
  const formRef = useRef();
  const nextAnimationRef = useRef();

  const dispatch = useDispatch();
  const {
    incompleteData,
    subSteps,
    patientsList,
  } = useBehaviorHealthVisitFlow();
  const { getCurrentPatient } = usePatients();
  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    subSteps[bh_sub_steps.select_condition].value;

  const { updateVisit } = useUpdateBehaviorHealthVisit();

  const [selected, setSelected] = useState(null);
  const [behaviorConditionList, setBehaviorConditionList] = useState([]);

  const {
    payload: visitConcernsPayload,
    loading: gettingConcerns,
    query: queryConcerns,
  } = useApiQuery(visitConcerns(2), false);

  useEffect(() => {
    if (currentScreenValueFromStore?.length && behaviorConditionList?.length) {
      if (
        behaviorConditionList?.find(
          (el) =>
            el?.toLowerCase() === currentScreenValueFromStore?.toLowerCase()
        )
      ) {
        setSelected(currentScreenValueFromStore);
      } else {
        setSelected(behaviorConditionList?.[behaviorConditionList?.length - 1]);
        scrollToBottom();
      }
    }
  }, [currentScreenValueFromStore, behaviorConditionList]);

  useEffect(() => {
    if (selected?.length) {
      if (
        selected === behaviorConditionList?.[behaviorConditionList?.length - 1]
      ) {
        scrollToBottom();
      }
      dispatch(
        behaviorHealthVisitFlowReducers.select_condition({
          isValid: true,
        })
      );
    } else {
      dispatch(
        behaviorHealthVisitFlowReducers.select_condition({
          isValid: false,
        })
      );
    }
  }, [dispatch, selected, behaviorConditionList]);

  useEffect(() => {
    queryConcerns();
  }, [queryConcerns]);

  useEffect(() => {
    if (visitConcernsPayload?.visit_converns?.length) {
      setBehaviorConditionList(
        visitConcernsPayload?.visit_converns?.map((el) => el?.title)
      );
    }
  }, [visitConcernsPayload]);

  useEffect(() => {
    if (subSteps?.select_patient?.value === "Me") {
      dispatch(
        behaviorHealthVisitFlowReducers.select_condition({
          prevScreen: bh_sub_steps.select_patient,
        })
      );
    } else if (subSteps?.select_patient?.value === "My Child") {
      dispatch(
        behaviorHealthVisitFlowReducers.select_condition({
          prevScreen: bh_sub_steps.select_child,
        })
      );
    } else if (subSteps?.select_patient?.value === "Someone else") {
      dispatch(
        behaviorHealthVisitFlowReducers.select_condition({
          prevScreen: bh_sub_steps.select_someone_else,
        })
      );
    }
  }, [dispatch, subSteps]);

  function onRightButtonClick(_, nextAnimation) {
    if (
      selected === behaviorConditionList?.[behaviorConditionList?.length - 1]
    ) {
      formRef?.current?.handleSubmit();
      nextAnimationRef.current = nextAnimation;
    } else {
      updateVisit({
        payload: {
          case: {
            id: incompleteData?.id,
            skin_issue: selected,
            behavior_health_steps: {
              main_steps: {
                ...incompleteData?.behavior_health_steps?.main_steps,
                [bh_main_steps.visit_details]: "completed",
                [bh_main_steps.main_select_slot]: checkAlreadyCompleted({
                  screen_name: bh_main_steps.visit_details,
                  behavior_health_steps: incompleteData?.behavior_health_steps,
                  step_placement: "main_steps",
                }),
              },
              sub_steps: {
                ...incompleteData?.behavior_health_steps?.sub_steps,
              },
            },
          },
        },
        change_current_screen: true,
        screen_to_complete: bh_sub_steps.select_condition,
        callBack: () => {
          dispatch(
            behaviorHealthVisitFlowReducers.select_condition({
              value: selected,
            })
          );
          nextAnimation();
        },
      });
    }
  }

  function onFormSubmit(values) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          skin_issue: values?.other_condition,
          behavior_health_steps: incompleteData?.behavior_health_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: bh_sub_steps.select_condition,
      callBack: () => {
        dispatch(
          behaviorHealthVisitFlowReducers.select_condition({
            value: values?.other_condition,
          })
        );
        nextAnimationRef?.current();
      },
    });
  }

  function isLast() {
    return (
      behaviorConditionList?.length &&
      selected &&
      behaviorConditionList?.[behaviorConditionList?.length - 1] === selected
    );
  }

  return (
    <StepScreenContainer align={"start"}>
      <MedicalAssistant
        content={
          <p>
            Thank you, {currentPatient?.first_name}. Now, what is your main
            concern today?
          </p>
        }
      />
      <Spacer height={40} />
      <Card>
        {gettingConcerns ? (
          <div className={"h-40 flex items-center justify-center"}>
            <CSSLoader className={"w-16 h-16"} color={"orange"} />
          </div>
        ) : (
          <RadioButtonCard
            options={behaviorConditionList}
            onSelect={setSelected}
            selected={selected}
          />
        )}
      </Card>
      {isLast() ? (
        <VisitSubQuestionSlideUpContainer>
          <Spacer height={40} />
          <Card>
            <Formik
              innerRef={formRef}
              onSubmit={onFormSubmit}
              initialValues={{
                other_condition:
                  currentScreenValueFromStore &&
                  behaviorConditionList?.find(
                    (el) =>
                      el?.toLowerCase() !==
                      currentScreenValueFromStore?.toLowerCase()
                  )
                    ? currentScreenValueFromStore
                    : "",
              }}
              validationSchema={OtherConditionSchema}
            >
              {() => (
                <Form>
                  <Input
                    id={"other_condition"}
                    name={"other_condition"}
                    label="Type your concern"
                  />
                </Form>
              )}
            </Formik>
          </Card>
        </VisitSubQuestionSlideUpContainer>
      ) : null}
    </StepScreenContainer>
  );
}

export default SelectConditionForBehaviorHealth;
