import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";

import usePatients from "hooks/visit/usePatients";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";

import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Button from "components/buttons/Button";
import CSSLoader from "components/cssLoader/CSSLoader";

import { bh_sub_steps } from "helpers/enum";

import SelectChild from "./SelectChild";
import SelectSomeoneElse from "./SelectSomeoneElse";

const options = ["Me", "My Child", "Someone else"];

const SelectPatient = ({
  incompleteData,
  fetchingPatients,
  fetchingProfileDetails,
  createNewVisit,
  creatingVisit,
  updateVisit,
  updatingVisit,
  setActiveIndex,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getAccountHolder } = usePatients();
  const { patientsList, subSteps } = useBehaviorHealthVisitFlow();
  const currentScreenValueFromStore =
    subSteps[bh_sub_steps.select_patient].value;

  const [selected, setSelected] = useState(null);
  const [selectedScreen, setSelectedScreen] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (fetchingPatients === false && fetchingProfileDetails === false) {
      setShowLoader(false);
    }
  }, [fetchingPatients, fetchingProfileDetails]);

  useEffect(() => {
    if (currentScreenValueFromStore) {
      setSelected(currentScreenValueFromStore);
      setSelectedScreen(currentScreenValueFromStore);
    }
  }, [currentScreenValueFromStore, patientsList]);

  const selectSelf = () => {
    dispatch(
      behaviorHealthVisitFlowReducers.select_patient({
        value: options[0],
      })
    );
    setSelectedScreen(options[0]);
  };

  function onRightButtonClick() {
    if (selected === options?.[0]) {
      if (incompleteData && Object.keys(incompleteData)?.length) {
        updateVisit({
          payload: {
            case: {
              id: incompleteData?.id,
              patient_id: getAccountHolder(patientsList)?.id,
              medications:
                typeof incompleteData?.medications[0] === "string"
                  ? incompleteData?.medications.map((item) => ({
                      name: item,
                    }))
                  : incompleteData?.medications,
            },
          },
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            dispatch(
              behaviorHealthVisitFlowReducers.select_patient({
                value: "Me",
              })
            );
            setActiveIndex(1);
            history.push("/visit/behavior-health/pricing");
          },
        });
      } else {
        createNewVisit({
          patient_id: getAccountHolder(patientsList)?.id,
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            dispatch(
              behaviorHealthVisitFlowReducers.select_patient({
                value: "Me",
              })
            );
            setActiveIndex(1);
            history.push("/visit/behavior-health/pricing");
          },
        });
      }
    } else {
      dispatch(
        behaviorHealthVisitFlowReducers.select_patient({
          value: selected,
        })
      );
      setSelectedScreen(selected);
    }
  }

  return (
    <div className="bg-white rounded-b-4xl p-10 relative overflow-x-hidden">
      {showLoader ? (
        <div className="flex-1 py-10 flex justify-center items-center">
          <CSSLoader className="w-16 h-16" color="orange" />
        </div>
      ) : selectedScreen === options[0] ? (
        <>
          <RadioButtonCard
            loading={creatingVisit}
            options={options}
            onSelect={setSelected}
            selected={selected}
            disabled={["My Child", "Someone else"]}
            infoTexts={["", "Coming Soon", "Coming Soon"]}
          />
          <Button
            disabled={!selected}
            className="py-4 mt-10"
            loading={updatingVisit || creatingVisit}
            label="Next"
            onClick={onRightButtonClick}
          />
        </>
      ) : selectedScreen === options[1] ? (
        <SelectChild
          incompleteData={incompleteData}
          createNewVisit={createNewVisit}
          creatingVisit={creatingVisit}
          updateVisit={updateVisit}
          updatingVisit={updatingVisit}
          setActiveIndex={setActiveIndex}
          goBackToSelf={selectSelf}
        />
      ) : selectedScreen === options[2] ? (
        <SelectSomeoneElse
          incompleteData={incompleteData}
          createNewVisit={createNewVisit}
          creatingVisit={creatingVisit}
          updateVisit={updateVisit}
          updatingVisit={updatingVisit}
          setActiveIndex={setActiveIndex}
          goBackToSelf={selectSelf}
        />
      ) : (
        <>
          <RadioButtonCard
            loading={creatingVisit}
            options={options}
            onSelect={setSelected}
            selected={selected}
            disabled={["My Child", "Someone else"]}
            infoTexts={["", "Coming Soon", "Coming Soon"]}
          />
          <Button
            disabled={!selected}
            className="py-4 mt-10"
            loading={updatingVisit || creatingVisit}
            label="Next"
            onClick={onRightButtonClick}
          />
        </>
      )}
    </div>
  );
};

export default SelectPatient;
