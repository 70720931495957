export const MEDICAL_HISTORY_DATA = {
  Neurological: [
    "Dizziness",
    "Fainting",
    "Headaches / Migraines",
    "Numbness",
    "Seizure",
    "Stroke",
    "Visual Problems",
  ],
  "Ears / Nose / Throat": [
    "Allergies",
    "Ear Trouble",
    "Hearing Loss",
    "Hoarseness",
    "Nose Bleeds",
    "Sinus Trouble",
    "Stuffy Nose",
  ],
  "Pulmonary / Lungs": [
    "Asthma",
    "Chest Pain",
    "Cough",
    "Coughed up Blood",
    "Emphysema / COPD",
    "Night Sweats",
    "Pleurisy",
    "Pneumonia",
    "Shortness of Breath",
    "Tuberculosis",
    "Wheezing",
  ],
  "GI / Stomach": [
    "Abdominal Pain",
    "Bloody or Dark Stool",
    "Bowel Irregularity",
    "Change in Appetite",
    "Colitis",
    "Constipation",
    "Diarrhea",
    "Gallbladder Trouble",
    "Heartburn",
    "Hemorrhoids",
    "Hepatitis",
    "Indigestion",
    "Liver Disease",
    "Nausea",
    "Nervous Stomach",
    "Trouble Swallowing",
    "Ulcers",
    "Vomiting Blood",
  ],
  Skin: [
    "Acne",
    "Change in Moles or Warts",
    "Itching / Rash / Hives",
    "Skin Cancer",
    "Tumor or Swelling",
  ],
  "Heart / Vascular": [
    "Abnormal EKG",
    "Anemia",
    "Angina / Chest Pain",
    "Blood Disorders",
    "Blood Transfusion",
    "Coronary Artery Disease",
    "Enlarged Heart",
    "Frequent Ankle Swelling",
    "Heart Attack (MI)",
    "Heart Murmur / Valve Disorder",
    "Heart Trouble",
    "High Blood Pressure (HTN)",
    "Irregular Heart Beat",
    "Palpitations",
    "Phlebitis / Blood Clots",
    "Rheumatic Fever",
    "Varicose Veins",
  ],
  "Bones / Joints / Muscles": [
    "Arthritis",
    "Back Pain",
    "Breast Pain",
    "Bursitis",
    "Muscle Cramps",
    "Muscle Weakness",
    "Osteoporosis / Osteopenia",
  ],
  Endocrine: [
    "Abnormal Cholesterol",
    "Diabetes",
    "Goiter",
    "Hot Flashes",
    "Hypoglycemia",
    "Sudden Weight Loss / Gain",
    "Thyroid Trouble",
    "Weakness / Fatigue",
  ],
  "Kidney / Urology": [
    "Bladder Infection",
    "Difficulty Urinating",
    "Impotence",
    "Incontinence",
    "Infertility",
    "Kidney Trouble",
    "Prostate Trouble",
    "Sexual Problems",
    "Sexually Transmitted Disease",
  ],
  Psychological: [
    "Depression",
    "Excessive Worry or Anxiety",
    "Mood Swings",
    "Panic Attacks",
    "Severe Tension / Stress",
    "Sleep Problems",
  ],
  Cancer: ["Cancer"],
};
