import React, { useCallback, useEffect, useRef, useState } from "react";
import Images from "assets/images";
import Header from "components/header/Header";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import Spacer from "components/Spacer/Spacer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";
import DoctorCard from "components/doctorCard/DoctorCard";
import Accordion from "components/accordion/Accordion";
import { useApiQuery } from "hooks/useApiQuery";
import {
  checkInsuranceNetworkAction,
  currentUserAction,
  userProfileAction,
} from "api/actions/UserActions";
import {
  bookSlotAction,
  discardVisit,
  getBookingSlotsWithProvidersAction,
  getRevisedInsuranceAction,
  getVisitSymptomsAction,
  visitConcerns,
  getBehaviorHealthQuestionnaireAction,
} from "api/actions/VisitActions";

import useUpdateBehaviorHealthVisit from "hooks/visit/useUpdateBehaviorHealthVisit";
import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";
import { useDispatch } from "react-redux";
import {
  currentSpeciality,
  setUserDetails,
  updateVisitMessagePayload,
} from "reducers/currentUserReducer";
import { speciality } from "helpers/enum";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "hooks/useCurrentUser";
import usePatients from "hooks/visit/usePatients";
import { CURRENT_SOURCE } from "helpers/currentSource";
import useIncompleteData from "hooks/visit/useIncompleteData";
import RevisedPaymentBlock from "../../visitBlock/RevisedPaymentBlock";
import { isMobile } from "react-device-detect";
import useCreateBehaviorHealthVisit from "hooks/visit/useCreateBehaviorHealthVisit";
import useModal from "hooks/useModal";
import Alert from "components/alerts/Alert";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import LoaderComponent from "components/loader/LoaderComponent";
import { isDesktop } from "react-device-detect";
import useTextCompleteVisitToMobile from "hooks/useTextCompleteVisitToMobile";

import SelectPatient from "./phaseTwoSections/SelectPatient";
import SelectTherapy from "./phaseTwoSections/SelectTherapy";
import SelectSlot from "./phaseTwoSections/SelectSlot";
import BasicDetails from "./phaseTwoSections/BasicDetails";
import IdentityConfirmation from "./phaseTwoSections/IdentityConfirmation";
import SelectTherapyReason from "./phaseTwoSections/SelectTherapyReason.js";
import Symptoms from "./phaseTwoSections/Symptoms";
import SocialMedicalHistory from "./phaseTwoSections/SocialMedicalHistory";
import Medications from "./phaseTwoSections/Medications";
import ConsultationHistory from "./phaseTwoSections/ConsultationHistory";
import AdditionalInfo from "./phaseTwoSections/AdditionalInfo";
import PatientQuestionnaire from "./phaseTwoSections/PatientQuestionnaire";
import BHLegalAgreement from "./phaseTwoSections/BHLegalAgreement";
import CodeOfConduct from "./phaseTwoSections/CodeOfConduct";
import PaymentDetails from "./phaseTwoSections/PaymentDetails";

function BehaviorHealthCompleteVisitDetails() {
  const { setShowModal } = useModal();
  const { incompleteData } = useBehaviorHealthVisitFlow();
  const [activeIndex, setActiveIndex] = useState(0);
  const [disabledIndices, setDisabledIndices] = useState([]);
  const [currentPatient, setCurrentPatient] = useState({});
  const [shareMeds, setShareMeds] = useState(null);
  const [selectedMedications, setSelectedMedications] = useState([]);
  const [imageData, setImageData] = useState(null);

  // TODO: Update default values
  const [firstAnswer, setFirstAnswer] = useState("");
  const [secondAnswer, setSecondAnswer] = useState("");
  const [thirdAnswer, setThirdAnswer] = useState("");
  const [thirdYesAnswer, setThirdYesAnswer] = useState([]);
  const [fourthAnswer, setFourthAnswer] = useState("");
  const [fourthYesAnswer, setFourthYesAnswer] = useState([]);

  const { fetchIncompleteData } = useIncompleteData();

  const firstTime = useRef(true);
  const trialref = useRef(null);
  const idRef = useRef(true);
  const imagePickerRef = useRef(null);
  const {
    payload: profileDetails,
    query: getProfileDetails,
    loading: gettingProfileDetails,
  } = useApiQuery(userProfileAction, false);
  const {
    query: refetchUserDetails,
    payload: refetchedUserDetails,
    loading: refetchingUserDetails,
  } = useApiQuery(currentUserAction, false);

  const {
    payload: bhVisitConcernsPayload,
    loading: gettingBHConcerns,
    query: bhQueryConcerns,
  } = useApiQuery(visitConcerns(5), false);

  const { alertPatient } = useTextCompleteVisitToMobile();

  const {
    payload: revisedInsuranceDetails,
    query: getRevisedInsuranceDetails,
  } = useApiQuery(getRevisedInsuranceAction(incompleteData?.id), false);
  const { mutate: callDiscardVisit, loading: discardingVisit } = useApiMutation(
    discardVisit
  );
  const {
    query: checkInsuranceInNetwork,
    loading: checkingInsuranceInNetwork,
    payload: insuranceInNetwork,
  } = useApiQuery(
    checkInsuranceNetworkAction(
      speciality.behavior_health,
      incompleteData?.patient_id
    ),
    false
  );

  const {
    loading: gettingPatientQuestionnaire,
    payload: patientQuestionnaire,
    query: getPatientQuestionnaire,
  } = useApiQuery(getBehaviorHealthQuestionnaireAction);

  const {
    query: getBookingSlots,
    loading: gettingBookingSlots,
    payload: slotsResponse,
  } = useApiQuery(
    getBookingSlotsWithProvidersAction({
      caseId: incompleteData?.id,
    }),
    false
  );

  const { createNewVisit, creatingVisit } = useCreateBehaviorHealthVisit();
  const { updateVisit, updatingVisit } = useUpdateBehaviorHealthVisit();
  const dispatch = useDispatch();
  const history = useHistory();
  const { showAlert } = useToastify();
  const { userDetails } = useCurrentUser();
  const {
    patientsList,
    getPatients,
    getCurrentPatient,
    fetchingPatients,
  } = usePatients();
  const { loading: bookingSlot, mutate: callBookSlot } = useApiMutation(
    bookSlotAction
  );

  const scrollToActiveSection = useCallback(() => {
    if (activeIndex >= 0 && incompleteData) {
      trialref?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndex, incompleteData]);

  const {
    payload: bhSymptomsFromApi,
    loading: gettingBHSymptoms,
    query: bhSymptomsQuery,
  } = useApiQuery(
    getVisitSymptomsAction({
      speciality: 5,
      gender: getCurrentPatient(incompleteData?.patient_id, patientsList)
        ?.gender,
    }),
    false
  );

  useEffect(() => {
    const init = async () => {
      await fetchIncompleteData(speciality.behavior_health);
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scrollToActiveSection();
    }, 500);
  }, [activeIndex, scrollToActiveSection]);

  useEffect(() => {
    if (incompleteData?.patient_id) {
      getBookingSlots();
      checkInsuranceInNetwork();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incompleteData?.patient_id]);

  useEffect(() => {
    if (currentPatient?.gender) {
      bhSymptomsQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPatient?.gender]);

  useEffect(() => {
    if (profileDetails) {
      getPatients();
    }
  }, [profileDetails, getPatients]);

  useEffect(() => {
    setCurrentPatient({
      ...getCurrentPatient(incompleteData?.patient_id, patientsList),
    });
  }, [getCurrentPatient, incompleteData, patientsList]);

  useEffect(() => {
    if (firstTime.current && incompleteData) {
      let index = Object.keys(incompleteData?.bh_second_phase_steps).length;
      if (index === 12 && incompleteData?.accepted_legal_step) {
        index = 13;
      }
      if (index === 13 && incompleteData?.accepted_tnc) {
        index = 14;
      }
      if (index === 14 && incompleteData?.meta?.submitted_payment_info) {
        index = null;
      }
      setActiveIndex(index);
    }
  }, [incompleteData, currentPatient]);

  useEffect(() => {
    if (
      firstTime.current &&
      incompleteData?.payment_method === "insurance" &&
      incompleteData?.speciality === "behavior_health" &&
      patientsList
    ) {
      firstTime.current = false;
      getRevisedInsuranceDetails();
    }
  }, [incompleteData, getRevisedInsuranceDetails, patientsList]);

  useEffect(() => {
    if (incompleteData) {
      if (incompleteData?.medications.length === 0) {
        setShareMeds("None");
        setSelectedMedications([]);
      } else {
        if (incompleteData?.medications.includes("None")) {
          setShareMeds("None");
          setSelectedMedications([]);
        } else {
          setShareMeds(null);
          setSelectedMedications(incompleteData?.medications);
        }
      }
    }
  }, [incompleteData]);

  useEffect(() => {
    if (incompleteData?.bh_second_phase_steps?.medical_history) {
      incompleteData?.behavior_health_medical_history?.forEach((question) => {
        switch (question?.health_profile_section) {
          case "exercise":
            setFirstAnswer(
              question?.answer[0] + question?.answer?.slice(1).toLowerCase()
            );
            break;
          case "alcohol":
            setSecondAnswer(question?.answer);
            break;
          case "tobacco":
            setThirdAnswer(
              question?.answer[0] + question?.answer?.slice(1).toLowerCase()
            );
            setThirdYesAnswer(question?.form_of_tobacco ?? []);
            break;
          case "medical_history":
            setFourthAnswer(question?.answer === "NO" ? "None" : "Yes");

            if (question?.selected_options?.length > 0) {
              let selectedOptions = [];
              question?.selected_options.forEach((option) => {
                option.diseases.forEach((disease) => {
                  selectedOptions.push({
                    category: option.category,
                    disease: disease,
                  });
                });
              });

              setFourthYesAnswer(selectedOptions);
            } else {
              setFourthYesAnswer([]);
            }
            break;
          default:
            break;
        }
      });
    }
  }, [
    incompleteData?.behavior_health_medical_history,
    incompleteData?.bh_second_phase_steps,
  ]);

  useEffect(() => {
    const arr = [];
    if (incompleteData?.bh_second_phase_steps?.select_patient !== "completed") {
      arr.push(1);
    }
    if (incompleteData?.bh_second_phase_steps?.select_therapy !== "completed") {
      arr.push(2);
    }
    if (incompleteData?.bh_second_phase_steps?.select_slot !== "completed") {
      arr.push(3);
    }
    if (
      incompleteData?.bh_second_phase_steps?.confirm_residence !== "completed"
    ) {
      arr.push(4);
    }
    if (
      incompleteData?.bh_second_phase_steps?.identity_confirmation !==
      "completed"
    ) {
      arr.push(5);
    }
    if (
      incompleteData?.bh_second_phase_steps?.select_therapy_reasons !==
      "completed"
    ) {
      arr.push(6);
    }
    if (incompleteData?.bh_second_phase_steps?.symptoms !== "completed") {
      arr.push(7);
    }
    if (
      incompleteData?.bh_second_phase_steps?.medical_history !== "completed"
    ) {
      arr.push(8);
    }

    if (incompleteData?.bh_second_phase_steps?.medications !== "completed") {
      arr.push(9);
    }

    if (
      incompleteData?.bh_second_phase_steps?.consultation_history !==
      "completed"
    ) {
      arr.push(10);
    }

    if (
      incompleteData?.bh_second_phase_steps?.additional_info !== "completed"
    ) {
      arr.push(11);
    }

    if (
      incompleteData?.bh_second_phase_steps?.patient_health_questionnaire !==
      "completed"
    ) {
      arr.push(12);
    }

    if (!incompleteData?.accepted_legal_step) {
      arr.push(13);
    }

    if (!incompleteData?.accepted_tnc) {
      arr.push(14);
    }
    setDisabledIndices(arr);
  }, [incompleteData, currentPatient]);

  const submitVisit = async () => {
    try {
      const payload = {
        date: incompleteData?.meta?.booking_info?.selectedDate,
        slot_id: incompleteData?.meta?.booking_info?.selectedProvider?.slot_id,
        practice_user_id:
          incompleteData?.meta?.booking_info?.selectedProvider?.id,
      };
      const result = await callBookSlot({
        caseId: incompleteData?.id,
        payload,
      });
      if (result && result?.error === false) {
        updateVisit({
          payload: {
            case: {
              medications: incompleteData?.medications,
              phase: "second_phase",
              coupon_code: incompleteData?.meta?.coupon_code,
              status: "complete_awaiting_diagnosis",
              submitting_source: CURRENT_SOURCE,
            },
          },
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            dispatch(behaviorHealthVisitFlowReducers.reset());
            dispatch(currentSpeciality({ currentSpeciality: null }));
            dispatch(
              setUserDetails({
                userDetails: { ...userDetails, incomplete_visit_id: null },
              })
            );
            history.replace({
              pathname: "/visit/submitted",
              state: {
                speciality: speciality.behavior_health,
                currentPatient,
                bookingInfo: incompleteData?.meta?.booking_info,
              },
            });
          },
        });
      } else {
        if (result.error || result.payload.error || result.payload.error_msg) {
          showAlert(
            result.payload.error ||
              result.payload.error_msg ||
              result.payload.message ||
              "Booking failed.",
            "error"
          );
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    getProfileDetails();
  }, [getProfileDetails]);

  useEffect(() => {
    bhQueryConcerns();
  }, [bhQueryConcerns]);

  useEffect(() => {
    getPatientQuestionnaire();
  }, [getPatientQuestionnaire]);

  const openChat = () => {
    if (incompleteData?.messages?.length === 0) {
      return;
    } else {
      dispatch(
        updateVisitMessagePayload({
          follow_up: incompleteData?.follow_up,
          unread_provider_messages: incompleteData?.unread_provider_messages,
          messages: incompleteData?.messages,
        })
      );

      history.push(`/chat/${incompleteData?.id}`, {
        speciality: incompleteData?.speciality,
      });
    }
  };

  const alertDesktopPatient = () => {
    if (isDesktop) {
      alertPatient({
        message:
          "It looks like you're on a device that does not have camera access. You will be asked to upload your photo ID now. Do you want to use your smartphone to take photo? We'll text you a link to complete your visit on your smartphone.",
        heading: "Use your smartphone to take photos?",
        openImagePicker: () => imagePickerRef.current.click(),
      });
    } else {
      imagePickerRef.current.click();
    }
  };

  const AccordionHeader = ({
    headerTitle,
    isActive,
    stepCompleted,
    enabled,
  }) => (
    <div
      className={`flex justify-between items-start py-10 px-10 ${
        isActive ? "rounded-t-4xl" : "rounded-4xl"
      } ${enabled ? "bg-white" : "bg-lightGrey cursor-not-allowed"}`}
    >
      <h3 className="w-3/4 text-3xl text-left font-medium">{headerTitle}</h3>
      <div className="self-center flex space-x-4">
        {stepCompleted ? (
          <img
            src={Images.stepCompleted}
            alt="step_status"
            className="w-10 h-10"
          />
        ) : (
          <div />
        )}
        <img
          src={Images.dropdown}
          alt="chev_down"
          className={`transition duration-500 ease-in-out w-10 h-10 transform ${
            isActive ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
    </div>
  );

  const AccordionContent = ({ children }) => <div>{children}</div>;

  const accordionData = [
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Select Patient"
          isActive={isActive}
          stepCompleted={
            incompleteData?.bh_second_phase_steps?.select_patient ===
            "completed"
          }
          enabled={true}
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 0 ? <div ref={trialref} /> : <div />}
          <SelectPatient
            incompleteData={incompleteData}
            fetchingPatients={fetchingPatients}
            fetchingProfileDetails={gettingProfileDetails}
            createNewVisit={createNewVisit}
            creatingVisit={creatingVisit}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Select Therapy"
          isActive={isActive}
          stepCompleted={
            incompleteData?.bh_second_phase_steps?.select_therapy ===
            "completed"
          }
          enabled={
            incompleteData?.bh_second_phase_steps?.select_patient ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 1 ? <div ref={trialref} /> : <div />}
          <SelectTherapy
            incompleteData={incompleteData}
            basicDetails={currentPatient}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
            visitConcernsPayload={bhVisitConcernsPayload}
            gettingConcerns={gettingBHConcerns}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Select Date & Time Slot"
          isActive={isActive}
          stepCompleted={
            incompleteData?.bh_second_phase_steps?.select_slot === "completed"
          }
          enabled={
            incompleteData?.bh_second_phase_steps?.select_therapy ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 2 ? <div ref={trialref} /> : <div />}
          {history?.location?.state?.timeslotId ? (
            <SelectSlot
              incompleteData={incompleteData}
              updateVisit={updateVisit}
              updatingVisit={updatingVisit}
              setActiveIndex={setActiveIndex}
              gettingBookingSlots={gettingBookingSlots}
              slotsResponse={slotsResponse}
              preselectedSlotId={history?.location?.state?.timeslotId}
            />
          ) : (
            <SelectSlot
              incompleteData={incompleteData}
              updateVisit={updateVisit}
              updatingVisit={updatingVisit}
              setActiveIndex={setActiveIndex}
              gettingBookingSlots={gettingBookingSlots}
              slotsResponse={slotsResponse}
            />
          )}
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Enter demographics"
          isActive={isActive}
          stepCompleted={
            incompleteData?.bh_second_phase_steps.confirm_residence ===
            "completed"
          }
          enabled={
            incompleteData?.bh_second_phase_steps?.select_slot === "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 3 ? <div ref={trialref} /> : <div />}
          <BasicDetails
            incompleteData={incompleteData}
            basicDetails={currentPatient}
            refetch={getProfileDetails}
            refetchUserDetails={refetchUserDetails}
            refetchedUserDetails={refetchedUserDetails}
            refetchingUserDetails={refetchingUserDetails}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Confirm your identity"
          isActive={isActive}
          stepCompleted={
            incompleteData?.bh_second_phase_steps.identity_confirmation ===
            "completed"
          }
          enabled={
            incompleteData?.bh_second_phase_steps?.confirm_residence ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 4 ? <div ref={trialref} /> : <div />}
          <IdentityConfirmation
            alertDesktopPatient={alertDesktopPatient}
            firstTimeRef={idRef}
            imagePickerRef={imagePickerRef}
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
            imageData={imageData}
            setImageData={setImageData}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="What led you to therapy today?"
          isActive={isActive}
          stepCompleted={
            incompleteData?.bh_second_phase_steps.select_therapy_reasons ===
            "completed"
          }
          enabled={
            incompleteData?.bh_second_phase_steps.identity_confirmation ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 5 ? <div ref={trialref} /> : <div />}
          <SelectTherapyReason
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
            symptomsFromApi={bhSymptomsFromApi?.find(
              (el) => el?.related_to === "therapy_reasons"
            )}
            gettingSymptoms={gettingBHSymptoms}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="List all symptoms from the past 2 weeks"
          isActive={isActive}
          stepCompleted={
            incompleteData?.bh_second_phase_steps.symptoms === "completed"
          }
          enabled={
            incompleteData?.bh_second_phase_steps?.select_therapy_reasons ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 6 ? <div ref={trialref} /> : <div />}
          <Symptoms
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
            symptomsFromApi={bhSymptomsFromApi?.find(
              (el) => el?.related_to === "therapy_symptoms"
            )}
            gettingSymptoms={gettingBHSymptoms}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Social/Medical History"
          isActive={isActive}
          stepCompleted={
            incompleteData?.bh_second_phase_steps.medical_history ===
            "completed"
          }
          enabled={
            incompleteData?.bh_second_phase_steps.symptoms === "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 7 ? <div ref={trialref} /> : <div />}
          <SocialMedicalHistory
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
            firstAnswer={firstAnswer}
            setFirstAnswer={setFirstAnswer}
            secondAnswer={secondAnswer}
            setSecondAnswer={setSecondAnswer}
            thirdAnswer={thirdAnswer}
            setThirdAnswer={setThirdAnswer}
            thirdYesAnswer={thirdYesAnswer}
            setThirdYesAnswer={setThirdYesAnswer}
            fourthAnswer={fourthAnswer}
            setFourthAnswer={setFourthAnswer}
            fourthYesAnswer={fourthYesAnswer}
            setFourthYesAnswer={setFourthYesAnswer}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="List any medications you are currently taking"
          isActive={isActive}
          stepCompleted={
            incompleteData?.bh_second_phase_steps.medications === "completed"
          }
          enabled={
            incompleteData?.bh_second_phase_steps.medical_history ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 8 ? <div ref={trialref} /> : <div />}
          <Medications
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
            selected={shareMeds}
            setSelected={setShareMeds}
            selectedMedications={selectedMedications}
            setSelectedMedications={setSelectedMedications}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Behavioral Health History"
          isActive={isActive}
          stepCompleted={
            incompleteData?.bh_second_phase_steps.consultation_history ===
            "completed"
          }
          enabled={
            incompleteData?.bh_second_phase_steps.medications === "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 9 ? <div ref={trialref} /> : <div />}
          <ConsultationHistory
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Record/Write additional information"
          isActive={isActive}
          stepCompleted={
            incompleteData?.bh_second_phase_steps.additional_info ===
            "completed"
          }
          enabled={
            incompleteData?.bh_second_phase_steps.consultation_history ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 10 ? <div ref={trialref} /> : <div />}
          <AdditionalInfo
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Patient Health Questionnaire"
          isActive={isActive}
          stepCompleted={
            incompleteData?.bh_second_phase_steps?.patient_health_questionnaire
          }
          enabled={
            incompleteData?.bh_second_phase_steps?.additional_info ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 11 ? <div ref={trialref} /> : <div />}
          <PatientQuestionnaire
            incompleteData={incompleteData}
            gettingPatientQuestionnaire={gettingPatientQuestionnaire}
            patientQuestionnaire={patientQuestionnaire}
            setActiveIndex={setActiveIndex}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Please acknowledge that you have read and accept our terms of agreement."
          isActive={isActive}
          stepCompleted={incompleteData?.accepted_legal_step}
          enabled={
            incompleteData?.bh_second_phase_steps
              ?.patient_health_questionnaire === "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 12 ? <div ref={trialref} /> : <div />}
          <BHLegalAgreement
            incompleteData={incompleteData}
            setActiveIndex={setActiveIndex}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Please acknowledge that you have read and accept our code of conduct."
          isActive={isActive}
          stepCompleted={incompleteData?.accepted_tnc}
          enabled={incompleteData?.accepted_legal_step}
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 13 ? <div ref={trialref} /> : <div />}
          <CodeOfConduct
            enabled={incompleteData?.accepted_legal_step}
            incompleteData={incompleteData}
            termsAccepted={incompleteData?.accepted_tnc}
            setActiveIndex={setActiveIndex}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Enter your payment details"
          isActive={isActive}
          stepCompleted={incompleteData?.meta?.submitted_payment_info}
          enabled={incompleteData?.accepted_tnc}
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 14 ? <div ref={trialref} /> : <div />}
          {incompleteData?.accepted_tnc ? (
            <PaymentDetails
              incompleteData={incompleteData}
              updateVisit={updateVisit}
              updatingVisit={updatingVisit}
              setActiveIndex={setActiveIndex}
              checkingInsuranceInNetwork={checkingInsuranceInNetwork}
              insuranceInNetwork={insuranceInNetwork}
            />
          ) : (
            <div />
          )}
        </AccordionContent>
      ),
    },
  ];

  const confirmDeleteVisit = useCallback(async () => {
    try {
      const response = await callDiscardVisit(incompleteData?.id);
      if (response?.error === false) {
        dispatch(behaviorHealthVisitFlowReducers.reset());
        dispatch(
          setUserDetails({
            userDetails: { ...userDetails, incomplete_visit_id: null },
          })
        );
        history.goBack();
      }
    } catch (error) {}
  }, [callDiscardVisit, dispatch, history, incompleteData?.id, userDetails]);

  const deleteVisit = () => {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <Alert
          heading="Delete visit"
          message={"Are you sure you want to delete your visit?"}
          primaryButtonText="Confirm"
          onSecondaryButtonClick={onClose}
          onPrimaryButtonClick={() => {
            onClose();
            confirmDeleteVisit();
          }}
        />
      ),
      data: { preventRender: true },
    });
  };

  if (discardingVisit) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
        }}
      />
    );
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <Header
        title="Behavioral Health"
        rightActionView={
          incompleteData && Object.keys(incompleteData)?.length ? (
            <p
              onClick={deleteVisit}
              className="text-red text-xl font-medium bg-red bg-opacity-10 px-6 py-4 rounded-md cursor-pointer"
            >
              Delete
            </p>
          ) : null
        }
      />
      <div className="wrapper h-eliminateHeaderWithFooter overflow-y-auto">
        <div className="p-12 min-h-full bg-blue">
          <MedicalAssistant
            content={
              <p>
                Hi
                {incompleteData?.patient_first_name
                  ? ` ${incompleteData?.patient_first_name}`
                  : userDetails?.first_name
                  ? ` ${userDetails?.first_name}`
                  : null}
                , let's get started!
                <br />
                <br />
                Please complete the following steps so that our therapist will
                have all the required information before your visit.
              </p>
            }
          />
          <Spacer height={20} />
          {revisedInsuranceDetails?.revised_insurance_line_items
            ?.price_change_type === "revised" &&
          !revisedInsuranceDetails?.revised_insurance_line_items
            ?.price_change_confirmed_at ? (
            <RevisedPaymentBlock
              visitDetails={incompleteData}
              practiceInsurance={revisedInsuranceDetails?.practice_insurance}
              revisedInsuranceDetails={
                revisedInsuranceDetails?.revised_insurance_line_items
              }
            />
          ) : (
            <div />
          )}
          <Spacer height={20} />
          <h3 className="font-black text-2xl pb-4 tracking-wide">
            YOUR VISIT WITH
          </h3>
          {incompleteData?.provider_id ? (
            <div className="rounded-4xl p-5 border-1/4 border-borderColor bg-white">
              <DoctorCard
                imageUrl={incompleteData?.provider_photo_url}
                name={incompleteData?.provider_name}
                speciality={incompleteData?.provider_speciality}
                isActive={true}
                license={incompleteData?.license}
              />
            </div>
          ) : (
            <div />
          )}
          {incompleteData?.messages.length > 0 ? (
            <>
              <Spacer height={20} />
              <div
                onClick={openChat}
                className="cursor-pointer flex items-center p-6 rounded-4xl bg-white border border-borderColor"
              >
                <img
                  className="h-24 w-24"
                  src={Images.messageIcon}
                  alt="start_visit"
                />
                <div className="ml-6">
                  <h2 className="font-bold text-3xl">Messages</h2>
                  <p className="font-light text-2xl leading-8 mt-2 text-cloudyBlue">
                    {incompleteData?.unread_provider_messages > 0
                      ? `${
                          incompleteData?.unread_provider_messages
                        } unread message${
                          incompleteData?.unread_provider_messages === 1
                            ? ""
                            : "s"
                        }`
                      : "No new messages"}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div />
          )}
          <Spacer height={20} />
          <Accordion
            activeIndexFromData={activeIndex}
            accordionContainerClassName="mb-5 rounded-4xl"
            containerClassName="border-none"
            data={accordionData}
            disabledIndicesFromData={disabledIndices}
            enableTransition={true}
          />
        </div>
      </div>
      <BottomFixedButton
        disabled={
          !incompleteData?.meta?.submitted_payment_info ||
          !incompleteData?.accepted_tnc
        }
        loading={
          (incompleteData?.meta?.submitted_payment_info &&
            incompleteData?.accepted_tnc &&
            updatingVisit) ||
          bookingSlot
        }
        type="submit"
        label="Submit Visit"
        onClick={submitVisit}
      />
      {isMobile ? (
        <div
          className="fixed right-8 bottom-40 cursor-pointer border-2 rounded-full w-24 h-24 bg-orange border-orange flex items-center justify-center"
          onClick={scrollToActiveSection}
        >
          <img
            className="h-10 w-10 filter brightness-0 invert"
            src={Images.checkedGreen}
            alt="scroll to active section"
          />
        </div>
      ) : (
        <div
          onClick={scrollToActiveSection}
          className="fixed mr-10 right-1/4 bottom-40 w-24 h-24 flex items-center justify-center rounded-full bg-orange cursor-pointer shadow-d-2xl"
        >
          <img
            className="w-1/3 filter brightness-0 invert"
            src={Images.checkedGreen}
            alt="scroll to active section"
          />
        </div>
      )}
    </div>
  );
}

export default BehaviorHealthCompleteVisitDetails;
