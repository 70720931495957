import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useToastify from "hooks/useToastify";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";

import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";

import { bh_sub_steps } from "helpers/enum";

import CSSLoader from "components/cssLoader/CSSLoader";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Button from "components/buttons/Button";

const SelectTherapy = ({
  incompleteData,
  basicDetails,
  updateVisit,
  updatingVisit,
  setActiveIndex,
  visitConcernsPayload,
  gettingConcerns,
}) => {
  const { showAlert } = useToastify();

  const dispatch = useDispatch();
  const { subSteps } = useBehaviorHealthVisitFlow();
  const currentScreenValueFromStore =
    subSteps[bh_sub_steps.select_therapy].value;

  const [selected, setSelected] = useState(null);
  const [behaviorConditionList, setBehaviorConditionList] = useState([]);
  const [disabledRadios, setDisabledRadios] = useState([]);
  const [disabledInfo, setDisabledInfo] = useState([]);

  useEffect(() => {
    if (currentScreenValueFromStore?.length && behaviorConditionList?.length) {
      if (
        behaviorConditionList?.find(
          (el) =>
            el?.toLowerCase() === currentScreenValueFromStore?.toLowerCase()
        )
      ) {
        setSelected(currentScreenValueFromStore);
      } else {
        setSelected(behaviorConditionList?.[behaviorConditionList?.length - 1]);
      }
    }
  }, [currentScreenValueFromStore, behaviorConditionList]);

  useEffect(() => {
    if (visitConcernsPayload?.visit_converns?.length) {
      setBehaviorConditionList(
        visitConcernsPayload?.visit_converns?.map((el) => el?.title)
      );
    }
  }, [visitConcernsPayload]);

  useEffect(() => {
    if (basicDetails?.relationship_with_account === "pappa") {
      setDisabledRadios(["Individual (18+)", "Couples"]);
      setDisabledInfo(["", "Coming Soon", "Coming Soon"]);
      setSelected("Teenager (13 - 17)");
    } else {
      setDisabledRadios(["Teenager (13 - 17)", "Couples"]);
      setDisabledInfo(["", "Coming Soon", "Coming Soon"]);
      setSelected("Individual (18+)");
    }
  }, [basicDetails]);

  useEffect(() => {
    if (disabledRadios?.includes(selected)) {
      setSelected(null);
    }
  }, [disabledRadios, selected]);

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          therapy_for: selected,
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications,
          bh_second_phase_steps: {
            ...incompleteData?.bh_second_phase_steps,
            [bh_sub_steps.select_therapy]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        dispatch(
          behaviorHealthVisitFlowReducers.select_therapy({
            value: selected,
          })
        );
        showAlert("Progress Saved Successfully", "success");
        setActiveIndex(2);
      },
    });
  }

  return (
    <div className="bg-white shadow-card rounded-b-4xl p-10 relative overflow-x-hidden">
      {gettingConcerns ? (
        <div className={"h-40 flex items-center justify-center"}>
          <CSSLoader className={"w-16 h-16"} color={"orange"} />
        </div>
      ) : (
        <RadioButtonCard
          options={behaviorConditionList}
          onSelect={setSelected}
          selected={selected}
          disabled={disabledRadios}
          infoTexts={disabledInfo}
        />
      )}
      <Button
        disabled={!selected}
        className="mt-10 py-4"
        loading={updatingVisit}
        label="Next"
        onClick={onRightButtonClick}
      />
    </div>
  );
};

export default SelectTherapy;
