import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";

import useToastify from "hooks/useToastify";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";
import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";

import { bh_sub_steps } from "helpers/enum";
import { OtherConditionSchema } from "helpers/validationSchema";

import Input from "components/inputs/Input";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import CSSLoader from "components/cssLoader/CSSLoader";
import VisitSubQuestionSlideUpContainer from "components/visitSubQuestionSlideUp/VisitSubQuestionSlideUpContainer";
import Button from "components/buttons/Button";
import CheckBoxCard from "components/checkBoxCard/CheckBoxCard";

const SelectTherapyReason = ({
  incompleteData,
  updateVisit,
  updatingVisit,
  setActiveIndex,
  symptomsFromApi,
  gettingSymptoms,
}) => {
  const formRef = useRef();
  const { showAlert } = useToastify();

  const dispatch = useDispatch();
  const { subSteps } = useBehaviorHealthVisitFlow();
  const currentScreenValueFromStore =
    subSteps[bh_sub_steps.select_therapy_reasons].value;

  const [selected, setSelected] = useState([]);
  const [otherValue, setOtherValue] = useState("");
  const [conditionList, setConditionList] = useState([]);

  useEffect(() => {
    if (currentScreenValueFromStore?.length && conditionList?.length) {
      let formedConcerns = [];
      const concernsResult = currentScreenValueFromStore.filter((el) => {
        return conditionList?.find((elem) => {
          return el === elem?.label;
        });
      });
      const otherResult = currentScreenValueFromStore.find((el) => {
        return !conditionList?.find((elem) => {
          return el === elem?.label;
        });
      });

      formedConcerns = concernsResult;

      if (otherResult) {
        formedConcerns.push("Other");
        setOtherValue(otherResult);
      }
      setSelected(formedConcerns);
    }
  }, [currentScreenValueFromStore, conditionList]);

  useEffect(() => {
    if (symptomsFromApi && symptomsFromApi?.symptom_opts?.length) {
      setConditionList([
        ...symptomsFromApi?.symptom_opts?.map((el) => ({
          label: el,
          value: el,
        })),
        {
          label: "Other",
          value: "Other",
        },
      ]);
    }
  }, [symptomsFromApi]);

  function onRightButtonClick(_, nextAnimation) {
    if (selected?.includes("Other")) {
      formRef?.current?.handleSubmit();
    } else {
      updateVisit({
        payload: {
          case: {
            id: incompleteData?.id,
            medications:
              typeof incompleteData?.medications[0] === "string"
                ? incompleteData?.medications.map((item) => ({
                    name: item,
                  }))
                : incompleteData?.medications,
            therapy_reasons: selected,
            bh_second_phase_steps: {
              ...incompleteData?.bh_second_phase_steps,
              [bh_sub_steps.select_therapy_reasons]: "completed",
            },
          },
        },
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            behaviorHealthVisitFlowReducers.select_therapy_reasons({
              value: selected,
            })
          );
          showAlert("Progress Saved Successfully", "success");
          setActiveIndex(6);
        },
      });
    }
  }

  function onFormSubmit(values) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications,
          therapy_reasons: [
            ...selected?.filter((el) => el !== "Other"),
            values?.other_condition,
          ],
          bh_second_phase_steps: {
            ...incompleteData?.bh_second_phase_steps,
            [bh_sub_steps.select_therapy_reasons]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        dispatch(
          behaviorHealthVisitFlowReducers.select_therapy_reasons({
            value: [
              ...selected?.filter((el) => el !== "Other"),
              values?.other_condition,
            ],
          })
        );
        showAlert("Progress Saved Successfully", "success");
        setActiveIndex(6);
      },
    });
  }

  function handleOnSelect(el) {
    let final = [...selected];
    if (final?.includes(el)) {
      setSelected(final.filter((elem) => elem !== el));
    } else {
      setSelected([...final, el]);
    }
  }

  return (
    <div className="bg-white shadow-card rounded-b-4xl p-10 relative overflow-x-hidden">
      {gettingSymptoms ? (
        <div className={"h-40 flex items-center justify-center"}>
          <CSSLoader className={"w-16 h-16"} color={"orange"} />
        </div>
      ) : (
        <CheckBoxCard
          options={conditionList?.map((el) => el?.label)}
          selected={selected}
          onSelect={handleOnSelect}
        />
      )}
      {selected?.includes("Other") ? (
        <VisitSubQuestionSlideUpContainer>
          <Spacer height={40} />
          <Card>
            <Formik
              onSubmit={onFormSubmit}
              innerRef={formRef}
              validationSchema={OtherConditionSchema}
              initialValues={{
                other_condition: otherValue ?? "",
              }}
            >
              {() => (
                <Form>
                  <Input
                    id={"other_condition"}
                    name={"other_condition"}
                    label="Type your concern(s), separated by comma"
                  />
                </Form>
              )}
            </Formik>
          </Card>
        </VisitSubQuestionSlideUpContainer>
      ) : null}
      <Button
        disabled={selected.length === 0}
        className="mt-10 py-4"
        loading={updatingVisit}
        label="Next"
        onClick={onRightButtonClick}
      />
    </div>
  );
};

export default SelectTherapyReason;
