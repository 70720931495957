import React, { useCallback, useEffect, useRef, useState } from "react";
import Images from "assets/images";
import Header from "components/header/Header";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import Spacer from "components/Spacer/Spacer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { usePrimaryCareVisitFlow } from "hooks/useVisitFlow";
import DoctorCard from "components/doctorCard/DoctorCard";
import Accordion from "components/accordion/Accordion";
import { useApiQuery } from "hooks/useApiQuery";
import {
  checkInsuranceNetworkAction,
  userProfileAction,
} from "api/actions/UserActions";
import {
  bookSlotAction,
  discardVisit,
  getBookingSlotsWithProvidersAction,
  getRevisedInsuranceAction,
} from "api/actions/VisitActions";

import useUpdatePrimaryCareVisit from "hooks/visit/useUpdatePrimaryCareVisit";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";
import { useDispatch } from "react-redux";
import {
  currentSpeciality,
  setUserDetails,
  updateVisitMessagePayload,
} from "reducers/currentUserReducer";
import { primary_care_sub_steps, speciality } from "helpers/enum";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "hooks/useCurrentUser";
import usePatients from "hooks/visit/usePatients";
import { CURRENT_SOURCE } from "helpers/currentSource";
import useIncompleteData from "hooks/visit/useIncompleteData";
import RevisedPaymentBlock from "../../visitBlock/RevisedPaymentBlock";
import { isMobile } from "react-device-detect";
import useCreatePrimaryCareVisit from "hooks/visit/useCreatePrimaryCareVisit";
import useModal from "hooks/useModal";
import Alert from "components/alerts/Alert";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import LoaderComponent from "components/loader/LoaderComponent";
import { isDesktop } from "react-device-detect";
import useTextCompleteVisitToMobile from "hooks/useTextCompleteVisitToMobile";

import SelectPatient from "./phaseTwoSections/SelectPatient";
import SelectCondition from "./phaseTwoSections/SelectCondition";
import SelectSlot from "./phaseTwoSections/SelectSlot";
import BasicDetails from "./phaseTwoSections/BasicDetails";
import IdentityConfirmation from "./phaseTwoSections/IdentityConfirmation";
import SelectPharmacy from "./phaseTwoSections/SelectPharmacy";
import Symptoms from "./phaseTwoSections/Symptoms";
import Allergies from "./phaseTwoSections/Allergies";
import Medications from "./phaseTwoSections/Medications";
import FemaleMedicalHistory from "./phaseTwoSections/FemaleMedicalHistory";
import AdditionalInfo from "./phaseTwoSections/AdditionalInfo";
import CodeOfConduct from "./phaseTwoSections/CodeOfConduct";
import PaymentDetails from "./phaseTwoSections/PaymentDetails";

// Upload Image Failure UI
import { IoAlertCircleOutline } from "react-icons/io5";
import { startCase } from "lodash";
import { useParameterizedQuery } from "react-fetching-library";

import { trackEvent } from "helpers/analytics";
import Button from "components/buttons/Button";
import {
  imageUploadErrorReport,
  successImageUploadReport,
} from "api/actions/ImageUploadActions";
import useImageUpload from "hooks/visit/useImageUpload";

function PrimaryCareCompleteVisitDetails() {
  const { setShowModal } = useModal();
  const { incompleteData } = usePrimaryCareVisitFlow();
  const [activeIndex, setActiveIndex] = useState(0);
  const [disabledIndices, setDisabledIndices] = useState([]);
  const [currentPatient, setCurrentPatient] = useState({});
  const [selectedPharmacy, setSelectedPharmacy] = useState(
    incompleteData?.pharmacy_details || {}
  );
  const [shareAllergy, setShareAllergy] = useState(null);
  const [selectedAllergies, setSelectedAllergies] = useState([]);
  const [shareMeds, setShareMeds] = useState(null);
  const [selectedMedications, setSelectedMedications] = useState([]);
  const [imageData, setImageData] = useState(null);

  const { fetchIncompleteData } = useIncompleteData();

  const firstTime = useRef(true);
  const trialref = useRef(null);
  const idRef = useRef(true);
  const imagePickerRef = useRef(null);
  const {
    payload: profileDetails,
    query: getProfileDetails,
    loading: gettingProfileDetails,
  } = useApiQuery(userProfileAction, false);
  const { alertPatient } = useTextCompleteVisitToMobile();

  const {
    payload: revisedInsuranceDetails,
    query: getRevisedInsuranceDetails,
  } = useApiQuery(getRevisedInsuranceAction(incompleteData?.id), false);
  const { mutate: callDiscardVisit, loading: discardingVisit } = useApiMutation(
    discardVisit
  );
  const {
    query: checkInsuranceInNetwork,
    loading: checkingInsuranceInNetwork,
    payload: insuranceInNetwork,
  } = useApiQuery(
    checkInsuranceNetworkAction(
      speciality.primary_care,
      incompleteData?.patient_id
    ),
    false
  );

  const {
    query: getBookingSlots,
    loading: gettingBookingSlots,
    payload: slotsResponse,
  } = useApiQuery(
    getBookingSlotsWithProvidersAction({
      caseId: incompleteData?.id,
    }),
    false
  );

  const { createNewVisit, creatingVisit } = useCreatePrimaryCareVisit();
  const { updateVisit, updatingVisit } = useUpdatePrimaryCareVisit();
  const dispatch = useDispatch();
  const history = useHistory();
  const { showAlert } = useToastify();
  const { userDetails } = useCurrentUser();
  const {
    patientsList,
    getPatients,
    getCurrentPatient,
    fetchingPatients,
  } = usePatients();
  const { loading: bookingSlot, mutate: callBookSlot } = useApiMutation(
    bookSlotAction
  );

  const scrollToActiveSection = useCallback(() => {
    if (activeIndex >= 0 && incompleteData) {
      trialref?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndex, incompleteData]);

  useEffect(() => {
    const init = async () => {
      await fetchIncompleteData(speciality.primary_care);
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scrollToActiveSection();
    }, 500);
  }, [activeIndex, scrollToActiveSection]);

  useEffect(() => {
    if (incompleteData?.patient_id) {
      getBookingSlots();
      checkInsuranceInNetwork();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incompleteData?.patient_id]);

  useEffect(() => {
    if (profileDetails) {
      getPatients();
    }
  }, [profileDetails, getPatients]);

  useEffect(() => {
    setCurrentPatient({
      ...getCurrentPatient(incompleteData?.patient_id, patientsList),
    });
  }, [getCurrentPatient, incompleteData, patientsList]);

  useEffect(() => {
    if (firstTime.current && incompleteData) {
      let index = Object.keys(incompleteData?.pc_second_phase_steps).length;
      if (currentPatient?.gender === "female") {
        if (index === 11 && incompleteData?.accepted_tnc) {
          index = 12;
        }
        if (index === 12 && incompleteData?.meta?.submitted_payment_info) {
          index = null;
        }
      } else {
        if (index === 10 && incompleteData?.accepted_tnc) {
          index = 11;
        }
        if (index === 11 && incompleteData?.meta?.submitted_payment_info) {
          index = null;
        }
      }
      setActiveIndex(index);
    }
  }, [incompleteData, currentPatient]);

  useEffect(() => {
    if (
      firstTime.current &&
      incompleteData?.payment_method === "insurance" &&
      incompleteData?.speciality === "primary_care" &&
      patientsList
    ) {
      firstTime.current = false;
      getRevisedInsuranceDetails();
    }
  }, [incompleteData, getRevisedInsuranceDetails, patientsList]);

  useEffect(() => {
    if (incompleteData) {
      if (incompleteData?.allergies.length === 0) {
        setShareAllergy(
          incompleteData?.health_profile_allergies.length === 0 ? "None" : null
        );
        setSelectedAllergies(incompleteData?.health_profile_allergies);
      } else {
        if (incompleteData?.allergies.includes("None")) {
          setShareAllergy(
            incompleteData?.health_profile_allergies.length === 0
              ? "None"
              : null
          );
          setSelectedAllergies(incompleteData?.health_profile_allergies);
        } else {
          setShareAllergy(null);
          setSelectedAllergies(incompleteData?.allergies_json);
        }
      }

      if (incompleteData?.medications.length === 0) {
        setShareMeds("None");
        setSelectedMedications([]);
      } else {
        if (incompleteData?.medications.includes("None")) {
          setShareMeds("None");
          setSelectedMedications([]);
        } else {
          setShareMeds(null);
          setSelectedMedications(incompleteData?.medications);
        }
      }
    }

    if (
      !incompleteData?.images_uploaded &&
      incompleteData?.meta?.submitted_payment_info
    ) {
      failedImagesRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [incompleteData]);

  useEffect(() => {
    const arr = [];
    if (incompleteData?.pc_second_phase_steps?.select_patient !== "completed") {
      arr.push(1);
    }
    if (
      incompleteData?.pc_second_phase_steps?.select_condition !== "completed"
    ) {
      arr.push(2);
    }
    if (incompleteData?.pc_second_phase_steps?.select_slot !== "completed") {
      arr.push(3);
    }
    if (
      incompleteData?.pc_second_phase_steps?.confirm_residence !== "completed"
    ) {
      arr.push(4);
    }
    if (
      incompleteData?.pc_second_phase_steps?.identity_confirmation !==
      "completed"
    ) {
      arr.push(5);
    }
    if (
      incompleteData?.pc_second_phase_steps?.select_pharmacy !== "completed"
    ) {
      arr.push(6);
    }
    if (incompleteData?.pc_second_phase_steps?.symptoms !== "completed") {
      arr.push(7);
    }
    if (incompleteData?.pc_second_phase_steps?.allergies !== "completed") {
      arr.push(8);
    }

    if (currentPatient?.gender === "female") {
      if (incompleteData?.pc_second_phase_steps?.medications !== "completed") {
        arr.push(9);
      }
      if (
        incompleteData?.pc_second_phase_steps?.female_medical_history !==
        "completed"
      ) {
        arr.push(10);
        arr.push(11);
      }
      if (!incompleteData?.accepted_tnc) {
        arr.push(12);
      }
    } else {
      if (incompleteData?.pc_second_phase_steps?.medications !== "completed") {
        arr.push(9);
        arr.push(10);
      }
      if (!incompleteData?.accepted_tnc) {
        arr.push(11);
      }
    }
    setDisabledIndices(arr);
  }, [incompleteData, currentPatient]);

  const submitVisit = async () => {
    try {
      const payload = {
        date: incompleteData?.meta?.booking_info?.selectedDate,
        slot_id: incompleteData?.meta?.booking_info?.selectedProvider?.slot_id,
        practice_user_id:
          incompleteData?.meta?.booking_info?.selectedProvider?.id,
      };
      const result = await callBookSlot({
        caseId: incompleteData?.id,
        payload,
      });
      if (result && result?.error === false) {
        updateVisit({
          payload: {
            case: {
              allergies: incompleteData?.allergies_json,
              medications: incompleteData?.medications,
              phase: "second_phase",
              coupon_code: incompleteData?.meta?.coupon_code,
              status: "complete_awaiting_diagnosis",
              submitting_source: CURRENT_SOURCE,
            },
          },
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            dispatch(primaryCareVisitFlowReducers.reset());
            dispatch(currentSpeciality({ currentSpeciality: null }));
            dispatch(
              setUserDetails({
                userDetails: { ...userDetails, incomplete_visit_id: null },
              })
            );
            history.replace({
              pathname: "/visit/submitted",
              state: {
                speciality: speciality.primary_care,
                currentPatient,
                bookingInfo: incompleteData?.meta?.booking_info,
              },
            });
          },
        });
      } else {
        if (result.error || result.payload.error || result.payload.error_msg) {
          showAlert(
            result.payload.error ||
              result.payload.error_msg ||
              result.payload.message ||
              "Booking failed.",
            "error"
          );
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    getProfileDetails();
  }, [getProfileDetails]);

  const openChat = () => {
    if (incompleteData?.messages?.length === 0) {
      return;
    } else {
      dispatch(
        updateVisitMessagePayload({
          follow_up: incompleteData?.follow_up,
          unread_provider_messages: incompleteData?.unread_provider_messages,
          messages: incompleteData?.messages,
        })
      );

      history.push(`/chat/${incompleteData?.id}`, {
        speciality: incompleteData?.speciality,
      });
    }
  };

  const alertDesktopPatient = () => {
    if (isDesktop) {
      alertPatient({
        message:
          "It looks like you're on a device that does not have camera access. You will be asked to upload your photo ID now. Do you want to use your smartphone to take photo? We'll text you a link to complete your visit on your smartphone.",
        heading: "Use your smartphone to take photos?",
        openImagePicker: () => imagePickerRef.current.click(),
      });
    } else {
      imagePickerRef.current.click();
    }
  };

  // INFO: Image upload failure UI
  const failedImagesRef = useRef(null);
  const [idImageData, setIdImageData] = useState(null);
  const [idProofLoading, setIdLoading] = useState(false);

  const { query: successImageTracker } = useParameterizedQuery(
    successImageUploadReport
  );
  const { query: reportImageUploadFailure } = useParameterizedQuery(
    imageUploadErrorReport
  );
  const { uploadImage } = useImageUpload();

  function handleIdImageChange(event) {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      let reader = new FileReader();
      reader.onload = (event) => {
        let file_object;
        file_object = {
          uri: files?.[0]?.name?.toString(),
          file: files[0],
          filename: files?.[0]?.name?.toString(),
          data: event?.target?.result,
          size: (files[0]?.size / 1000000).toFixed(2),
        };
        setIdImageData(file_object);
        trackEvent(`identity_image_size`, {
          size: file_object?.size + "mb",
        });
      };
      reader.readAsDataURL(files[0]);
    }
  }

  async function handleIdImageSubmit() {
    try {
      setIdLoading(true);
      const data = await uploadImage(idImageData);

      if (!data) {
        setIdLoading(false);
        showAlert("Image Upload Failed - Please try again", "error");
        setIdImageData(null);
      } else {
        await successImageTracker({
          id: incompleteData?.id,
          payload: {
            uploaded_image: "identity_confirmation",
          },
        });
        updateVisit({
          payload: {
            case: {
              allergies: incompleteData?.allergies_json,
              medications:
                typeof incompleteData?.medications[0] === "string"
                  ? incompleteData?.medications.map((item) => ({
                      name: item,
                    }))
                  : incompleteData?.medications,
              pc_second_phase_steps: {
                ...incompleteData?.pc_second_phase_steps,
                [primary_care_sub_steps.identity_confirmation]: "completed",
              },
            },
          },
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            setIdLoading(false);
            showAlert("ID Proof submitted successfully", "success");
            dispatch(
              primaryCareVisitFlowReducers.identity_confirmation({
                value: idImageData,
              })
            );
          },
        });
      }
    } catch {
      setIdLoading(false);
      showAlert("Image Upload Failed - Please try again", "error");
      setIdImageData(null);
    }
  }

  const AccordionHeader = ({
    headerTitle,
    isActive,
    stepCompleted,
    enabled,
  }) => (
    <div
      className={`flex justify-between items-start py-10 px-10 ${
        isActive ? "rounded-t-4xl" : "rounded-4xl"
      } ${enabled ? "bg-white" : "bg-lightGrey cursor-not-allowed"}`}
    >
      <h3 className="w-3/4 text-3xl text-left font-medium">{headerTitle}</h3>
      <div className="self-center flex space-x-4">
        {stepCompleted ? (
          <img
            src={Images.stepCompleted}
            alt="step_status"
            className="w-10 h-10"
          />
        ) : (
          <div />
        )}
        <img
          src={Images.dropdown}
          alt="chev_down"
          className={`transition duration-500 ease-in-out w-10 h-10 transform ${
            isActive ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
    </div>
  );

  const AccordionContent = ({ children }) => <div>{children}</div>;

  const maleAccordionData = [
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Select Patient"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps?.select_patient ===
            "completed"
          }
          enabled={true}
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 0 ? <div ref={trialref} /> : <div />}
          <SelectPatient
            incompleteData={incompleteData}
            fetchingPatients={fetchingPatients}
            fetchingProfileDetails={gettingProfileDetails}
            createNewVisit={createNewVisit}
            creatingVisit={creatingVisit}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Select Condition"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps?.select_condition ===
            "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps?.select_patient ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 1 ? <div ref={trialref} /> : <div />}
          <SelectCondition
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Select Date & Time Slot"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps?.select_slot === "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps?.select_condition ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 2 ? <div ref={trialref} /> : <div />}
          {history?.location?.state?.timeslotId ? (
            <SelectSlot
              incompleteData={incompleteData}
              updateVisit={updateVisit}
              updatingVisit={updatingVisit}
              setActiveIndex={setActiveIndex}
              gettingBookingSlots={gettingBookingSlots}
              slotsResponse={slotsResponse}
              preselectedSlotId={history?.location?.state?.timeslotId}
            />
          ) : (
            <SelectSlot
              incompleteData={incompleteData}
              updateVisit={updateVisit}
              updatingVisit={updatingVisit}
              setActiveIndex={setActiveIndex}
              gettingBookingSlots={gettingBookingSlots}
              slotsResponse={slotsResponse}
            />
          )}
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Enter demographics"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.confirm_residence ===
            "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps?.select_slot === "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 3 ? <div ref={trialref} /> : <div />}
          <BasicDetails
            incompleteData={incompleteData}
            basicDetails={currentPatient}
            refetch={getProfileDetails}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Confirm your identity"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.identity_confirmation ===
            "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps?.confirm_residence ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 4 ? <div ref={trialref} /> : <div />}
          <IdentityConfirmation
            alertDesktopPatient={alertDesktopPatient}
            firstTimeRef={idRef}
            imagePickerRef={imagePickerRef}
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
            imageData={imageData}
            setImageData={setImageData}
            successImageTracker={successImageTracker}
            reportImageUploadFailure={reportImageUploadFailure}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Add your preferred pharmacy"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.select_pharmacy ===
            "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps.identity_confirmation ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 5 ? <div ref={trialref} /> : <div />}
          <SelectPharmacy
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            selectedPharmacy={selectedPharmacy}
            setSelectedPharmacy={setSelectedPharmacy}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="List all symptoms from the past 2 weeks"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.symptoms === "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps?.select_pharmacy ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 6 ? <div ref={trialref} /> : <div />}
          <Symptoms
            basicDetails={currentPatient}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="List any allergies"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.allergies === "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps.symptoms === "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 7 ? <div ref={trialref} /> : <div />}
          <Allergies
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
            selected={shareAllergy}
            setSelected={setShareAllergy}
            selectedAllergies={selectedAllergies}
            setSelectedAllergies={setSelectedAllergies}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="List any medications you are currently taking"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.medications === "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps.allergies === "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 8 ? <div ref={trialref} /> : <div />}
          <Medications
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
            selected={shareMeds}
            setSelected={setShareMeds}
            selectedMedications={selectedMedications}
            setSelectedMedications={setSelectedMedications}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Record/Write any additional information you would like your doctor to know"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.additional_info ===
            "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps.medications === "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 9 ? <div ref={trialref} /> : <div />}
          <AdditionalInfo
            userDetails={currentPatient}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Please acknowledge that you have read and accept our code of conduct."
          isActive={isActive}
          stepCompleted={incompleteData?.accepted_tnc}
          enabled={
            incompleteData?.pc_second_phase_steps?.medications === "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 10 ? <div ref={trialref} /> : <div />}
          <CodeOfConduct
            enabled={
              incompleteData?.pc_second_phase_steps?.medications === "completed"
            }
            incompleteData={incompleteData}
            userDetails={currentPatient}
            termsAccepted={incompleteData?.accepted_tnc}
            setActiveIndex={setActiveIndex}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Enter your payment details"
          isActive={isActive}
          stepCompleted={incompleteData?.meta?.submitted_payment_info}
          enabled={incompleteData?.accepted_tnc}
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 11 ? <div ref={trialref} /> : <div />}
          {incompleteData?.accepted_tnc ? (
            <PaymentDetails
              incompleteData={incompleteData}
              updateVisit={updateVisit}
              updatingVisit={updatingVisit}
              setActiveIndex={setActiveIndex}
              checkingInsuranceInNetwork={checkingInsuranceInNetwork}
              insuranceInNetwork={insuranceInNetwork}
            />
          ) : (
            <div />
          )}
        </AccordionContent>
      ),
    },
  ];

  const femaleAccordionData = [
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Select Patient"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps?.select_patient ===
            "completed"
          }
          enabled={true}
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 0 ? <div ref={trialref} /> : <div />}
          <SelectPatient
            incompleteData={incompleteData}
            fetchingPatients={fetchingPatients}
            fetchingProfileDetails={gettingProfileDetails}
            createNewVisit={createNewVisit}
            creatingVisit={creatingVisit}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Select Condition"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps?.select_condition ===
            "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps?.select_patient ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 1 ? <div ref={trialref} /> : <div />}
          <SelectCondition
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Select Date & Time Slot"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps?.select_slot === "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps?.select_condition ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 2 ? <div ref={trialref} /> : <div />}
          {history?.location?.state?.timeslotId ? (
            <SelectSlot
              incompleteData={incompleteData}
              updateVisit={updateVisit}
              updatingVisit={updatingVisit}
              setActiveIndex={setActiveIndex}
              gettingBookingSlots={gettingBookingSlots}
              slotsResponse={slotsResponse}
              preselectedSlotId={history?.location?.state?.timeslotId}
            />
          ) : (
            <SelectSlot
              incompleteData={incompleteData}
              updateVisit={updateVisit}
              updatingVisit={updatingVisit}
              setActiveIndex={setActiveIndex}
              gettingBookingSlots={gettingBookingSlots}
              slotsResponse={slotsResponse}
            />
          )}
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Enter demographics"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.confirm_residence ===
            "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps?.select_slot === "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 3 ? <div ref={trialref} /> : <div />}
          <BasicDetails
            incompleteData={incompleteData}
            basicDetails={currentPatient}
            refetch={getProfileDetails}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Confirm your identity"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.identity_confirmation ===
            "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps?.confirm_residence ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 4 ? <div ref={trialref} /> : <div />}
          <IdentityConfirmation
            alertDesktopPatient={alertDesktopPatient}
            firstTimeRef={idRef}
            imagePickerRef={imagePickerRef}
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
            imageData={imageData}
            setImageData={setImageData}
            successImageTracker={successImageTracker}
            reportImageUploadFailure={reportImageUploadFailure}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Add your preferred pharmacy"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.select_pharmacy ===
            "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps.identity_confirmation ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 5 ? <div ref={trialref} /> : <div />}
          <SelectPharmacy
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            selectedPharmacy={selectedPharmacy}
            setSelectedPharmacy={setSelectedPharmacy}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="List all symptoms from the past 2 weeks"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.symptoms === "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps?.select_pharmacy ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 6 ? <div ref={trialref} /> : <div />}
          <Symptoms
            basicDetails={currentPatient}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="List any allergies"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.allergies === "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps.symptoms === "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 7 ? <div ref={trialref} /> : <div />}
          <Allergies
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
            selected={shareAllergy}
            setSelected={setShareAllergy}
            selectedAllergies={selectedAllergies}
            setSelectedAllergies={setSelectedAllergies}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="List any medications you are currently taking"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.medications === "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps.allergies === "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 8 ? <div ref={trialref} /> : <div />}
          <Medications
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
            selected={shareMeds}
            setSelected={setShareMeds}
            selectedMedications={selectedMedications}
            setSelectedMedications={setSelectedMedications}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Additional medical conditions"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.female_medical_history ===
            "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps.medications === "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 9 ? <div ref={trialref} /> : <div />}
          <FemaleMedicalHistory
            incompleteData={incompleteData}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Record/Write any additional information you would like your doctor to know"
          isActive={isActive}
          stepCompleted={
            incompleteData?.pc_second_phase_steps.additional_info ===
            "completed"
          }
          enabled={
            incompleteData?.pc_second_phase_steps.female_medical_history ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 10 ? <div ref={trialref} /> : <div />}
          <AdditionalInfo
            userDetails={currentPatient}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
            setActiveIndex={setActiveIndex}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Please acknowledge that you have read and accept our code of conduct."
          isActive={isActive}
          stepCompleted={incompleteData?.accepted_tnc}
          enabled={
            incompleteData?.pc_second_phase_steps?.female_medical_history ===
            "completed"
          }
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 11 ? <div ref={trialref} /> : <div />}
          <CodeOfConduct
            enabled={
              incompleteData?.pc_second_phase_steps?.female_medical_history ===
              "completed"
            }
            incompleteData={incompleteData}
            userDetails={currentPatient}
            termsAccepted={incompleteData?.accepted_tnc}
            setActiveIndex={setActiveIndex}
            updateVisit={updateVisit}
            updatingVisit={updatingVisit}
          />
        </AccordionContent>
      ),
    },
    {
      header: ({ isActive }) => (
        <AccordionHeader
          headerTitle="Enter your payment details"
          isActive={isActive}
          stepCompleted={incompleteData?.meta?.submitted_payment_info}
          enabled={incompleteData?.accepted_tnc}
        />
      ),
      content: (
        <AccordionContent>
          {activeIndex === 12 ? <div ref={trialref} /> : <div />}
          {incompleteData?.accepted_tnc ? (
            <PaymentDetails
              incompleteData={incompleteData}
              updateVisit={updateVisit}
              updatingVisit={updatingVisit}
              setActiveIndex={setActiveIndex}
              checkingInsuranceInNetwork={checkingInsuranceInNetwork}
              insuranceInNetwork={insuranceInNetwork}
            />
          ) : (
            <div />
          )}
        </AccordionContent>
      ),
    },
  ];

  const confirmDeleteVisit = useCallback(async () => {
    try {
      const response = await callDiscardVisit(incompleteData?.id);
      if (response?.error === false) {
        dispatch(primaryCareVisitFlowReducers.reset());
        dispatch(
          setUserDetails({
            userDetails: { ...userDetails, incomplete_visit_id: null },
          })
        );
        history.goBack();
      }
    } catch (error) {}
  }, [callDiscardVisit, dispatch, history, incompleteData?.id, userDetails]);

  const deleteVisit = () => {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <Alert
          heading="Delete visit"
          message={"Are you sure you want to delete your visit?"}
          primaryButtonText="Confirm"
          onSecondaryButtonClick={onClose}
          onPrimaryButtonClick={() => {
            onClose();
            confirmDeleteVisit();
          }}
        />
      ),
      data: { preventRender: true },
    });
  };

  if (discardingVisit) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
        }}
      />
    );
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <Header
        title="Primary Care Visit Details"
        rightActionView={
          incompleteData && Object.keys(incompleteData)?.length ? (
            <p
              onClick={deleteVisit}
              className="text-red text-xl font-medium bg-red bg-opacity-10 px-6 py-4 rounded-md cursor-pointer"
            >
              Delete
            </p>
          ) : null
        }
      />
      <div className="wrapper h-eliminateHeaderWithFooter overflow-y-auto">
        <div className="p-12 min-h-full bg-blue">
          <MedicalAssistant
            content={
              <p>
                Hi
                {incompleteData?.patient_first_name
                  ? ` ${incompleteData?.patient_first_name}`
                  : userDetails?.first_name
                  ? ` ${userDetails?.first_name}`
                  : null}
                , let's get started!
                <br />
                <br />
                Please complete the following steps so that our doctor will have
                all the required information before your visit.
              </p>
            }
          />
          <Spacer height={20} />
          {revisedInsuranceDetails?.revised_insurance_line_items
            ?.price_change_type === "revised" &&
          !revisedInsuranceDetails?.revised_insurance_line_items
            ?.price_change_confirmed_at ? (
            <RevisedPaymentBlock
              visitDetails={incompleteData}
              practiceInsurance={revisedInsuranceDetails?.practice_insurance}
              revisedInsuranceDetails={
                revisedInsuranceDetails?.revised_insurance_line_items
              }
            />
          ) : (
            <div />
          )}
          <Spacer height={20} />
          {incompleteData?.failed_images_names?.length > 0 && (
            <div
              ref={failedImagesRef}
              className="space-y-4 mx-0 my-4 p-4 w-full rounded-md bg-yellow-200"
            >
              <p className="font-bold text-2xl flex items-center gap-3">
                <IoAlertCircleOutline /> OOPS! Your photos didn't upload. Select
                link(s) below to re-upload images again to submit your visit.
              </p>
              {incompleteData?.failed_images_names?.includes(
                "identity_confirmation"
              ) ? (
                <div className="flex flex-col">
                  <input
                    ref={imagePickerRef}
                    id="confirm_identity_image_picker"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={handleIdImageChange}
                    className="hidden"
                  />
                  <label
                    htmlFor="confirm_identity_image_picker"
                    className="py-2 flex justify-between items-center cursor-pointer hover:underline"
                  >
                    <p className="text-xl font-semibold">
                      {incompleteData?.failed_images_names?.length}.{" "}
                      {startCase("identity_confirmation")}
                    </p>
                    <p class="font-semibold">
                      {idImageData ? "Completed" : "Click here"}
                    </p>
                  </label>
                  <Button
                    textSize="text-2xl"
                    className="px-4 py-2"
                    disabled={!idImageData}
                    loading={idProofLoading}
                    onClick={handleIdImageSubmit}
                    label="Submit ID Proof"
                  />
                </div>
              ) : null}
            </div>
          )}

          <Spacer height={20} />
          <h3 className="font-black text-2xl pb-4 tracking-wide">
            YOUR VISIT WITH
          </h3>
          {incompleteData?.provider_id ? (
            <div className="rounded-4xl p-5 border-1/4 border-borderColor bg-white">
              <DoctorCard
                imageUrl={incompleteData?.provider_photo_url}
                name={incompleteData?.provider_name}
                speciality="Family Practice: Primary Care Physician"
                isActive={true}
                license={incompleteData?.license}
              />
            </div>
          ) : (
            <div />
          )}
          {incompleteData?.messages.length > 0 ? (
            <>
              <Spacer height={20} />
              <div
                onClick={openChat}
                className="cursor-pointer flex items-center p-6 rounded-4xl bg-white border border-borderColor"
              >
                <img
                  className="h-24 w-24"
                  src={Images.messageIcon}
                  alt="start_visit"
                />
                <div className="ml-6">
                  <h2 className="font-bold text-3xl">Messages</h2>
                  <p className="font-light text-2xl leading-8 mt-2 text-cloudyBlue">
                    {incompleteData?.unread_provider_messages > 0
                      ? `${
                          incompleteData?.unread_provider_messages
                        } unread message${
                          incompleteData?.unread_provider_messages === 1
                            ? ""
                            : "s"
                        }`
                      : "No new messages"}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div />
          )}
          <Spacer height={20} />
          <Accordion
            activeIndexFromData={activeIndex}
            accordionContainerClassName="mb-5 rounded-4xl"
            containerClassName="border-none"
            data={
              currentPatient?.gender === "female"
                ? femaleAccordionData
                : maleAccordionData
            }
            disabledIndicesFromData={disabledIndices}
            enableTransition={true}
          />
        </div>
      </div>
      <BottomFixedButton
        disabled={
          !incompleteData?.images_uploaded ||
          !incompleteData?.meta?.submitted_payment_info ||
          !incompleteData?.accepted_tnc
        }
        loading={
          (incompleteData?.meta?.submitted_payment_info &&
            incompleteData?.accepted_tnc &&
            updatingVisit) ||
          bookingSlot
        }
        type="submit"
        label="Submit Visit"
        onClick={submitVisit}
      />
      {isMobile ? (
        <div
          className="fixed right-8 bottom-40 cursor-pointer border-2 rounded-full w-24 h-24 bg-orange border-orange flex items-center justify-center"
          onClick={scrollToActiveSection}
        >
          <img
            className="h-10 w-10 filter brightness-0 invert"
            src={Images.checkedGreen}
            alt="scroll to active section"
          />
        </div>
      ) : (
        <div
          onClick={scrollToActiveSection}
          className="fixed mr-10 right-1/4 bottom-40 w-24 h-24 flex items-center justify-center rounded-full bg-orange cursor-pointer shadow-d-2xl"
        >
          <img
            className="w-1/3 filter brightness-0 invert"
            src={Images.checkedGreen}
            alt="scroll to active section"
          />
        </div>
      )}
    </div>
  );
}

export default PrimaryCareCompleteVisitDetails;
