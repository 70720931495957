import React, { useState } from "react";
import Images from "assets/images";
import Button from "components/buttons/Button";
import useToastify from "hooks/useToastify";
import { bh_sub_steps } from "helpers/enum";

function BHLegalAgreement({
  incompleteData,
  setActiveIndex,
  updateVisit,
  updatingVisit,
}) {
  const { showAlert } = useToastify();
  const [agreementAccepted, setAgreementAcceped] = useState(
    incompleteData?.accepted_legal_step
  );

  const acceptAgreement = () => {
    updateVisit({
      payload: {
        case: {
          accepted_legal_step: agreementAccepted,
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications,
          bh_second_phase_steps: {
            ...incompleteData?.bh_second_phase_steps,
            [bh_sub_steps.accepted_legal_step]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        showAlert("Agreement Accepted", "success");
        setActiveIndex(13);
      },
    });
  };

  return (
    <div className="bg-white rounded-b-4xl p-10">
      <div className="overflow-y-scroll h-60 font-medium text-xl">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sagittis
          et metus nec faucibus. Vestibulum tempus augue tincidunt placerat
          commodo. Fusce non cursus nibh, id malesuada risus. Fusce aliquet
          lacinia nisi, tempus tristique quam commodo at. Pellentesque euismod
          elit at quam aliquet aliquam. Proin facilisis leo a purus aliquam, sed
          varius quam lacinia. Curabitur venenatis placerat mattis. Morbi
          iaculis lectus vitae felis euismod, et condimentum ipsum volutpat.
          Curabitur pharetra pharetra convallis. Nulla malesuada ultricies nibh,
          et consequat lorem rhoncus et. Donec non dui venenatis, blandit lacus
          sit amet, varius dui. Quisque tincidunt pharetra mi non faucibus. Cras
          posuere dignissim odio id venenatis. Aenean et lobortis lorem. Ut ut
          est scelerisque, mollis mauris eget, sagittis est.
        </p>
        <br />
        <p>
          Morbi porta nibh et neque rhoncus bibendum at nec justo. Ut luctus
          lorem non tempus malesuada. Nullam laoreet sem quam, nec lacinia orci
          semper vitae. Maecenas blandit, magna ac condimentum tempor, urna
          ligula condimentum velit, in fringilla quam diam id urna. Nulla
          pellentesque fringilla nisl, sed tristique mauris. Nulla facilisi.
          Etiam blandit aliquam dignissim. Nam diam nisi, blandit nec metus eu,
          bibendum commodo quam. Vivamus sit amet nulla mollis, porta tortor sit
          amet, venenatis risus. Donec et fermentum mauris. Vestibulum a viverra
          arcu. Morbi at risus eget lacus pulvinar posuere id eu arcu. Nullam
          accumsan viverra tincidunt. Proin rutrum metus id sapien pellentesque
          auctor. Vestibulum ante ipsum primis in faucibus orci luctus et
          ultrices posuere cubilia curae; Proin consectetur ornare leo, at
          finibus nisi imperdiet ac.
        </p>
        <br />
        <p>
          In hac habitasse platea dictumst. Donec laoreet sed nibh sit amet
          volutpat. Aenean id velit vestibulum orci dictum vestibulum. Proin
          tristique lorem quis quam venenatis consectetur. Suspendisse sapien
          ante, ullamcorper at tellus id, placerat mollis neque. Mauris faucibus
          eu ligula at congue. Etiam dignissim, dui ut tincidunt placerat, dui
          elit sagittis lorem, nec iaculis purus quam feugiat urna. Nulla vitae
          eros semper ex eleifend lacinia. Cras ullamcorper mollis nisi nec
          egestas. Nulla facilisi.
        </p>
        <br />
        <p>
          In interdum massa sit amet magna maximus, eu vulputate neque volutpat.
          Orci varius natoque penatibus et magnis dis parturient montes,
          nascetur ridiculus mus. Donec scelerisque elit eu neque semper, id
          condimentum massa faucibus. Fusce iaculis tincidunt neque eget mattis.
          Donec pretium pharetra mollis. In vulputate mattis auctor. Sed nec
          eros quis sem fermentum volutpat sed sit amet neque. Quisque et
          ullamcorper felis. Aenean dignissim venenatis mi non accumsan.
        </p>
        <br />
        <p>
          Sed mollis facilisis nisl, a scelerisque magna accumsan et. Donec
          placerat leo vitae tincidunt ullamcorper. Aenean lacinia, turpis eget
          rutrum dignissim, sem tellus sagittis mauris, vel feugiat quam lectus
          vel tellus. Curabitur mauris tellus, ultricies in volutpat at,
          vehicula ut lectus. Aenean eu elit magna. Nam gravida magna lorem, eu
          pharetra sem posuere at. Nulla faucibus tortor eget ante vestibulum,
          vel maximus urna blandit. Praesent a libero dignissim, laoreet enim
          posuere, pretium ex.
        </p>
        <div className="py-5">
          <div
            onClick={() => setAgreementAcceped((prev) => !prev)}
            className="flex cursor-pointer"
          >
            <img
              className="w-10 h-10"
              src={
                agreementAccepted
                  ? Images.checkedOrange
                  : Images.uncheckedCircle
              }
              alt="checked"
            />
            <p className="pl-5 text-2xl leading-8 font-bold pt-1">
              I have read and agree to the terms of the agreement
            </p>
          </div>
        </div>
      </div>
      <Button
        disabled={!agreementAccepted}
        loading={updatingVisit}
        className="mt-5 py-4"
        label="Next"
        onClick={acceptAgreement}
      />
    </div>
  );
}

export default BHLegalAgreement;
