import Images from "assets/images";
import Accordion from "components/accordion/Accordion";
import { getVisitDetails } from "helpers/functions";
import React from "react";
import { ContentCard } from "./MiscComponents";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import "./tabs.css";

function Header({ label, isActive }) {
  return (
    <div className={"flex items-center justify-between px-4 pb-6"}>
      <div className="flex items-center">
        <h2 className="text-3xl font-bold ">{label}</h2>
      </div>
      <div>
        <img
          src={Images.dropdown}
          alt="chev_down"
          className={`transition duration-500 ease-in-out w-10 h-10 transform ${
            isActive ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
    </div>
  );
}
function SubHeader({ label, index }) {
  return (
    <div className={`flex items-center justify-between py-6 px-2`}>
      <div className="flex items-center">
        <h2 className="text-2xl font-medium ">{label}</h2>
      </div>
    </div>
  );
}

function EvaluatedAccordion({ speciality, loading, queryDetails, details }) {
  const filteredData = getVisitDetails(
    speciality,
    details,
    "evaluated"
  )?.filter((obj) => obj !== false && obj !== null);
  const accordionData = [
    {
      header: (props) => (
        <Header {...props} label={"Diagnosis and Treatment Plan"} />
      ),
      content: (
        <div className="space-y-4">
          <div className="py-6">
            <Tab.Group>
              <Tab.List className="flex space-x-1 border-b scroll">
                {filteredData?.map((e) => {
                  return (
                    <Tab
                      key={`Tab-${e.label}`}
                      className={({ selected }) =>
                        classNames(
                          "w-80 py-2.5 flex items-center flex-shrink-0 space-x-4 justify-center text-2xl ring-0 focus:ring-0 focus:outline-none",
                          selected
                            ? "border-b-2 border-orange font-bold text-orange"
                            : "text-blue-100 hover:bg-white/[0.12] font-medium"
                        )
                      }
                    >
                      <div> {e.label}</div>
                      {e.key === "otc" && e.content.available_OTC && (
                        <div
                          className={`rounded-full w-7 h-7 text-md font-bold items-center justify-center flex bg-errorRed text-white `}
                        >
                          {"!"}
                        </div>
                      )}
                    </Tab>
                  );
                })}
              </Tab.List>
              <Tab.Panels className="mt-2">
                {filteredData?.map((e, i) => {
                  if (e) {
                    return (
                      <Tab.Panel
                        key={`Content-${e.label}`}
                        className={classNames(
                          "rounded-xl bg-white p-3",
                          "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                        )}
                      >
                        <div>
                          <SubHeader label={e.label} index={i} />
                          <ContentCard
                            content={e.content}
                            type={e.key}
                            index={i}
                          />
                        </div>
                      </Tab.Panel>
                    );
                  } else return null;
                })}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Accordion
      data={
        speciality === "behavior_health"
          ? accordionData?.splice(1)
          : accordionData
      }
      loading={details === null ? true : loading}
      containerClassName={"pt-6"}
      onExpand={(a) => {
        if (!details) {
          queryDetails();
        }
      }}
    />
  );
}
export default EvaluatedAccordion;
