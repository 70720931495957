import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import "./Stepper.css";

//This is more generic than StepContainer Compoennt
const Step = ({ number, active, completed }) => {
  return (
    <div
      className={classNames(
        "flex justify-center items-center w-12 h-12 border",
        completed && "bg-orange border-orange text-white",
        active && "border-orange bg-transparent text-orange",
        !active && !completed && "bg-grey border-grey-600 text-grey-600",
        "rounded-full bg-transparent"
      )}
    >
      <p className="text-2xl font-bold">{number}</p>
    </div>
  );
};

const StepLabel = ({ text, className }) => {
  return (
    <p className={classNames(" font-medium text-xl text-black", className)}>
      {text}
    </p>
  );
};

const Stepper = ({ steps, customWidth = true }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const containerRef = useRef(null);
  useEffect(() => {
    const updateStepStatus = () => {
      const tempCompletedSteps = steps
        .filter((step) => step.status === "completed")
        .map((step) => step.step_name);

      setCompletedSteps(tempCompletedSteps);

      const tempStepIndex = steps.findIndex(
        (step) =>
          step.status === "current" ||
          (step.status === "incomplete" &&
            !tempCompletedSteps.includes(step.step_name))
      );
      setActiveStepIndex(tempStepIndex >= 0 ? tempStepIndex : steps.length - 1);
    };

    updateStepStatus();
  }, [steps]);

  const stepWidth = 13;

  // Calculate the total width of all the steps
  const totalWidth = steps.length > 4 ? steps.length * stepWidth : "100%";

  const containerStyle = {
    width: customWidth ? `${totalWidth}rem` : "100%",
    overflowX: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    WebkitOverflowScrolling: "touch",
    "&::WebkitScrollbar?": { width: "0px", height: "0px" },
    // " @media (min-width: 1200px)": {
    //   width: `totalWidth`,
    // },
  };

  useEffect(() => {
    // Scroll to the active step when it changes
    if (containerRef.current) {
      const activeStep = containerRef.current.querySelector(
        `.step-${activeStepIndex}`
      );
      if (activeStep) {
        containerRef.current.scrollTo({
          left: activeStep.offsetLeft - containerRef.current.offsetLeft,
          behavior: "smooth",
        });
      }
    }
  }, [activeStepIndex]);

  // For showing substeps below
  const completeSteps = steps.filter((step) => step.status === "completed");
  const lastCompletedStep = completeSteps[completeSteps.length - 1];
  const completedSubsteps = lastCompletedStep
    ? lastCompletedStep.sub_steps
    : // .filter(
      //   (substep) => substep.status === "completed"
      // )
      [];

  const currentStep = steps.find((step) => step.status === "current");
  const currentSubsteps = currentStep
    ? currentStep.sub_steps.filter((substep) => substep.status === "completed")
    : [];

  const completedAndCurrentSubsteps = [
    ...completedSubsteps,
    ...currentSubsteps,
  ];
  return (
    <div className="w-full">
      <div ref={containerRef} className="overflow-x-auto stepper-container">
        <div
          className="flex flex-col py-4 text-white rounded-3xl bg-transparent"
          style={containerStyle}
        >
          <div className="flex items-center justify-between ">
            {steps.map((step, index) => (
              <React.Fragment key={`s1-${index}`}>
                <div className="flex items-center space-x-4">
                  <Step
                    number={index + 1}
                    active={activeStepIndex === index}
                    completed={completedSteps.includes(step.step_name)}
                  />
                  {/* <StepLabel
                    active={activeStepIndex === index}
                    completed={completedSteps.includes(step.step_name)}
                    text={step.step_name}
                    className={"md:hidden"}
                  /> */}
                </div>
                {index < steps.length - 1 && (
                  <div
                    className={classNames(
                      "flex-grow border-t ",
                      completedSteps.includes(step.step_name)
                        ? "border-orange"
                        : "border-grey-600"
                    )}
                  ></div>
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="justify-between flex pt-1.5">
            {steps.map((step, index) => (
              <div key={`s2-${index}`} className={`step-${index}`}>
                <StepLabel
                  key={index}
                  text={step.step_name}
                  active={activeStepIndex === index}
                  completed={completedSteps.includes(step.step_name)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {completedAndCurrentSubsteps.length > 0 && (
        <div className="flex flex-col pt-4 space-y-3">
          {completedAndCurrentSubsteps?.map((e) => {
            return (
              <div
                key={`s3-${e.step_name}`}
                className="flex items-center space-x-3 text-xl font-light"
              >
                <div className="w-5 h-5 rounded-full bg-orange" />
                <div> {e.step_name} - Completed</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Stepper;

// {
/* <div className="flex flex-col pt-4 space-y-3">
  {currentProgress.completed.map((e) => {
    const step = DermaSteps.find((step) => step.name === e);
    return (
      step && (
        <div
          key={step.label}
          className="flex items-center space-x-3 text-xl font-light"
        >
          <div className="w-5 h-5 rounded-full bg-orange" />
          <div> {step.label} - completed</div>
        </div>
      )
    );
  })}
</div> */
// }
