import React, { useEffect, useState } from "react";
import Images from "assets/images";
import DoctorCard from "components/doctorCard/DoctorCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { useApiQuery } from "hooks/useApiQuery";
import moment from "moment";
import DateChip from "components/slotChip/DateChip";
import TimeSlotChip from "components/slotChip/TimeSlotChip";
import CSSLoader from "components/cssLoader/CSSLoader";
import { useDispatch } from "react-redux";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import { bh_main_steps, bh_sub_steps } from "helpers/enum";
import useUpdateBehaviorHealthVisit from "hooks/visit/useUpdateBehaviorHealthVisit";
import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";
import { checkAlreadyCompleted } from "helpers/setBHStepStatus";
import useModal from "hooks/useModal";
import DoctorProfileDetails from "pages/home/waitingRoom/medicalIntake/selectedProviders/DoctorProfileDetails/DoctorProfileDetails";
import scrollToBottom from "helpers/scrollToBottom";
import { getBookingSlotsWithProvidersAction } from "api/actions/VisitActions";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import CosmeticSelectProvider from "pages/home/waitingRoom/consultation/cosmetic/cosmeticFlow/CosmeticSelectProvider";
import { useHistory } from "react-router-dom";
import { useApiMutation } from "hooks/useApiMutation";
import { bookSlotAction } from "api/actions/VisitActions";
import useToastify from "hooks/useToastify";
import LoaderComponent from "components/loader/LoaderComponent";

function SelectSlot() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);

  const dispatch = useDispatch();
  const { setShowModal } = useModal();
  const { incompleteData, subSteps } = useBehaviorHealthVisitFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore = subSteps[bh_sub_steps.select_slot].value;
  const history = useHistory();
  const { showAlert } = useToastify();

  const {
    loading: gettingBookingSlots,
    payload: slotsResponse,
    query: getBookingSlot,
  } = useApiQuery(
    getBookingSlotsWithProvidersAction({
      caseId: incompleteData?.id,
    }),
    false
  );
  const { updateVisit, updatingVisit } = useUpdateBehaviorHealthVisit();

  const { loading: bookingSlot, mutate: callBookSlot } = useApiMutation(
    bookSlotAction
  );

  useEffect(() => {
    getBookingSlot();
  }, [getBookingSlot]);

  useEffect(() => {
    if (
      currentScreenValueFromStore &&
      Object.keys(currentScreenValueFromStore)?.length &&
      slotsResponse
    ) {
      setSelectedDate(currentScreenValueFromStore?.selectedDate);
      const findSlotFromPayload = slotsResponse?.[
        currentScreenValueFromStore?.selectedDate
      ]?.find(
        (el) => el?.slot_id === currentScreenValueFromStore?.slotInfo?.slot_id
      );
      if (
        findSlotFromPayload?.booked === false ||
        findSlotFromPayload?.hasOwnProperty("booked") === false ||
        currentScreenValueFromStore?.slotInfo?.booked === false
      ) {
        setSelectedSlot(currentScreenValueFromStore?.slotInfo);
        setSelectedProvider(currentScreenValueFromStore?.selectedProvider);
      }
    }
  }, [currentScreenValueFromStore, slotsResponse]);

  useEffect(() => {
    if (selectedSlot?.slot_id && selectedProvider) {
      dispatch(
        behaviorHealthVisitFlowReducers.select_slot({
          isValid: true,
        })
      );
      scrollToBottom();
    } else {
      dispatch(
        behaviorHealthVisitFlowReducers.select_slot({
          isValid: false,
        })
      );
    }
  }, [dispatch, selectedSlot, selectedProvider]);

  async function onRightButtonClick(_, nextAnimation) {
    if (incompleteData?.phase) {
      try {
        const payload = {
          date: selectedDate,
          slot_id: selectedSlot?.slot_id,
          practice_user_id: selectedProvider?.id,
        };
        const result = await callBookSlot({
          caseId: incompleteData?.id,
          payload,
        });

        if (result && result?.error === false) {
          updateVisit({
            payload: {
              case: {
                phase: "first_phase",
                allergies: incompleteData?.allergies_json,
                medications:
                  typeof incompleteData?.medications[0] === "string"
                    ? incompleteData?.medications.map((item) => ({
                        name: item,
                      }))
                    : incompleteData?.medications,
                behavior_health_provider_id: selectedProvider?.id,
                behavior_health_steps: {
                  main_steps: {
                    ...incompleteData?.behavior_health_steps?.main_steps,
                    [bh_main_steps.main_select_slot]: "completed",
                    [bh_main_steps.basic_info]: checkAlreadyCompleted({
                      screen_name: bh_main_steps.basic_info,
                      behavior_health_steps:
                        incompleteData?.behavior_health_steps,
                      step_placement: "main_steps",
                    }),
                  },
                  sub_steps: {
                    ...incompleteData?.behavior_health_steps?.sub_steps,
                  },
                },
                meta: {
                  ...incompleteData?.meta,
                  booking_info: {
                    selectedDate,
                    slotInfo: selectedSlot,
                    selectedProvider,
                  },
                },
              },
            },
            change_current_screen: false,
            screen_to_complete: incompleteData?.phase
              ? bh_sub_steps.select_slot
              : null,
            callBack: () => {
              dispatch(
                behaviorHealthVisitFlowReducers.select_slot({
                  value: {
                    selectedDate,
                    slotInfo: selectedSlot,
                    selectedProvider,
                  },
                })
              );
              history?.replace(
                "/visit/behavior-health/appointment-confirmation"
              );
            },
          });
        } else {
          if (
            result.error ||
            result.payload.error ||
            result.payload.error_msg
          ) {
            getBookingSlot();
            window.location.reload();
            showAlert(
              result.payload.error ||
                result.payload.error_msg ||
                result.payload.message ||
                "Booking failed.",
              "error"
            );
          }
        }
      } catch (err) {}
    } else {
      updateVisit({
        payload: {
          case: {
            behavior_health_provider_id: selectedProvider?.id,
            behavior_health_steps: {
              main_steps: {
                ...incompleteData?.behavior_health_steps?.main_steps,
                [bh_main_steps.main_select_slot]: "completed",
                [bh_main_steps.basic_info]: checkAlreadyCompleted({
                  screen_name: bh_main_steps.basic_info,
                  behavior_health_steps: incompleteData?.behavior_health_steps,
                  step_placement: "main_steps",
                }),
              },
              sub_steps: {
                ...incompleteData?.behavior_health_steps?.sub_steps,
              },
            },
            meta: {
              ...incompleteData?.meta,
              booking_info: {
                selectedDate,
                slotInfo: selectedSlot,
                selectedProvider,
              },
            },
          },
        },
        change_current_screen: false,
        screen_to_complete: null,
        callBack: () => {
          dispatch(
            behaviorHealthVisitFlowReducers.select_slot({
              value: {
                selectedDate,
                slotInfo: selectedSlot,
                selectedProvider,
              },
            })
          );
          history?.push("/visit/behavior-health/terms_and_conditions");
        },
      });
    }
  }

  if (bookingSlot || updatingVisit) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
          message: "Booking your slot",
        }}
      />
    );
  }

  return (
    <StepScreenContainer align="start">
      <MedicalAssistant
        content={
          <p>
            Thank you! We are looking forward to assisting you. Please select a
            date and time that you are available to see one of our Family
            practice doctors.
          </p>
        }
      />
      <Spacer height={40} />
      <Card>
        {gettingBookingSlots ? (
          <div className={"h-40 flex items-center justify-center"}>
            <CSSLoader className={"w-16 h-16"} color={"orange"} />
          </div>
        ) : (
          <div>
            {slotsResponse && Object.keys(slotsResponse)?.length ? (
              <>
                <label className="font-bold text-2xl text-indigo text-opacity-50">
                  Select Day
                </label>
                <Spacer height={15} />
                <div className="overflow-auto">
                  <div className="flex pb-10">
                    {Object.keys(slotsResponse)?.map((el, idx) => {
                      return (
                        <DateChip
                          key={idx}
                          numberOfSlots={slotsResponse?.[el]?.length}
                          onClick={() => {
                            setSelectedProvider(null);
                            setSelectedSlot(null);
                            setSelectedDate(el);
                          }}
                          selected={el === selectedDate}
                          isLast={idx === Object.keys(slotsResponse)?.length}
                          date={
                            moment().format("YYYY-MM-DD") === el
                              ? "Today"
                              : moment().add(1, "days").format("YYYY-MM-DD") ===
                                el
                              ? "Tomorrow"
                              : moment(el, "YYYY-MM-DD").format("DD MMM")
                          }
                          day={moment(el).format("ddd")}
                        />
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <h2 className="text-2xl font-semibold text-center text-indigo text-opacity-50">
                Oops! No slots available. Please try again later.
              </h2>
            )}
            {selectedDate ? (
              <div>
                <Spacer height={15} />
                <label className="font-bold text-2xl text-indigo text-opacity-50">
                  Select Time
                </label>
                {slotsResponse?.[selectedDate]?.length ? (
                  <div>
                    <Spacer height={15} />
                    {slotsResponse?.[selectedDate]?.length ? (
                      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
                        {slotsResponse?.[selectedDate]?.map((el, idx) => {
                          return (
                            <TimeSlotChip
                              key={idx}
                              onClick={() => {
                                setSelectedSlot(el);
                                setSelectedProvider(el?.providers?.[0]);
                              }}
                              disabled={el?.booked}
                              selected={selectedSlot?.slot_id === el?.slot_id}
                              isLast={
                                idx === slotsResponse?.[selectedDate]?.length
                              }
                              slot={`${moment(el?.start_time, "hh:mma").format(
                                "h:mm A"
                              )} - ${moment(el?.end_time, "hh:mma").format(
                                "h:mm A"
                              )}`}
                            />
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <p className="text-xl pt-4 font-normal">
                    Oops! No slots available. Please try selecting other dates.
                  </p>
                )}
              </div>
            ) : null}
          </div>
        )}
      </Card>
      {selectedSlot ? (
        <div>
          <Spacer height={36} />
          <MedicalAssistant
            content={
              <p>
                Your pre-selected doctor is{" "}
                <span className="font-black">{selectedProvider?.name}</span>, or
                you may select a different provider.
              </p>
            }
          />
          <Spacer height={26} />
          <Card>
            <div className="border border-borderColor rounded-3xl p-6 relative">
              <DoctorCard
                license={selectedProvider?.license}
                isActive={selectedProvider?.active}
                name={selectedProvider?.name}
                imageUrl={selectedProvider?.avatar}
                speciality={selectedProvider?.speciality ?? ""}
                viewProfile={true}
                onViewProfileClick={() =>
                  setShowModal({
                    state: true,
                    children: (_, onClose) => (
                      <DoctorProfileDetails
                        details={selectedProvider}
                        onClose={onClose}
                      />
                    ),
                  })
                }
              />
            </div>
            {selectedSlot?.providers.length > 1 ? (
              <div className="flex flex-1 items-center justify-center mt-7">
                <div
                  onClick={() => {
                    setShowModal({
                      state: true,
                      children: (_, onClose) => (
                        <CosmeticSelectProvider
                          selectedProvider={selectedProvider}
                          availableProviders={selectedSlot?.providers}
                          onSelectProvider={setSelectedProvider}
                          onClose={onClose}
                        />
                      ),
                    });
                  }}
                  className="flex items-center justify-center cursor-pointer"
                >
                  <p className="pr-6 text-2xl font-medium">Change Provider</p>
                  <img className="w-8" src={Images.arrowRight} alt={"go"} />
                </div>
              </div>
            ) : null}
          </Card>
        </div>
      ) : null}
    </StepScreenContainer>
  );
}

export default SelectSlot;
