import React from "react";
import { useCurrentUser } from "hooks/useCurrentUser";

function BHInsuranceOption() {
  const { userDetails } = useCurrentUser();
  return (
    <div className="opacity-50 cursor-not-allowed border border-borderColor rounded-1.6 py-8 px-5 bg-white">
      <div className="flex items-center justify-between opacity-50">
        <div className="flex items-start">
          <div className="pl-14">
            <h2 className="text-2xl font-bold">Insurance Price Estimate</h2>
          </div>
        </div>
      </div>
      <div className="pt-1 pl-14">
        <p className="mt-2 text-2xl font-medium leading-7 text-red">
          We unfortunately do not have an in-network therapist in{" "}
          {userDetails?.state} that accepts your insurance. Please choose the
          self-pay option below to continue with your booking.
        </p>
      </div>
    </div>
  );
}

export default BHInsuranceOption;
