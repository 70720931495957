import React from "react";

import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";
import useToastify from "hooks/useToastify";
import { bh_sub_steps } from "helpers/enum";
import Button from "components/buttons/Button";
import RegularSelect from "components/radioButtonCard/RegularSelect";
import Spacer from "components/Spacer/Spacer";
import CheckBoxCard from "components/checkBoxCard/CheckBoxCard";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import SearchInputWithChipCard from "components/searchInputWithChip/SearchInputWithChipCard";
import HistoryDebounceSearchModal from "../../dermatology/medicalHistory/HistoryDebounceSearchModal";
import useModal from "hooks/useModal";

function SocialMedicalHistory({
  updateVisit,
  updatingVisit,
  setActiveIndex,
  firstAnswer,
  setFirstAnswer,
  secondAnswer,
  setSecondAnswer,
  thirdAnswer,
  setThirdAnswer,
  thirdYesAnswer,
  setThirdYesAnswer,
  fourthAnswer,
  setFourthAnswer,
  fourthYesAnswer,
  setFourthYesAnswer,
}) {
  const { showAlert } = useToastify();
  const { setShowModal } = useModal();

  const { incompleteData } = useBehaviorHealthVisitFlow();

  function isButtonValid() {
    let firstQuestionCleared = false;
    let secondQuestionCleared = false;
    let thirdQuestionCleared = false;
    let fourthQuestionCleared = false;

    if (firstAnswer.length > 0) {
      firstQuestionCleared = true;
    } else {
      firstQuestionCleared = false;
    }

    if (secondAnswer.length > 0) {
      secondQuestionCleared = true;
    } else {
      secondQuestionCleared = false;
    }

    if (thirdAnswer === "No") {
      thirdQuestionCleared = true;
    } else {
      if (thirdYesAnswer?.length > 0) {
        thirdQuestionCleared = true;
      } else {
        thirdQuestionCleared = false;
      }
    }

    if (fourthAnswer === "None") {
      fourthQuestionCleared = true;
    } else {
      if (fourthYesAnswer?.length > 0) {
        fourthQuestionCleared = true;
      } else {
        fourthQuestionCleared = false;
      }
    }

    if (
      firstQuestionCleared &&
      secondQuestionCleared &&
      thirdQuestionCleared &&
      fourthQuestionCleared
    ) {
      return true;
    } else {
      return false;
    }
  }

  function onRightButtonClick() {
    const refinedFourthAnswer = [];

    fourthYesAnswer.forEach((el) => {
      let existingIndex = refinedFourthAnswer?.findIndex(
        (answer) => answer.category === el.category
      );
      if (existingIndex > -1) {
        refinedFourthAnswer[existingIndex].diseases.push(el?.disease);
      } else {
        refinedFourthAnswer.push({
          category: el.category,
          diseases: [el?.disease],
        });
      }
    });

    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          behavior_health_medical_history: [
            {
              health_profile_section: "exercise",
              question: "Do you exercise regularly?",
              answer_type_boolean: true,
              answer: firstAnswer.toUpperCase(),
            },
            {
              health_profile_section: "alcohol",
              question: "Do you drink alcohol?",
              answer_type_boolean: false,
              answer: secondAnswer,
            },
            {
              health_profile_section: "tobacco",
              question: "Do you smoke?",
              answer_type_boolean: true,
              answer: thirdAnswer.toUpperCase(),
              form_of_tobacco: thirdAnswer === "Yes" ? thirdYesAnswer : [],
            },
            {
              health_profile_section: "medical_history",
              question: "Current medical conditions under treatment",
              answer_type_boolean: true,
              answer: fourthAnswer === "None" ? "NO" : "YES",
              selected_options:
                fourthAnswer === "None" ? [] : refinedFourthAnswer,
            },
          ],
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications,
          bh_second_phase_steps: {
            ...incompleteData?.bh_second_phase_steps,
            [bh_sub_steps.medical_history]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        showAlert("Progress Saved Successfully", "success");
        setActiveIndex(11);
      },
    });
  }

  function handleOnSelectThirdYesAnswer(el) {
    let final = [...thirdYesAnswer];
    if (final?.includes(el)) {
      setThirdYesAnswer(final.filter((elem) => elem !== el));
    } else {
      setThirdYesAnswer([...final, el]);
    }
  }

  return (
    <div className="bg-white shadow-card rounded-b-4xl px-10 pb-10 relative overflow-x-hidden">
      <RegularSelect
        label="Do you exercise regularly?"
        options={["Yes", "No"]}
        selected={firstAnswer}
        setSelected={(el) => setFirstAnswer(el)}
        orange={true}
      />

      <Spacer height={20} />

      <RegularSelect
        label="Do you drink alcohol?"
        options={["Never", "Occasionally", "Daily", "Previously/Quit"]}
        selected={secondAnswer}
        setSelected={(el) => setSecondAnswer(el)}
        orange={true}
        mobile={true}
      />

      <Spacer height={20} />

      <RegularSelect
        label="Do you smoke?"
        options={["Yes", "No"]}
        selected={thirdAnswer}
        setSelected={(el) => setThirdAnswer(el)}
        orange={true}
      />

      {thirdAnswer === "Yes" ? (
        <div>
          <Spacer height={20} />
          <CheckBoxCard
            options={[
              "Cigarettes",
              "Vape",
              "Cannabis",
              "Chew",
              "Cigar",
              "Pipe",
            ]}
            selected={thirdYesAnswer}
            onSelect={handleOnSelectThirdYesAnswer}
            variant="small"
          />
        </div>
      ) : null}

      <Spacer height={20} />

      <p className="font-medium text-2xl">
        Please add any medical conditions for which you are under treatment:
      </p>
      <Spacer height={20} />
      <SearchInputWithChipCard
        inputLabel={"Search Medical Conditions"}
        onClickInput={() => {
          setFourthAnswer("Yes");
          setShowModal({
            state: true,
            children: (_, onClose) => {
              return (
                <HistoryDebounceSearchModal
                  keyToSearch={"medical-history-options"}
                  title={"Search Medical Conditions"}
                  onClose={onClose}
                  selected={fourthYesAnswer}
                  setSelected={setFourthYesAnswer}
                />
              );
            },
            data: null,
          });
        }}
        selected={fourthYesAnswer}
        setSelected={setFourthYesAnswer}
      />
      <Spacer height={20} />
      <RadioButtonCard
        loading={updatingVisit}
        options={["None"]}
        selected={fourthAnswer}
        onSelect={(_) => {
          if (fourthAnswer === "None") {
            setFourthAnswer("Yes");
          } else {
            setFourthAnswer("None");
            setFourthYesAnswer([]);
          }
        }}
      />

      <Button
        className="mt-10 py-4"
        disabled={!isButtonValid()}
        loading={updatingVisit}
        label="Next"
        onClick={onRightButtonClick}
      />
    </div>
  );
}

export default SocialMedicalHistory;
