import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import InsuranceAndProviderBlock from "../medicalIntake/InsuranceAndProviderBlock";
import InsuranceDetails from "../medicalIntake/insuranceDetails/InsuranceDetails";
import IsMedicalInsuranceAvailable from "../medicalIntake/IsMedicalInsuranceAvailable/IsMedicalInsuranceAvailable";
import MedicalIntakeRouteGuard from "../../../../routes/MedicalIntakeRouteGuard";
import SelectedProviders from "../medicalIntake/selectedProviders/SelectedProviders";
import ProtectVisitRoute from "./common/ProtectVisitRoute";
import VisitSubmitted from "./common/VisitSubmitted";
import DermatologyCheckout from "./dermatology/checkout/DermatologyCheckout";
import DermatologyVisitTerms from "./dermatology/DermatologyVisitTerms";
import DermaVisitSteps from "./dermatology/DermaVisitSteps";
import DermatologyVisitPricing from "./dermatology/pricing/DermatologyVisitPricing";
import DermAcceptRevisedQuote from "./dermatology/DermAcceptRevisedQuote";
import PrimaryCareCheckout from "./primaryCare/checkout/PrimaryCareCheckout";
import PrimaryCareVisitPricing from "./primaryCare/pricing/PrimaryCareVisitPricing";
import PrimaryCareVisitSteps from "./primaryCare/PrimaryCareVisitSteps";
import PrimaryCareVisitTerms from "./primaryCare/PrimaryCareVisitTerms";
import AppointmentConfirmation from "./primaryCare/AppointmentConfirmation";
import SelectVisitType from "./SelectVisitType";
import DermaSubStepScreen from "./subStepScreen/DermaSubStepScreen";
import PrimaryCareSubStepScreen from "./subStepScreen/PrimaryCareSubStepScreen";
import PrimaryCareCompleteVisitDetails from "./primaryCare/PrimaryCareCompleteVisitDetails";
import AcceptRevisedQuote from "./primaryCare/phaseTwoSections/AcceptRevisedQuote";
import PedVisitSteps from "./ped/PedVisitSteps";
import PedSubStepScreen from "./subStepScreen/PedSubStepsScreen";
import PedVisitPricing from "./ped/pricing/PedVisitPricing";
import PedAppointmentConfirmation from "./ped/AppointmentConfirmation";
import PedCompleteVisitDetails from "./ped/PedCompleteVisitDetails";
import PedAcceptRevisedQuote from "./ped/phaseTwoSections/AcceptRevisedQuote";
import PedVisitTerms from "./ped/PedVisitTerms";
import BehaviorVisitSteps from "./behaviorHealth/BehaviorHealthVisitSteps";
import BehaviorSubStepsScreen from "./subStepScreen/BehaviorHealthSubStepScreen";
import BehaviorVisitPricing from "./behaviorHealth/pricing/BehaviorHealthVisitPricing";
import BehaviorAppointmentConfirmation from "./behaviorHealth/AppointmentConfirmation";
import BehaviorHealthCompleteVisitDetails from "./behaviorHealth/BehaviorHealthCompleteVisitDetails";
import BehaviorAcceptRevisedQuote from "./behaviorHealth/phaseTwoSections/AcceptRevisedQuote";
import BehaviorVisitTerms from "./behaviorHealth/BehaviorHealthVisitTerms";

function VisitFlowRoute() {
  let { url } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={url} to={`${url}/type`} />
      <Route path={`${url}/type`}>
        <SelectVisitType />
      </Route>

      {/* upload inurance and select provider */}
      <MedicalIntakeRouteGuard exact path={`${url}/medical-intake`}>
        <InsuranceAndProviderBlock />
      </MedicalIntakeRouteGuard>
      <MedicalIntakeRouteGuard
        path={`${url}/medical-intake/is-insurance-available`}
      >
        <IsMedicalInsuranceAvailable />
      </MedicalIntakeRouteGuard>
      <MedicalIntakeRouteGuard path={`${url}/medical-intake/insurance-details`}>
        <InsuranceDetails />
      </MedicalIntakeRouteGuard>
      <MedicalIntakeRouteGuard path={`${url}/medical-intake/select-provider`}>
        <SelectedProviders />
      </MedicalIntakeRouteGuard>

      {/* visit flow  */}
      <ProtectVisitRoute exact path={`${url}/dermatology`}>
        <DermaVisitSteps />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/dermatology/new-visit`}>
        <DermaSubStepScreen />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/dermatology/pricing`}>
        <DermatologyVisitPricing />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/dermatology/checkout`}>
        <DermatologyCheckout />
      </ProtectVisitRoute>
      <Route exact path={`${url}/dermatology/accept-price-revision`}>
        <DermAcceptRevisedQuote />
      </Route>
      <ProtectVisitRoute exact path={`${url}/primary-care`}>
        <PrimaryCareVisitSteps />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/primary-care/new-visit`}>
        <PrimaryCareSubStepScreen />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/primary-care/pricing`}>
        <PrimaryCareVisitPricing />
      </ProtectVisitRoute>
      <ProtectVisitRoute
        exact
        path={`${url}/primary-care/appointment-confirmation`}
      >
        <AppointmentConfirmation />
      </ProtectVisitRoute>
      <ProtectVisitRoute
        exact
        path={`${url}/primary-care/complete-visit-details`}
      >
        <PrimaryCareCompleteVisitDetails />
      </ProtectVisitRoute>
      <Route exact path={`${url}/primary-care/accept-price-revision`}>
        <AcceptRevisedQuote />
      </Route>
      <ProtectVisitRoute
        exact
        path={`${url}/primary-care/terms_and_conditions`}
      >
        <PrimaryCareVisitTerms />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/pediatrics`}>
        <PedVisitSteps />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/pediatrics/new-visit`}>
        <PedSubStepScreen />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/pediatrics/pricing`}>
        <PedVisitPricing />
      </ProtectVisitRoute>
      <ProtectVisitRoute
        exact
        path={`${url}/pediatrics/appointment-confirmation`}
      >
        <PedAppointmentConfirmation />
      </ProtectVisitRoute>
      <ProtectVisitRoute
        exact
        path={`${url}/pediatrics/complete-visit-details`}
      >
        <PedCompleteVisitDetails />
      </ProtectVisitRoute>
      <Route exact path={`${url}/pediatrics/accept-price-revision`}>
        <PedAcceptRevisedQuote />
      </Route>
      <ProtectVisitRoute exact path={`${url}/pediatrics/terms_and_conditions`}>
        <PedVisitTerms />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/behavior-health`}>
        <BehaviorVisitSteps />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/behavior-health/new-visit`}>
        <BehaviorSubStepsScreen />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/behavior-health/pricing`}>
        <BehaviorVisitPricing />
      </ProtectVisitRoute>
      <ProtectVisitRoute
        exact
        path={`${url}/behavior-health/appointment-confirmation`}
      >
        <BehaviorAppointmentConfirmation />
      </ProtectVisitRoute>
      <ProtectVisitRoute
        exact
        path={`${url}/behavior-health/complete-visit-details`}
      >
        <BehaviorHealthCompleteVisitDetails />
      </ProtectVisitRoute>
      <Route exact path={`${url}/behavior-health/accept-price-revision`}>
        <BehaviorAcceptRevisedQuote />
      </Route>
      <ProtectVisitRoute
        exact
        path={`${url}/behavior-health/terms_and_conditions`}
      >
        <BehaviorVisitTerms />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/dermatology/terms_and_conditions`}>
        <DermatologyVisitTerms />
      </ProtectVisitRoute>
      <ProtectVisitRoute exact path={`${url}/primary-care/checkout`}>
        <PrimaryCareCheckout />
      </ProtectVisitRoute>
      <Route exact path={`${url}/submitted`}>
        <VisitSubmitted />
      </Route>
      <Redirect to="/404" />
    </Switch>
  );
}

export default VisitFlowRoute;
