import React, { useEffect, useRef, useState } from "react";
import Images from "assets/images";
import Button from "components/buttons/Button";
import useToastify from "hooks/useToastify";
import { useApiMutation } from "hooks/useApiMutation";
import { getVisitTerms } from "api/actions/VisitActions";
import CSSLoader from "components/cssLoader/CSSLoader";
import DOMPurify from "dompurify";

function CodeOfConduct({
  enabled,
  incompleteData,
  userDetails,
  termsAccepted,
  setActiveIndex,
  updateVisit,
  updatingVisit,
}) {
  const firstRef = useRef(true);
  const [terms, setTerms] = useState([]);
  const [selected, setSelected] = useState(
    termsAccepted ? Array.from({ length: terms?.length }, (_, i) => i) : []
  );
  const { showAlert } = useToastify();
  const { mutate: fetchTerms, loading: fetchingTerms } = useApiMutation(
    getVisitTerms
  );

  useEffect(() => {
    const init = async () => {
      if (firstRef.current && enabled) {
        if (incompleteData?.accepted_tnc) {
          setTerms((prev) => [...prev, ...incompleteData?.code_of_conduct]);
          firstRef.current = false;
        } else {
          const result = await fetchTerms("pediatric");

          setTerms((prev) => [...prev, ...result?.payload]);
          firstRef.current = false;
        }
      }
    };

    init();
  }, [fetchTerms, incompleteData, enabled]);

  useEffect(() => {
    setSelected(
      termsAccepted ? Array.from({ length: terms?.length }, (_, i) => i) : []
    );
  }, [terms, termsAccepted]);

  function onSelect(index) {
    if (selected?.includes(index)) {
      setSelected((prev) => prev?.filter((el) => el !== index));
    } else {
      setSelected((prev) => [...prev, index]);
    }
  }

  const acceptTerms = () => {
    updateVisit({
      payload: {
        case: {
          accepted_tnc: true,
          allergies: incompleteData?.allergies_json,
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications,
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        showAlert("Terms Accepted", "success");
        setActiveIndex(userDetails?.gender === "female" ? 12 : 11);
      },
    });
  };

  return (
    <div className="bg-white rounded-b-4xl px-10 pb-10">
      <div>
        {fetchingTerms ? (
          <div className="flex-1 py-10 flex justify-center items-center">
            <CSSLoader className="w-16 h-16" color="orange" />
          </div>
        ) : (
          terms?.map((term, index) => {
            return (
              <div className="py-5" key={index}>
                <div
                  onClick={() => onSelect(index)}
                  className="flex cursor-pointer"
                >
                  <img
                    className="w-12 h-12"
                    src={
                      selected?.includes(index)
                        ? Images.checkedOrange
                        : Images.uncheckedCircle
                    }
                    alt="checked"
                  />
                  <p
                    className="pl-5 text-2xl leading-8 font-light pt-1"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(term),
                    }}
                  />
                </div>
              </div>
            );
          })
        )}
        <Button
          disabled={selected.length !== terms.length || termsAccepted}
          loading={updatingVisit}
          className="mt-5 py-4"
          label="Next"
          onClick={acceptTerms}
        />
      </div>
    </div>
  );
}

export default CodeOfConduct;
