import React, { useCallback, useEffect, useState } from "react";

import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { useCurrentUser } from "hooks/useCurrentUser";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useDispatch } from "react-redux";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";
import usePatients from "hooks/visit/usePatients";
import { useHistory } from "react-router-dom";
import { bh_sub_steps } from "helpers/enum";
import useCreateBehaviorHealthVisit from "hooks/visit/useCreateBehaviorHealthVisit";
import useUpdateBehaviorHealthVisit from "hooks/visit/useUpdateBehaviorHealthVisit";
import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";

const options = ["Me", "My Child", "Someone else"];

function SelectPatientForBehaviorHealth() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const { userDetails } = useCurrentUser();
  const { getAccountHolder } = usePatients();
  const {
    incompleteData,
    patientsList,
    subSteps,
  } = useBehaviorHealthVisitFlow();
  const currentScreenValueFromStore =
    subSteps[bh_sub_steps.select_patient].value;

  const { createNewVisit, creatingVisit } = useCreateBehaviorHealthVisit();
  const { updateVisit } = useUpdateBehaviorHealthVisit();

  const [selected, setSelected] = useState(null);

  const getNextScreen = useCallback(() => {
    if (selected === options?.[0]) {
      return bh_sub_steps.select_therapy;
    } else if (selected === options?.[1]) {
      return bh_sub_steps.select_child;
    } else {
      return bh_sub_steps.select_someone_else;
    }
  }, [selected]);

  useEffect(() => {
    if (currentScreenValueFromStore) {
      setSelected(currentScreenValueFromStore);
    }
  }, [currentScreenValueFromStore, patientsList]);

  useEffect(() => {
    if (selected?.length) {
      dispatch(
        behaviorHealthVisitFlowReducers.select_patient({
          isValid: true,
          nextScreen: getNextScreen(),
        })
      );
    } else {
      dispatch(
        behaviorHealthVisitFlowReducers.select_patient({
          isValid: false,
        })
      );
    }
  }, [dispatch, selected, getNextScreen]);

  function onRightButtonClick(_, nextAnimation) {
    if (selected === options?.[0]) {
      if (incompleteData && Object.keys(incompleteData)?.length) {
        updateVisit({
          payload: {
            case: {
              id: incompleteData?.id,
              patient_id: getAccountHolder(patientsList)?.id,
              behavior_health_steps: incompleteData?.behavior_health_steps,
            },
          },
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            dispatch(
              behaviorHealthVisitFlowReducers.select_patient({
                value: "Me",
              })
            );
            history.push("/visit/behavior-health/pricing");
          },
        });
      } else {
        createNewVisit({
          patient_id: getAccountHolder(patientsList)?.id,
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            dispatch(
              behaviorHealthVisitFlowReducers.select_patient({
                value: "Me",
              })
            );
            history.push("/visit/behavior-health/pricing");
          },
        });
      }
    } else {
      dispatch(
        behaviorHealthVisitFlowReducers.select_patient({
          value: selected,
        })
      );
      nextAnimation();
      setTimeout(() => {
        dispatch(
          behaviorHealthVisitFlowReducers.currentScreen({
            value: getNextScreen(),
          })
        );
      }, 300);
    }
  }

  return (
    <StepScreenContainer align={"start"}>
      <MedicalAssistant
        content={
          <p>
            Hi{userDetails?.first_name ? `, ${userDetails?.first_name}` : " "}!
            Let's get started. Please tell me who the patient is?
          </p>
        }
      />
      <Spacer height={40} />
      <Card>
        <RadioButtonCard
          loading={creatingVisit}
          options={options}
          onSelect={setSelected}
          selected={selected}
        />
      </Card>
    </StepScreenContainer>
  );
}

export default SelectPatientForBehaviorHealth;
