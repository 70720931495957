import React, { useEffect, useState } from "react";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import Spacer from "components/Spacer/Spacer";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";
import usePatients from "hooks/visit/usePatients";
import useVisitStepChange from "hooks/useVisitStepChange";
import { bh_main_steps, bh_sub_steps } from "helpers/enum";
import useImageUpload from "hooks/visit/useImageUpload";
import { checkAlreadyCompleted } from "helpers/setBHStepStatus";
import { useDispatch } from "react-redux";
import useUpdateBehaviorHealthVisit from "hooks/visit/useUpdateBehaviorHealthVisit";
import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";
import ConfirmIdentityImagePicker from "components/confirmIdentityImagePicker/ConfirmIdentityImagePicker";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";

function IdentityConfirmationForBehaviorHealth() {
  const [imageData, setImageData] = useState(null);
  const dispatch = useDispatch();
  const { uploadImage } = useImageUpload();
  const { updateVisit } = useUpdateBehaviorHealthVisit();
  const {
    patientsList,
    incompleteData,
    subSteps,
  } = useBehaviorHealthVisitFlow();
  const { getCurrentPatient } = usePatients();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );
  const currentScreenValue =
    subSteps?.[bh_sub_steps.identity_confirmation]?.value;
  useEffect(() => {
    if (currentScreenValue && currentScreenValue?.uri?.length) {
      setImageData(currentScreenValue);
    } else {
      if (currentPatient) {
        if (currentPatient?.insurance?.id_proof?.length) {
          setImageData({
            uri: currentPatient?.insurance?.id_proof,
            file: null,
            data: null,
          });
        }
      }
    }
  }, [currentPatient, currentScreenValue]);

  useEffect(() => {
    if (imageData?.uri?.length) {
      dispatch(
        behaviorHealthVisitFlowReducers.identity_confirmation({
          isValid: true,
        })
      );
    } else {
      dispatch(
        behaviorHealthVisitFlowReducers.identity_confirmation({
          isValid: false,
        })
      );
    }
  }, [dispatch, imageData]);

  function onRightButtonClick(_, nextAnimation) {
    if (!imageData?.uri?.startsWith("https")) {
      uploadImage(imageData);
    }
    updateVisit({
      payload: {
        case: {
          behavior_health_steps: {
            main_steps: {
              ...incompleteData?.behavior_health_steps?.main_steps,
              [bh_main_steps.basic_info]: "completed",
              [bh_main_steps.additional_details]: checkAlreadyCompleted({
                screen_name: bh_main_steps.additional_details,
                behavior_health_steps: incompleteData?.behavior_health_steps,
                step_placement: "main_steps",
              }),
            },
            sub_steps: {
              ...incompleteData?.behavior_health_steps?.sub_steps,
            },
          },
        },
      },
      change_current_screen: true,
      screen_to_complete: bh_sub_steps.identity_confirmation,
      callBack: () => {
        dispatch(
          behaviorHealthVisitFlowReducers.identity_confirmation({
            value: imageData,
          })
        );
        nextAnimation();
      },
    });
  }

  function handleImageChange(event) {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      let reader = new FileReader();
      reader.onload = (event) => {
        let file_object;
        file_object = {
          uri: files?.[0]?.name?.toString(),
          file: files[0],
          filename: files?.[0]?.name?.toString(),
          data: event?.target?.result,
        };
        setImageData(file_object);
      };
      reader.readAsDataURL(files[0]);
    }
  }
  return (
    <StepScreenContainer align="start">
      <MedicalAssistant
        content={
          <p>
            Thank you. It is also important to confirm your identity before we
            evaluate and treat you. Please upload a picture of your photo ID
            below (e.g., Driver's license, Passport, etc.) [Accepted image
            formats: PNG, JPG, JPEG].
            <br />
            <br />
            If the patient is a minor please upload the guardians photo ID.
          </p>
        }
      />
      <Spacer height={40} />
      <div className="flex justify-evenly">
        <div>
          <ConfirmIdentityImagePicker
            handleChange={handleImageChange}
            imageUrl={
              imageData
                ? imageData?.uri?.startsWith("http")
                  ? imageData?.uri
                  : imageData?.data
                : ""
            }
          />
        </div>
      </div>
    </StepScreenContainer>
  );
}

export default IdentityConfirmationForBehaviorHealth;
