import React from "react";
import Images from "assets/images";
import useModal from "hooks/useModal";
import CaseImageUploadInfo from "components/imageUploadInfoModal/CaseImageUploadInfo";

function CasePhotosImagePickerTile({
  imageUrl,
  handleChange,
  keyType,
  allowDelete = false,
  handleDelete = () => null,
}) {
  const { setShowModal } = useModal();

  return (
    <div
      onClick={() => {
        setShowModal({
          state: true,
          children: (_, onClose) => (
            <CaseImageUploadInfo
              title={
                keyType === "close_up" ? "Close Up Photo" : "Far Away Photo"
              }
              image={
                keyType === "close_up"
                  ? Images.caseCloseUpPhoto
                  : Images.caseFarAwayPhoto
              }
              info={
                keyType === "close_up"
                  ? "Make sure to take a photo from about 6 inches away [Accepted image formats: PNG, JPG, JPEG]."
                  : "Make sure to take a photo from about 1 feet away [Accepted image formats: PNG, JPG, JPEG]."
              }
              onClose={onClose}
              handleChange={handleChange}
            />
          ),
        });
      }}
      className="relative w-40 h-40 md:w-56 md:h-56 bg-white rounded-3xl border-2 border-orange flex items-center justify-center cursor-pointer overflow-hidden"
    >
      {imageUrl?.length ? (
        <>
          <img
            className="w-40 h-40 md:w-56 md:h-56 rounded-3xl object-contain"
            src={imageUrl}
            alt="case_image"
          />
          <div className="absolute w-full h-full bg-black bg-opacity-30" />
        </>
      ) : null}
      {imageUrl?.length ? null : (
        <img
          className="w-16 h-16 absolute"
          src={Images.addIcon}
          alt="add_image"
        />
      )}
      {imageUrl?.length ? (
        allowDelete ? (
          <div className="absolute flex items-center space-x-3">
            <img
              className="w-16 h-16 rounded-full shadow-2xl"
              src={Images.editIcon}
              alt="add_image"
            />
            <img
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
              className="w-16 h-16 rounded-full shadow-2xl"
              src={Images.deleteIcon}
              alt="delete_image"
            />
          </div>
        ) : (
          <img
            className="w-16 h-16 rounded-full absolute shadow-2xl"
            src={Images.editIcon}
            alt="add_image"
          />
        )
      ) : null}
    </div>
  );
}

export default CasePhotosImagePickerTile;
