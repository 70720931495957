import { bh_main_steps, bh_sub_steps, step_status } from "helpers/enum";
import { setNextScreenToUpcoming } from "helpers/setBHStepStatus";

export const behaviorHealthAdditionalDetailsReducer = {
  select_therapy_reasons(state, action) {
    const { value, isValid, status } = action.payload;
    if (value) {
      state.subSteps[bh_sub_steps.select_therapy_reasons].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[bh_sub_steps.select_therapy_reasons].isValid = isValid;
    }
    if (status) {
      state.subSteps[bh_sub_steps.select_therapy_reasons].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: bh_sub_steps.symptoms,
        });
      }
    }
  },
  symptoms(state, action) {
    const { status, value, isValid } = action.payload;
    if (status) {
      state.subSteps[bh_sub_steps.symptoms].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: bh_sub_steps.additional_info,
        });
      }
    }
    if (value) {
      state.subSteps[bh_sub_steps.symptoms].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[bh_sub_steps.symptoms].isValid = isValid;
    }
  },
  additional_info(state, action) {
    const { status, value, isValid, recordUrl } = action.payload;
    if (status) {
      state.subSteps[bh_sub_steps.additional_info].status = status;
      if (status === step_status.completed) {
        state.mainSteps[bh_main_steps.additional_details].status =
          step_status.completed;
      }
    }
    if (value) {
      state.subSteps[bh_sub_steps.additional_info].value = value;
    }
    if (recordUrl !== undefined) {
      state.subSteps[bh_sub_steps.additional_info].recordUrl = recordUrl;
    }
    if (isValid !== undefined) {
      state.subSteps[bh_sub_steps.additional_info].isValid = isValid;
    }
  },
};
