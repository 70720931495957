import React, { useEffect, useState } from "react";
import CheckBoxCard from "components/checkBoxCard/CheckBoxCard";
import { bh_sub_steps } from "helpers/enum";
import useToastify from "hooks/useToastify";
import Button from "components/buttons/Button";
import RegularSelect from "components/radioButtonCard/RegularSelect";
import Spacer from "components/Spacer/Spacer";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";

const ConsultationHistory = ({
  incompleteData,
  updateVisit,
  updatingVisit,
  setActiveIndex,
}) => {
  const { showAlert } = useToastify();

  const [firstQuestion, setFirstQuestion] = useState("No");
  const [firstAnswer, setFirstAnswer] = useState("");

  const [secondQuestion, setSecondQuestion] = useState("No");
  const [secondAnswer, setSecondAnswer] = useState([]);
  const [secondOtherAnswer, setSecondOtherAnswer] = useState("");

  const [thirdAnswer, setThirdAnswer] = useState("");

  const secondQuestionOptions = [
    "Recent or past hospitalizations for psychopathy, hallucinations, delusions, substance abuse",
    "Recent inpatient treatment for active suicidality, self harm, or assault committed to others",
    "Recent inpatient treatment for severe substance abuse of any kind - especially opioid, fentanyl, alcohol",
    "Recent arrest or hospitalization for mental health concern",
    "Other",
  ];

  const thirdQuestionOptions = [
    "Never",
    "Over a year ago",
    "Over the last several months",
    "This week",
    "Currently",
  ];

  useEffect(() => {
    incompleteData?.behavior_health_consultation_history?.forEach((el) => {
      if (el?.question === "Have you seen a therapist before?") {
        setFirstQuestion(
          el?.answer
            ?.split(" ")
            ?.map((i) => i[0].toUpperCase() + i.substring(1).toLowerCase())
            .join(" ")
        );
        if (el?.reason?.length > 0) {
          setFirstAnswer(el?.reason[0]);
        }
      }
      if (
        el?.question ===
        "Have you been hospitalized for mental health treatment before? Why?"
      ) {
        setSecondQuestion(
          el?.answer
            ?.split(" ")
            ?.map((i) => i[0].toUpperCase() + i.substring(1).toLowerCase())
            .join(" ")
        );
        if (el?.reason?.length > 0) {
          let otherAnswer = el?.reason?.find(
            (el) => !secondQuestionOptions.includes(el)
          );
          setSecondOtherAnswer(otherAnswer);
          setSecondAnswer(
            el?.reason?.map((el) => {
              if (el === otherAnswer) {
                return "Other";
              }
              return el;
            })
          );
        }
      }
      if (
        el?.question === "When is the last time you had thoughts of Suicide?"
      ) {
        setThirdAnswer(el?.answer);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incompleteData?.behavior_health_consultation_history]);

  function isButtonValid() {
    let firstQuestionCleared = false;
    let secondQuestionCleared = false;
    let thirdQuestionCleared = false;

    if (firstQuestion === "No") {
      firstQuestionCleared = true;
    } else {
      if (firstAnswer.length > 0) {
        firstQuestionCleared = true;
      } else {
        firstQuestionCleared = false;
      }
    }

    if (secondQuestion === "No") {
      secondQuestionCleared = true;
    } else {
      if (secondAnswer?.length > 0) {
        if (secondAnswer?.includes("Other")) {
          if (secondOtherAnswer?.length === 0) {
            secondQuestionCleared = false;
          } else {
            secondQuestionCleared = true;
          }
        } else {
          secondQuestionCleared = true;
        }
      } else {
        secondQuestionCleared = false;
      }
    }

    if (thirdAnswer?.length > 0) {
      thirdQuestionCleared = true;
    } else {
      thirdQuestionCleared = false;
    }

    if (firstQuestionCleared && secondQuestionCleared && thirdQuestionCleared) {
      return true;
    } else {
      return false;
    }
  }

  function handleOnSelectSecondQuestion(el) {
    let final = [...secondAnswer];
    if (final?.includes(el)) {
      setSecondAnswer(final.filter((elem) => elem !== el));
    } else {
      setSecondAnswer([...final, el]);
    }
  }

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications,
          behavior_health_consultation_history: [
            {
              question: "Have you seen a therapist before?",
              answer_type_boolean: true,
              answer: firstQuestion.toUpperCase(),
              reason: [firstQuestion === "Yes" ? firstAnswer : ""],
            },
            {
              question:
                "Have you been hospitalized for mental health treatment before? Why?",
              answer_type_boolean: true,
              answer: secondQuestion.toUpperCase(),
              reason:
                secondQuestion === "Yes"
                  ? secondAnswer?.includes("Other")
                    ? [
                        ...secondAnswer?.filter((el) => el !== "Other"),
                        secondOtherAnswer,
                      ]
                    : secondAnswer
                  : [],
            },
            {
              question: "When is the last time you had thoughts of Suicide?",
              answer_type_boolean: false,
              answer: thirdAnswer,
            },
          ],
          bh_second_phase_steps: {
            ...incompleteData?.bh_second_phase_steps,
            [bh_sub_steps.consultation_history]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        showAlert("Progress Saved Successfully", "success");
        setActiveIndex(10);
      },
    });
  }

  return (
    <div className="bg-white shadow-card rounded-b-4xl px-10 pb-10 relative overflow-x-hidden">
      <RegularSelect
        label="Have you seen a therapist before?"
        options={["Yes", "No"]}
        selected={firstQuestion}
        setSelected={(el) => setFirstQuestion(el)}
        orange={true}
      />
      {firstQuestion === "Yes" ? (
        <div className="flex flex-col">
          <Spacer height={20} />
          <label className="font-bold text-2xl text-indigo text-opacity-50">
            Reason for treatment?
          </label>
          <Spacer height={8} />
          <div className="rounded-lg h-52 bg-white border-solid border-2 w-full border-borderColor focus:border-indigo">
            <textarea
              className="p-6 h-full bg-transparent font-medium text-xl text-indigo w-full outline-none placeholder-cloudyBlue resize-none"
              value={firstAnswer}
              onChange={(e) => setFirstAnswer(e.target.value)}
            />
          </div>
        </div>
      ) : null}
      <Spacer height={20} />
      <RegularSelect
        label="Have you been hospitalized for mental health treatment before? Why?"
        options={["Yes", "No"]}
        selected={secondQuestion}
        setSelected={(el) => setSecondQuestion(el)}
        orange={true}
      />
      {secondQuestion === "Yes" ? (
        <div>
          <Spacer height={20} />
          <CheckBoxCard
            options={secondQuestionOptions}
            selected={secondAnswer}
            onSelect={handleOnSelectSecondQuestion}
            variant="small"
          />
          {secondAnswer?.includes("Other") ? (
            <div className="flex flex-col">
              <Spacer height={20} />
              <label className="font-bold text-2xl text-indigo text-opacity-50">
                Any additional reason?
              </label>
              <Spacer height={8} />
              <div className="rounded-lg h-52 bg-white border-solid border-2 w-full border-borderColor focus:border-indigo">
                <textarea
                  className="p-6 h-full bg-transparent font-medium text-xl text-indigo w-full outline-none placeholder-cloudyBlue resize-none"
                  value={secondOtherAnswer}
                  onChange={(e) => setSecondOtherAnswer(e.target.value)}
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      <Spacer height={20} />

      <p className="font-medium text-2xl">
        When was the last time you had thoughts of suicide?
      </p>

      <Spacer height={20} />

      <RadioButtonCard
        options={thirdQuestionOptions}
        onSelect={setThirdAnswer}
        selected={thirdAnswer}
        variant="small"
      />

      <Button
        className="mt-10 py-4"
        disabled={!isButtonValid()}
        loading={updatingVisit}
        label="Next"
        onClick={onRightButtonClick}
      />
    </div>
  );
};

export default ConsultationHistory;
