import React, { useEffect, useReducer, useState } from "react";

import Spacer from "components/Spacer/Spacer";
import useToastify from "hooks/useToastify";
import { bh_sub_steps } from "helpers/enum";
import Button from "components/buttons/Button";
import CSSLoader from "components/cssLoader/CSSLoader";
import RegularSelect from "components/radioButtonCard/RegularSelect";

function PatientQuestionnaire({
  gettingPatientQuestionnaire,
  patientQuestionnaire,
  incompleteData,
  updateVisit,
  updatingVisit,
  setActiveIndex,
}) {
  const { showAlert } = useToastify();

  const [questions, setQuestions] = useState([]);
  const [ratings, setRatings] = useState(null);

  function reducer(state, action) {
    switch (action.type) {
      case 0:
        return action.payload;
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return state?.map((question) => {
          if (question?.rank === action.type) {
            return { ...question, answer: action.payload };
          }
          return question;
        });
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    if (
      state.length === 0 &&
      questions &&
      incompleteData?.bh_questionnaire_response
    ) {
      dispatch({
        type: 0,
        payload: questions?.map((question) => {
          const answerObject = incompleteData?.bh_questionnaire_response?.find(
            (answer) => question?.question === answer?.question
          );
          const getAnswer = () => {
            if (typeof answerObject?.answer === "number") {
              if (question?.question === answerObject?.question) {
                if (question?.answer_type === "rating") {
                  return Object.keys(ratings)?.find?.(
                    (key) => ratings[key] === answerObject?.answer
                  );
                }
                return answerObject?.answer;
              }
            } else {
              if (answerObject?.answer && answerObject?.answer?.length > 0) {
                return answerObject?.answer;
              }
            }
            return "";
          };

          return {
            rank: question?.rank,
            question: question?.question,
            answer: getAnswer(),
            answer_type: question?.answer_type,
          };
        }),
      });
    }
  }, [state, questions, incompleteData?.bh_questionnaire_response, ratings]);

  useEffect(() => {
    if (patientQuestionnaire) {
      setQuestions(patientQuestionnaire?.patient_health_questionnaires);
      setRatings(patientQuestionnaire?.answer_ratings);
    }
  }, [patientQuestionnaire]);

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          medications:
            typeof incompleteData?.medications[0] === "string"
              ? incompleteData?.medications.map((item) => ({
                  name: item,
                }))
              : incompleteData?.medications,
          bh_questionnaire_response: state?.map((question) => {
            if (question?.answer_type === "text") {
              return {
                question: question?.question,
                answer_type: question?.answer_type,
                answer: question?.answer,
              };
            }
            return {
              question: question?.question,
              answer_type: question?.answer_type,
              answer: ratings[question?.answer],
            };
          }),
          bh_second_phase_steps: {
            ...incompleteData?.bh_second_phase_steps,
            [bh_sub_steps.patient_health_questionnaire]: "completed",
          },
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        showAlert("Progress Saved Successfully", "success");
        setActiveIndex(12);
      },
    });
  }
  return (
    <div className="bg-white shadow-card rounded-b-4xl px-10 pb-10 relative overflow-x-hidden">
      {gettingPatientQuestionnaire ? (
        <div className={"h-40 flex items-center justify-center"}>
          <CSSLoader className={"w-16 h-16"} color={"orange"} />
        </div>
      ) : (
        <div>
          <p className="font-medium text-2xl">
            Over the last 2 weeks, how often have you been bothered by any of
            the following problems? Please select the applicable answer.
          </p>

          <Spacer height={20} />

          {state?.map((question) => (
            <div key={question?.rank}>
              {question?.answer_type === "rating" ? (
                <RegularSelect
                  label={`${question?.rank}. ${question?.question}`}
                  options={Object.keys(ratings)}
                  selected={question?.answer}
                  setSelected={(el) =>
                    dispatch({ type: question?.rank, payload: el })
                  }
                  orange={true}
                  mobile={true}
                />
              ) : (
                <RegularSelect
                  label={`${question?.rank}. ${question?.question}`}
                  options={[
                    "Not difficult at all",
                    "Somewhat difficult",
                    "Very difficult",
                    "Extremely difficult",
                  ]}
                  selected={question?.answer}
                  setSelected={(el) =>
                    dispatch({ type: question?.rank, payload: el })
                  }
                  orange={true}
                  mobile={true}
                />
              )}
              <Spacer height={20} />
            </div>
          ))}
        </div>
      )}
      <Button
        className="mt-10 py-4"
        disabled={state?.some((question) => question?.answer?.length === 0)}
        loading={updatingVisit}
        label="Next"
        onClick={onRightButtonClick}
      />
    </div>
  );
}

export default PatientQuestionnaire;
