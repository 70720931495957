import { createSlice } from "@reduxjs/toolkit";
import { behaviorHealthVisitFlowInitialState } from "./behaviorHealthVisitFlowInitialState";
import { behaviorHealthAdditionalDetailsReducer } from "./steps/behaviorHealthAdditionalDetailsReducer";
import { behaviorHealthBasicInfoReducer } from "./steps/behaviorHealthBasicInfoReducer";
import { behaviorHealthMainStepsReducer } from "./steps/behaviorHealthMainStepsReducer";
import { behaviorHealthSelectSlotReducer } from "./steps/behaviorHealthSelectSlotReducer";
import { behaviorHealthVisitDetailsReducer } from "./steps/behaviorHealthVisitDetailsReducer";

const initialState = behaviorHealthVisitFlowInitialState;

const behaviorHealthVisitFlowSlice = createSlice({
  name: "behaviorHealthVisitFlow",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    currentScreen(state, action) {
      const { value } = action.payload;
      state["currentScreen"] = value;
    },
    incompleteData(state, action) {
      const { value } = action.payload;
      state["incompleteData"] = value;
    },
    patientsList(state, action) {
      const { value } = action.payload;
      state["patientsList"] = value;
    },
    completedProgress(state, action) {
      const { value } = action.payload;
      if (value && value > state.completedProgress) {
        state["completedProgress"] = value;
      }
    },
    setPaymentMethod(state, action) {
      const { value } = action.payload;
      if (value) {
        state["paymentMethod"] = value;
      }
    },
    ...behaviorHealthMainStepsReducer,
    ...behaviorHealthBasicInfoReducer,
    ...behaviorHealthVisitDetailsReducer,
    ...behaviorHealthSelectSlotReducer,
    ...behaviorHealthAdditionalDetailsReducer,
  },
});

export const behaviorHealthVisitFlowReducers =
  behaviorHealthVisitFlowSlice.actions;

export default behaviorHealthVisitFlowSlice.reducer;
