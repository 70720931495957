import React, { useEffect, useState } from "react";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import usePatients from "hooks/visit/usePatients";
import useVisitStepChange from "hooks/useVisitStepChange";
import { dermatology_main_steps, dermatology_sub_steps } from "helpers/enum";
import useImageUpload from "hooks/visit/useImageUpload";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import { checkAlreadyCompleted } from "helpers/setDermatologyStepStatus";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import ConfirmIdentityImagePicker from "components/confirmIdentityImagePicker/ConfirmIdentityImagePicker";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { trackEvent } from "helpers/analytics";

import { useParameterizedQuery } from "react-fetching-library";
import {
  imageUploadErrorReport,
  successImageUploadReport,
} from "api/actions/ImageUploadActions";

function IdentityConfirmationForDerm() {
  const [imageData, setImageData] = useState(null);

  const dispatch = useDispatch();
  const { uploadImage } = useImageUpload();
  const { updateVisit } = useUpdateDermatologyVisit();
  const { patientsList, incompleteData, subSteps } = useDermatologyVisitFlow();
  const { getCurrentPatient } = usePatients();
  const { setLoading, rightButtonClickRef } = useVisitStepChange();

  const { query: successImageTracker } = useParameterizedQuery(
    successImageUploadReport
  );
  const { query: reportImageUploadFailure } = useParameterizedQuery(
    imageUploadErrorReport
  );
  rightButtonClickRef.current = onRightButtonClick;
  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );
  const currentScreenValue =
    subSteps?.[dermatology_sub_steps.identity_confirmation]?.value;

  useEffect(() => {
    if (currentScreenValue && currentScreenValue?.uri?.length) {
      setImageData(currentScreenValue);
    } else {
      if (currentPatient) {
        if (currentPatient?.insurance?.id_proof?.length) {
          setImageData({
            uri: currentPatient?.insurance?.id_proof,
            file: null,
            data: null,
          });
        }
      }
    }
  }, [currentPatient, currentScreenValue]);

  useEffect(() => {
    if (imageData?.uri?.length) {
      dispatch(
        dermaVisitFlowReducers.identity_confirmation({
          isValid: true,
        })
      );
    } else {
      dispatch(
        dermaVisitFlowReducers.identity_confirmation({
          isValid: false,
        })
      );
    }
  }, [dispatch, imageData]);

  async function onRightButtonClick(_, nextAnimation) {
    if (!imageData?.uri?.startsWith("https")) {
      setLoading(true);
      const data = await uploadImage(imageData);

      if (!data) {
        await reportImageUploadFailure({
          patientId: incompleteData?.patient_id,
          payload: {
            upload_issue: {
              case_id: incompleteData?.id,
              image_type: "identity_confirmation",
              filename: "",
              file_size: imageData?.size || 0,
              platform: "",
              s3_response: {},
            },
          },
        });
        setLoading(false);
        // showAlert("Error uploading image - Please try again", "error");
        updateVisit({
          payload: {
            case: {
              dermatology_steps: {
                main_steps: {
                  ...incompleteData?.dermatology_steps?.main_steps,
                  [dermatology_main_steps.basic_info]: "completed",
                  [dermatology_main_steps.visit_details]: checkAlreadyCompleted(
                    {
                      screen_name: dermatology_main_steps.visit_details,
                      dermatology_steps: incompleteData?.dermatology_steps,
                      step_placement: "main_steps",
                    }
                  ),
                },
                sub_steps: {
                  ...incompleteData?.dermatology_steps?.sub_steps,
                },
              },
            },
          },
          change_current_screen: true,
          screen_to_complete: dermatology_sub_steps.identity_confirmation,
          callBack: () => {
            dispatch(
              dermaVisitFlowReducers.identity_confirmation({
                value: imageData,
              })
            );
            nextAnimation();
          },
        });
      } else {
        await successImageTracker({
          id: incompleteData?.id,
          payload: {
            uploaded_image: "identity_confirmation",
          },
        });
        setLoading(false);
        updateVisit({
          payload: {
            case: {
              dermatology_steps: {
                main_steps: {
                  ...incompleteData?.dermatology_steps?.main_steps,
                  [dermatology_main_steps.basic_info]: "completed",
                  [dermatology_main_steps.visit_details]: checkAlreadyCompleted(
                    {
                      screen_name: dermatology_main_steps.visit_details,
                      dermatology_steps: incompleteData?.dermatology_steps,
                      step_placement: "main_steps",
                    }
                  ),
                },
                sub_steps: {
                  ...incompleteData?.dermatology_steps?.sub_steps,
                },
              },
            },
          },
          change_current_screen: true,
          screen_to_complete: dermatology_sub_steps.identity_confirmation,
          callBack: () => {
            dispatch(
              dermaVisitFlowReducers.identity_confirmation({
                value: imageData,
              })
            );
            nextAnimation();
          },
        });
      }
    } else {
      updateVisit({
        payload: {
          case: {
            dermatology_steps: {
              main_steps: {
                ...incompleteData?.dermatology_steps?.main_steps,
                [dermatology_main_steps.basic_info]: "completed",
                [dermatology_main_steps.visit_details]: checkAlreadyCompleted({
                  screen_name: dermatology_main_steps.visit_details,
                  dermatology_steps: incompleteData?.dermatology_steps,
                  step_placement: "main_steps",
                }),
              },
              sub_steps: {
                ...incompleteData?.dermatology_steps?.sub_steps,
              },
            },
          },
        },
        change_current_screen: true,
        screen_to_complete: dermatology_sub_steps.identity_confirmation,
        callBack: () => {
          dispatch(
            dermaVisitFlowReducers.identity_confirmation({
              value: imageData,
            })
          );
          nextAnimation();
        },
      });
    }
  }

  function handleImageChange(event) {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      let reader = new FileReader();
      reader.onload = (event) => {
        let file_object;
        file_object = {
          uri: files?.[0]?.name?.toString(),
          file: files[0],
          filename: files?.[0]?.name?.toString(),
          data: event?.target?.result,
          size: (files[0]?.size / 1000000).toFixed(2),
        };
        setImageData(file_object);
        trackEvent(`identity_image_size`, {
          size: file_object?.size + "mb",
        });
      };
      reader.readAsDataURL(files[0]);
    }
  }
  return (
    <StepScreenContainer align="start" childClass={"wrapper"}>
      <MedicalAssistant
        content={
          <p>
            Thank you. It is also important to confirm your identity before we
            evaluate and treat you. Please upload a picture of your photo ID
            below (e.g., Driver's license, Passport, etc.) [Accepted image
            formats: PNG, JPG, JPEG].
            <br />
            <br />
            If the patient is a minor please upload the guardians photo ID.
          </p>
        }
      />
      <div className="flex justify-evenly">
        <div>
          <ConfirmIdentityImagePicker
            handleChange={handleImageChange}
            imageUrl={
              imageData
                ? imageData?.uri?.startsWith("http")
                  ? imageData?.uri
                  : imageData?.data
                : ""
            }
          />
        </div>
      </div>
    </StepScreenContainer>
  );
}

export default IdentityConfirmationForDerm;
