import { bh_main_steps, bh_sub_steps, step_status } from "helpers/enum";
import { setNextScreenToUpcoming } from "helpers/setBHStepStatus";

export const behaviorHealthVisitDetailsReducer = {
  select_patient(state, action) {
    const { value, prevScreen, nextScreen, status, isValid } = action.payload;
    if (prevScreen) {
      state.subSteps[bh_sub_steps.select_patient].prevScreen = prevScreen;
    }
    if (nextScreen) {
      state.subSteps[bh_sub_steps.select_patient].nextScreen = nextScreen;
    }
    if (value) {
      state.subSteps[bh_sub_steps.select_patient].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[bh_sub_steps.select_patient].isValid = isValid;
    }
    if (status) {
      state.subSteps[bh_sub_steps.select_patient].status = status;
      if (status === step_status.completed) {
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: bh_sub_steps.select_therapy,
        });
      }
    }
  },
  select_child(state, action) {
    const { value, isValid } = action.payload;
    if (value) {
      state.subSteps[bh_sub_steps.select_child].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[bh_sub_steps.select_child].isValid = isValid;
    }
  },
  select_someone_else(state, action) {
    const { value, isValid } = action.payload;
    if (value) {
      state.subSteps[bh_sub_steps.select_someone_else].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[bh_sub_steps.select_someone_else].isValid = isValid;
    }
  },
  select_therapy(state, action) {
    const { status, value, prevScreen, isValid } = action.payload;
    // Allow updating prevScreen using useEffect in SelelctConditionforPC
    if (prevScreen) {
      state.subSteps[bh_sub_steps.select_therapy].prevScreen = prevScreen;
    }
    if (status) {
      state.subSteps[bh_sub_steps.select_therapy].status = status;
      if (status === step_status.completed) {
        state.mainSteps[bh_main_steps.visit_details].status =
          step_status.completed;
        if (
          state.mainSteps[bh_main_steps.main_select_slot].status ===
          step_status.upcoming
        ) {
          state.mainSteps[bh_main_steps.main_select_slot].status =
            step_status.current;
        }
        setNextScreenToUpcoming({
          state,
          status,
          nextScreen: bh_sub_steps.select_slot,
        });
      }
    }
    if (value) {
      state.subSteps[bh_sub_steps.select_therapy].value = value;
    }
    if (isValid !== undefined) {
      state.subSteps[bh_sub_steps.select_therapy].isValid = isValid;
    }
  },
};
