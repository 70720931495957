const checkoutDisclaimer = [
  {
    icon: "secure-user",
    title: "Data Privacy",
    text:
      "Your personal data is kept private and secure. We follow strict privacy rules to protect your information.",
  },
  {
    icon: "secure-shield",
    title: "Secured Transactions",
    text:
      "Rest assured, your health-related concerns are kept private and secure here, maintaining confidentiality of your consultations.",
  },
  {
    icon: "secure-consultation",
    title: "Confidential Consultations",
    text:
      "Your payment transactions are protected by industry-standard encryption, assuring the safety of your financial details.",
  },
];

const disclaimer = [
  "We don't charge you until after you've been evaluated by one of our providers.",
  "Please provide your credit card information to secure your appointment.",
  "We will verify your insurance and benefits once we have your health insurance.",
];

export { checkoutDisclaimer, disclaimer };
