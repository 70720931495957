import { combineReducers } from "redux";
import cosmeticConsultationReducer from "./cosmetic/cosmeticConsultationReducer";
import currentUserReducer from "./currentUserReducer";
import dermaVisitFlowReducer from "./dermatology/dermaVisitFlowReducer";
import pedVisitFlowReducer from "./ped/pedVisitFlowReducer";
import primaryCareVisitFlowReducer from "./primary_care/primaryCareVisitFlowReducer";
import behaviorHealthVisitFlowReducer from "./behavior_health/behaviorHealthVisitFlowReducer";

export const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  dermaVisitFlow: dermaVisitFlowReducer,
  primaryCareVisitFlow: primaryCareVisitFlowReducer,
  cosmeticConsultationFlow: cosmeticConsultationReducer,
  pedVisitFlow: pedVisitFlowReducer,
  behaviorHealthVisitFlow: behaviorHealthVisitFlowReducer,
});
