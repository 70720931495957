import Spacer from "components/Spacer/Spacer";
import React from "react";
import classnames from "classnames";
function RegularSelect({
  options,
  label,
  selected,
  setSelected,
  orange = false,
  mobile = false,
}) {
  return (
    <div>
      <label className="font-medium text-2xl">{label && label}</label>
      <Spacer height={15} />
      <div
        className={`flex ${
          mobile
            ? "flex-col space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0"
            : "flex-row space-x-4 space-y-0"
        }`}
      >
        {options?.map((el, idx) => {
          return (
            <div
              onClick={() => setSelected(el)}
              key={idx}
              className={classnames(
                "flex items-center cursor-pointer border px-5 py-2 rounded-md",
                selected === el
                  ? orange === true
                    ? "border-orange text-orange"
                    : "border-secondaryBlue-600 text-secondaryBlue-600"
                  : "border-grey-600"
              )}
            >
              <p className="text-xl font-bold">{el}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RegularSelect;
