import React, { useCallback, useState } from "react";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import Header from "components/header/Header";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { useCurrentUser } from "hooks/useCurrentUser";
import Spacer from "components/Spacer/Spacer";
import Images from "assets/images";
import constants from "helpers/constants";
import { useHistory } from "react-router-dom";
import Alert from "components/alerts/Alert";
import useModal from "hooks/useModal";
import { useApiMutation } from "hooks/useApiMutation";
import { discardVisit } from "api/actions/VisitActions";
import { useDispatch } from "react-redux";
import { setUserDetails } from "reducers/currentUserReducer";
import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";
import { bh_main_steps, bh_sub_steps } from "helpers/enum";
import useUpdateBehaviorHealthVisit from "hooks/visit/useUpdateBehaviorHealthVisit";
import { checkAlreadyCompleted } from "helpers/setBHStepStatus";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";

const terms = [
  "Accept all.",
  "I understand SkyMD's telehealth services are provided by a medical group of providers, similar to standard in-person clinical visits.",
  "Each time I submit a medical visit, I agree that I am initiating a request for a consultation with at least one medical professional, asking for their unbiased medical opinion, which will include a diagnosis and recommended treatment plan.",
  "I understand that I will have a role in any recommended treatment plan and that by not following the advice and plan of my provider, I may adversely affect the outcome of my treatment.",
  "I understand that my assigned medical professional knows more than what internet searches tell me or what I can deduce through my own self-service efforts, which I why I chose to seek their professional opinion and submit my visit.",
  "I understand that I will communicate with my telehealth provider(s) and staff no differently than if I were standing in front of them, meaning I agree to be banned from the medical platform if I am rude, yell, curse, or otherwise act in an inappropriate social manner.",
  "I understand that refunds are not possible once my visit is complete, because I've asked a medical professional to spend their time evaluating my case and render their opinion regarding a diagnosis and treatment plan. This includes my not agreeing with my provider's opinion, as well as whether my provider prescribed medication or not. I am asking and paying only for my provider's opinion.",
  "I understand that these main attestation points are also contained in SkyMD's complete terms and conditions, which are located (->link<-)",
];

function BehaviorHealthVisitTerms() {
  const history = useHistory();
  const [selected, setSelected] = useState([]);
  const { userDetails } = useCurrentUser();

  const { setShowModal } = useModal();

  const { incompleteData } = useBehaviorHealthVisitFlow();

  const { mutate: callDiscardVisit } = useApiMutation(discardVisit);

  const dispatch = useDispatch();
  const { updateVisit } = useUpdateBehaviorHealthVisit();

  function onSelect(index) {
    if (selected?.includes(index)) {
      if (index === 0) {
        setSelected([]);
      } else {
        setSelected((prev) => prev?.filter((el) => el !== index && el !== 0));
      }
    } else {
      if (index === 0) {
        setSelected(Array.from({ length: terms?.length }, (_, i) => i));
      } else {
        setSelected((prev) => [...prev, index]);
      }
    }
  }

  const confirmDeleteVisit = useCallback(async () => {
    try {
      const response = await callDiscardVisit(incompleteData?.id);
      if (response?.error === false) {
        dispatch(behaviorHealthVisitFlowReducers.reset());
        dispatch(
          setUserDetails({
            userDetails: { ...userDetails, incomplete_visit_id: null },
          })
        );
        history.replace("/visit/type");
      }
    } catch (error) {}
  }, [callDiscardVisit, dispatch, history, incompleteData?.id, userDetails]);

  function deleteVisit() {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <Alert
          heading="Delete visit"
          message={"Are you sure you want to delete your visit?"}
          primaryButtonText="Confirm"
          onSecondaryButtonClick={onClose}
          onPrimaryButtonClick={() => {
            onClose();
            confirmDeleteVisit();
          }}
        />
      ),
      data: null,
    });
  }

  async function acceptTerms() {
    updateVisit({
      payload: {
        case: {
          behavior_health_steps: {
            main_steps: {
              ...incompleteData?.behavior_health_steps?.main_steps,
              [bh_main_steps.main_select_slot]: "completed",
              [bh_main_steps.basic_info]: checkAlreadyCompleted({
                screen_name: bh_main_steps.basic_info,
                behavior_health_steps: incompleteData?.behavior_health_steps,
                step_placement: "main_steps",
              }),
            },
            sub_steps: {
              ...incompleteData?.behavior_health_steps?.sub_steps,
            },
          },
        },
      },
      change_current_screen: true,
      screen_to_complete: bh_sub_steps.select_slot,
      callBack: () => {
        setTimeout(() => {
          history.goBack();
        }, 500);
      },
    });
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <Header
        title="SkyMD's Code of Conduct"
        rightActionView={
          <p
            onClick={deleteVisit}
            className="text-red text-xl font-medium bg-red bg-opacity-10 px-6 py-4 rounded-md cursor-pointer"
          >
            Delete
          </p>
        }
      />
      <div className="h-eliminateHeaderWithFooter bg-white overflow-y-auto wrapper">
        <Spacer height={30} />
        <MedicalAssistant
          xOffset={23}
          content={
            <p>
              {userDetails?.first_name}, please attest that you have read our
              Code of Conduct below. Basically, we are no different than any
              typical doctor's office in terms of our doctors and support staff
              and how we manage our medical practice.
            </p>
          }
        />
        <Spacer height={27} />
        <div className="bg-alternativeWhite pb-16">
          <div className="px-12">
            {terms?.map((term, index) => {
              return (
                <div className="py-10" key={index}>
                  <div
                    onClick={() => onSelect(index)}
                    className="flex cursor-pointer"
                  >
                    <img
                      className="w-12 h-12"
                      src={
                        selected?.includes(index)
                          ? Images.checkedOrange
                          : Images.uncheckedCircle
                      }
                      alt="checked"
                    />
                    <p className="pl-5 text-2xl leading-8 font-light pt-1">
                      {term?.replace("(->link<-)", "")}
                      {term?.includes("(->link<-)") ? (
                        <a
                          href={constants.TERMS_AND_CONDITIONS_URL}
                          target="blank"
                          className="font-medium underline cursor-pointer"
                        >
                          here.
                        </a>
                      ) : null}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <BottomFixedButton
        disabled={selected?.length >= terms?.length - 1 ? false : true}
        onClick={acceptTerms}
        label="Accept & Continue"
      />
    </div>
  );
}

export default BehaviorHealthVisitTerms;
