import React, { useEffect } from "react";
import Images from "assets/images";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import useModal from "hooks/useModal";
import BasicDetailsForm from "../../common/BasicDetailsForm";
import { useBehaviorHealthVisitFlow } from "hooks/useVisitFlow";
import usePatients from "hooks/visit/usePatients";
import { getGeoDataFromZipcodeAction } from "api/actions/UserActions";
import { useApiQuery } from "hooks/useApiQuery";
import useVisitStepChange from "hooks/useVisitStepChange";
import { useDispatch } from "react-redux";
import { bh_sub_steps, speciality } from "helpers/enum";
import useUpdateBehaviorHealthVisit from "hooks/visit/useUpdateBehaviorHealthVisit";
import { behaviorHealthVisitFlowReducers } from "reducers/behavior_health/behaviorHealthVisitFlowReducer";
import { checkBasicDetailsAvailable } from "helpers/checkBasicDetailsAvailable";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";

function ConfirmResidenceForBehaviorHealth() {
  const dispatch = useDispatch();
  const { setShowModal } = useModal();
  const { patientsList, incompleteData } = useBehaviorHealthVisitFlow();
  const { getCurrentPatient } = usePatients();
  const { rightButtonClickRef, setLoading, loading } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );

  const {
    loading: gettingZipDetails,
    payload: zipDetails,
    query: getZipcodeDetails,
  } = useApiQuery(getGeoDataFromZipcodeAction(currentPatient?.zip), false);

  const { updateVisit } = useUpdateBehaviorHealthVisit();

  function openBasicDetailsModal() {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <BasicDetailsForm
          onClose={onClose}
          currentPatient={currentPatient}
          patientType={
            currentPatient?.relationship_with_account === "pappa"
              ? "young"
              : "old"
          }
          currentSpeciality={speciality.behavior_health}
        />
      ),
      data: null,
    });
  }

  useEffect(() => {
    getZipcodeDetails();
  }, [getZipcodeDetails]);

  useEffect(() => {
    if (gettingZipDetails) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [gettingZipDetails, setLoading]);

  useEffect(() => {
    if (zipDetails?.geo_data) {
      dispatch(
        behaviorHealthVisitFlowReducers.confirm_residence({
          isValid: true,
        })
      );
    } else {
      dispatch(
        behaviorHealthVisitFlowReducers.confirm_residence({
          isValid: false,
        })
      );
    }
  }, [dispatch, zipDetails]);

  function onRightButtonClick(_, nextAnimation) {
    if (checkBasicDetailsAvailable({ patient: currentPatient })) {
      updateVisit({
        payload: {
          case: {
            id: incompleteData?.id,
            state: zipDetails?.geo_data?.state_code,
            behavior_health_steps: incompleteData?.behavior_health_steps,
          },
        },
        change_current_screen: true,
        screen_to_complete: bh_sub_steps.confirm_residence,
        callBack: () => {
          dispatch(
            behaviorHealthVisitFlowReducers.confirm_residence({
              value: zipDetails?.geo_data?.state_code,
            })
          );
          nextAnimation();
        },
      });
    } else {
      openBasicDetailsModal();
    }
  }

  return (
    <StepScreenContainer align="start">
      <MedicalAssistant
        content={
          <p>
            Thank you, {currentPatient?.first_name}. Just a few more steps
            before your appointment is booked.
            <br />
            <br />
            Let's make sure that our medical group is licensed to practice
            medicine where you live. Please confirm that you currently live in
            the location below.
          </p>
        }
      />
      <Spacer height={40} />
      <Card>
        <div className="flex items-center justify-between">
          <h2 className={"font-bold text-3xl"}>
            {zipDetails?.geo_data?.state_name ?? "..."}
          </h2>
          <img
            onClick={
              gettingZipDetails || loading ? null : openBasicDetailsModal
            }
            className={`w-16 cursor-pointer ${
              gettingZipDetails || loading
                ? "opacity-50 cursor-not-allowed"
                : "opacity-100"
            }`}
            src={Images.editIconInverted}
            alt="edit_icon"
          />
        </div>
      </Card>
    </StepScreenContainer>
  );
}

export default ConfirmResidenceForBehaviorHealth;
