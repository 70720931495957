import React from "react";
import ServiceCard from "./ServiceCard";
import { useDispatch } from "react-redux";
import { currentSpeciality, setUserDetails } from "reducers/currentUserReducer";
import { useHistory } from "react-router-dom";
import { speciality } from "helpers/enum";
import IncompleteVisitTracker from "./IncompleteVisitTracker";
import { useCurrentUser } from "hooks/useCurrentUser";
import { subscribePCPAction } from "api/actions/UserActions";
import { trackEvent } from "helpers/analytics";
import ActiveVisitsBanner from "./ActiveVisitBanner";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import DermaProvider from "./DermaProvider";
import { getRouteBySpecialty, getServiceMessage } from "helpers/functions";
import useModal from "hooks/useModal";
import { Icon } from "stories/Icons";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";

const services = (userDetails, hasNoDermaProvider) => [
  {
    name: "primary_care",
    specialityName: "primary_care",
    route: getRouteBySpecialty("primary_care"),
    label: "Primary Care Visit",
    content: userDetails?.incomplete_primary_care_id
      ? "Complete your primary care visit "
      : "Book a primary care visit with a physician",
    isOngoing: userDetails?.incomplete_primary_care_id,
    isAvailable: userDetails?.is_primary_care_available,
    isSubscribed: userDetails?.subscribe_to_pcp,
    subscribeKey: "subscribe_to_pcp",
    specialityValue: 2,
  },
  {
    name: "dermatology",
    specialityName: "dermatology",
    route: getRouteBySpecialty("dermatology", hasNoDermaProvider),
    label: "Dermatology Visit",
    content: userDetails?.incomplete_dermatology_id
      ? "Complete your dermatology visit"
      : "Book a dermatology visit with a dermatologist",
    isOngoing: userDetails?.incomplete_dermatology_id,
    isAvailable: true,
    isSubscribed: true,
    subscribeKey: "subscribe_to_dermatology",
    specialityValue: 1,
  },
  {
    name: "ped",
    specialityName: "pediatric",
    route: getRouteBySpecialty("ped"),
    label: "Pediatric Visit",
    content: userDetails?.incomplete_pediatric_id
      ? "Complete your pediatric visit with a pediatrician"
      : "Book a pediatric visit with a pediatrician",
    isOngoing: userDetails?.incomplete_pediatric_id,
    isAvailable: userDetails?.is_pediatric_available,
    isSubscribed: userDetails?.subscribe_to_pediatric,
    subscribeKey: "subscribe_to_paediatric",
    specialityValue: 4,
  },
  {
    name: "behavior_health",
    specialityName: "behavior_health",
    label: "Behavioral Health Visit",
    content: userDetails?.incomplete_behavior_health_id
      ? "Complete your behavioral health visit"
      : "Book a behavioral health visit with a therapist",
    route: getRouteBySpecialty("behavior_health"),
    isOngoing: userDetails?.incomplete_behavior_health_id,
    isAvailable: userDetails?.is_behavior_health_available,
    isSubscribed: userDetails?.subscribe_to_behavior_health,
    subscribeKey: "subscribe_to_behavior_health",
    specialityValue: 5,
  },
  {
    name: "cosmetic",
    specialityName: "cosmetic",
    label: "Cosmetic Visit",
    content: userDetails?.incomplete_cosmetic_consultation_id
      ? "Complete your cosmetic consultation"
      : "Book a cosmetic consultation with a physicisan",
    route: getRouteBySpecialty("cosmetic"),
    isOngoing: userDetails?.incomplete_cosmetic_consultation_id,
    isAvailable: userDetails?.is_cosmetic_consultation_available,
    isSubscribed: userDetails?.subscribe_to_cosmetic,
    subscribeKey: "subscribe_to_cosmetic",
    specialityValue: 3,
  },
];

function ServiceSection({
  dermaDoctorDetails,
  hasNoDermaProvider,
  incompleteVisits,
  submittedVisits,
  incVisitsLoading,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setShowModal } = useModal();
  const { userDetails } = useCurrentUser();
  const { mutate: callSubscribeToPCP } = useApiMutation(subscribePCPAction);
  const { showAlert } = useToastify();

  function redirectVisits(spec, hasNoDerma) {
    const route = getRouteBySpecialty(spec, hasNoDerma);
    dispatch(
      currentSpeciality({
        currentSpeciality: speciality[spec],
      })
    );
    return history.push(route);
  }
  const filteredServices =
    userDetails &&
    services(userDetails, hasNoDermaProvider).filter((e) => {
      return e.specialityName !== incompleteVisits?.inc_visits[0]?.speciality;
    });
  return (
    <div className="flex flex-col space-y-8">
      <div>
        <h2 className="pb-1 text-2.5xl md:text-4xl font-bold">
          Your Personal Healthcare Partner
        </h2>
        <p className="mt-2 text-xl font-medium leading-8 md:text-2xl text-themeBlack">
          {getServiceMessage(
            incompleteVisits?.inc_visits?.length > 0
              ? incompleteVisits.inc_visits[0]?.practice_user &&
                !incompleteVisits.inc_visits[0]?.practice_user.license?.active
                ? "expired"
                : incompleteVisits.inc_visits[0].speciality
              : ""
          )}
        </p>
      </div>
      {(incVisitsLoading || incompleteVisits?.inc_visits?.length > 0) && (
        <IncompleteVisitTracker
          loading={incVisitsLoading}
          visit={
            incompleteVisits?.inc_visits
              ? incompleteVisits?.inc_visits[0]
              : null
          }
          routeHelper={redirectVisits}
        />
      )}
      {filteredServices && (
        <div
          className={
            filteredServices.length === 5
              ? "service-grid-for-five"
              : "service-grid-for-four"
          }
        >
          {userDetails &&
            filteredServices.map((e) => {
              return (
                <ServiceCard
                  key={e.name}
                  userDetails={userDetails}
                  label={e.label}
                  name={e.name}
                  content={e.content}
                  maintainRatio={
                    (filteredServices.length > 3 && window.innerWidth > 320) ||
                    window.innerWidth > 768
                  }
                  available={e.isAvailable}
                  onClick={() => {
                    if (e.isAvailable) {
                      if (e.specialityValue === 5) {
                        // Continue an existing BH visit, if any
                        // OR
                        // Start a new BH visit if no visits are pending
                        if (
                          userDetails?.can_create_new_visit ||
                          userDetails?.incomplete_behavior_health_id
                        ) {
                          redirectVisits(e.name, hasNoDermaProvider);
                          trackEvent(
                            e.isOngoing
                              ? `continue_${e.name}_visit`
                              : `start_${e.name}_visit`
                          );
                        } else {
                          // Do not start a new BH visit if an older BH visit is still being evaluated
                          if (userDetails?.has_any_bh_in_progress_visit) {
                            setShowModal({
                              state: true,
                              children: (_, onClose) => (
                                <div className="flex items-center justify-center w-full h-full py-20 bg-black bg-opacity-50">
                                  <div className="max-w-4xl md:w-2/5 rounded-xl">
                                    <div className="p-6 bg-white rounded-xl">
                                      <div className="flex justify-between items-center mb-8 space-x-5 font-medium">
                                        <div />
                                        <button
                                          type="button"
                                          onClick={() => onClose()}
                                        >
                                          <Icon name={"close"} />
                                        </button>
                                      </div>
                                      <MedicalAssistant
                                        content={
                                          <p>
                                            Hey {userDetails?.first_name},<br />
                                            <br />
                                            Please wait until your previously
                                            submitted therapist visit has been
                                            evaluated before trying to submit a
                                            new one.
                                          </p>
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              ),
                              data: null,
                            });
                            // Do not start a new BH followup visit if 2 older BH visits are still being evaluated
                          } else if (
                            userDetails?.can_create_bh_follow_up_visit === false
                          ) {
                            setShowModal({
                              state: true,
                              children: (_, onClose) => (
                                <div className="flex items-center justify-center w-full h-full py-20 bg-black bg-opacity-50">
                                  <div className="max-w-4xl md:w-2/5 rounded-xl">
                                    <div className="p-6 bg-white rounded-xl">
                                      <div className="flex justify-between items-center mb-8 space-x-5 font-medium">
                                        <div />
                                        <button
                                          type="button"
                                          onClick={() => onClose()}
                                        >
                                          <Icon name={"close"} />
                                        </button>
                                      </div>
                                      <MedicalAssistant
                                        content={
                                          <p>
                                            Hey {userDetails?.first_name},<br />
                                            <br />
                                            Please wait until your previously
                                            submitted therapist visit has been
                                            evaluated before trying to submit a
                                            new one.
                                          </p>
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              ),
                              data: null,
                            });
                          } else {
                            redirectVisits(e.name, hasNoDermaProvider);
                            trackEvent(
                              e.isOngoing
                                ? `continue_${e.name}_visit`
                                : `start_${e.name}_visit`
                            );
                          }
                        }
                      } else {
                        redirectVisits(e.name, hasNoDermaProvider);
                        trackEvent(
                          e.isOngoing
                            ? `continue_${e.name}_visit`
                            : `start_${e.name}_visit`
                        );
                      }
                    } else {
                      trackEvent(`unavailable_${e.name}_service_request`);
                    }
                  }}
                  subscribed={e.isSubscribed}
                  onClickNotify={() => subscribe(e)}
                />
              );
            })}
        </div>
      )}
      {submittedVisits?.submitted_visits?.length > 0 && (
        <ActiveVisitsBanner
          key={`acitive-visit-${submittedVisits.submitted_visits[0].speciality}`}
          speciality={submittedVisits.submitted_visits[0].speciality}
          id={submittedVisits.submitted_visits[0].visit_id}
        />
      )}
      {!hasNoDermaProvider && <DermaProvider details={dermaDoctorDetails} />}
    </div>
  );

  async function subscribe(e) {
    try {
      const response = await callSubscribeToPCP({
        subscribed: true,
        speciality: e.specialityValue,
      });
      if (response && response?.error === false) {
        dispatch(
          setUserDetails({
            userDetails: {
              ...userDetails,
              [e.subscribeKey]: true,
            },
          })
        );
        showAlert(response.payload.message, "success");
      }
    } catch (error) {}
  }
}
export default ServiceSection;
